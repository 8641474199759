export default (

    state = {
        allNotifications: [],
        readNotifications: [],
        unreadNotifications: [],
        notificationPage: 1,
        totalUnread: 0
    },
    action
) => {
    switch (action.type) {
        
        case "FETCH_ALL_NOTIFICATION":
            return {
                ...state,
                allNotifications: [...state.allNotifications, ...action.response]
            };
        case "FETCH_READ_NOTIFICATION":
            return {
                ...state,
                readNotifications: [...state.readNotifications, ...action.response]
            };
        case "FETCH_UNREAD_NOTIFICATION":
            return {
                ...state,
                unreadNotifications: [...state.unreadNotifications, ...action.response]
            };
        case "NOTIFICATION_PAGE":
            return {
                ...state,
                notificationPage: state.notificationPage + 1
            };
        case "CLEAR_ALL_NOTIFICATION":
                return {
                    ...state,
                    allNotifications: [], notificationPage: 1
                };

        case "UNREAD_NOTIFICATIONS_COUNT":
                    return {
                        ...state,
                        totalUnread: action.response
                    };
                

        default:
            return state;
    }
};





// export default (state = { response: { data: {} } }, action) => {
//     const clientErrors = RegExp("4.*");
//     const serverErrors = RegExp("5.*");
//     switch (action.type) {
//         case "CLEAR_MESSAGE":
//             return {
//                 ...state,
//                 response: { data: {} },
//                 hasError: undefined,
//             };
//         case "DISPATCH_MESSAGE":
//             const hasError =
//                 clientErrors.test(action.response.status) ||
//                 serverErrors.test(action.response.status);
//             return {
//                 ...state,
//                 response: action.response,
//                 hasError: hasError,
//             };
//         case "CLEAR_ERROR":
//             return { ...state, hasError: undefined };
//         default:
//             return state;
//     }
// };
