export default (
  state = {
    allGroups: [],
    appGroups: [],
    appGroupsandSunGroupsByAppId: [],
    allNavigationGroups: [],
    allViewsWithoutNavigationGroups: [],
    allViewsWithoutNavGroupsByContainerId: [],
    appGroupsAndSubgroups: [],
  },
  action
) => {
  switch (action.type) {
    case "FETCH_GROUPS":
      return { ...state, allGroups: action.response };
    case "SAVE_GROUP":
      return {
        ...state,
        allGroups: [...state.allGroups, action.response],
      };
    case "FETCH_GROUP":
      return {
        ...state,
        chosenGroup: action.response,
      };
    case "FETCH_APP_GROUPS":
      return { ...state, appGroups: action.response };
    case "FETCH_APP_GROUPS_AND_SUBGROUP":
      return { ...state, appGroupsAndSubgroups: action.response };
    case "FETCH_GROUP_SUBGROUP_LISTING_BY_APPID":
      return { ...state, appGroupsandSunGroupsByAppId: action.response };
    case "SHOW_CREATE_GROUP_DIALOG":
      return { ...state, showCreateNewGroupDialog: action.response };
    case "FETCH_NAVIGATION_GROUP":
      return {
        ...state,
        allNavigationGroups: action.response,
      };
    case "FETCH_VIEWS_WITHOUT_NAVIGATION_GROUP":
      return {
        ...state,
        allViewsWithoutNavigationGroups: action.response,
      };
    case "FETCH_VIEWS_WITHOUT_NAVIGATION_GROUP_BY_CONTAINER_ID":
      return {
        ...state,
        allViewsWithoutNavGroupsByContainerId: action.response,
      };
    default:
      return state;
  }
};
