export default (state = {
    timeout: false
}, action) => {
    switch (action.type) {
        case "FETCH_TIMEOUT_STATUS":
            return { ...state, timeout: action.response };
        default:
            return state;
    }
};
