import React, { useState } from "react";
/*------------------------Material imports starts--------------------------------------*/
import { Tooltip, IconButton, TextField, InputAdornment } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
/*------------------------Material imports ends--------------------------------------*/
/*---------------------------------Common files starts-----------------------------------*/
import UserToolBarMoreOption from "./../../../../userToolbar/UserToolBarMoreOption";
import LanguageTranslation from "../../../../Language/LanguageTranslation";
import "../../../Css/Common.css";
import "./ToolbarReln.css";
/*---------------------------------Common files starts-----------------------------------*/

export default function AttachmentToolbar(props) {
  const [menuopen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchDisplay, setSearchDisplay] = useState("none");
  const [searchValue, setSearchValue] = useState("");

  const toggleSearch = () => {
    setSearchDisplay(searchDisplay === "none" ? "block" : "none");
  };
  const searchIconClicked = () => {
    props.searchChange(searchValue);
  };

  const listtypeopenShow = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const listtypeopenClose = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <label className="inputLabels-toobarReln">
          {props.relationshipName}
        </label>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {/*----------------- Search icon ---------------------*/}
          <IconButton
            className="buttonstyle"
            color="primary"
            size="small"
            onClick={toggleSearch}
          >
            <Tooltip title={<LanguageTranslation key="SEARCH" text="Search" />}>
              <SearchIcon />
            </Tooltip>
          </IconButton>
          {/*----------------- Search icon ---------------------*/}
          {/*----------------- Search textField ---------------------*/}
          <div style={{ display: searchDisplay }}>
            <TextField
              id={"search_text"}
              name={"search"}
              label={
                <LanguageTranslation
                  key="ENTER_SEARCH_TEXT"
                  text="Enter search text"
                />
              }
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={searchIconClicked}
                      color="primary"
                      size="small"
                      cursor="pointer"
                    >
                      <ManageSearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  searchIconClicked();
                }
              }}
            />
          </div>
          {/*----------------- Search textField ---------------------*/}
          {/*----------------- Vertical tripledot ---------------------*/}
          <IconButton
            className="buttonstyle"
            onClick={(e) => listtypeopenShow(e)}
            size="small"
            color={menuopen ? "primary" : "default"}
          >
            <Tooltip title="More">
              <MoreVertIcon />
            </Tooltip>
          </IconButton>
          {/*----------------- Vertical tripledot ---------------------*/}
        </div>
        {/*-----------------Component to call pophover menu ---------------------*/}
        {menuopen ? (
          <UserToolBarMoreOption
            listtypeopenClose={listtypeopenClose}
            menuopen={menuopen}
            anchorEl={anchorEl}
            excelExportShow={true}
            columnChooserShow={true}
            columnChooserclick={() => props.columnChooserclick()}
            excelExport={() => props.excelExport()}
          />
        ) : null}
        {/*-----------------Component to call pophover menu ---------------------*/}
      </div>
    </>
  );
}
