export function shadeColor(color, amount) {
  if (color !== undefined && color !== null && color) {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" +
            Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
              16
            )
          ).substr(-2)
        )
    );
  } else {
    return "#000000";
  }
}

export function colorBrightness(color) {
  if (color !== undefined && color !== null && color) {
    if (color !== "white") {
      if (color.charAt(0) === "#") {
        // Convert the hex color to RGB values.
        var r = parseInt(color.substring(1, 3), 16);
        var g = parseInt(color.substring(3, 5), 16);
        var b = parseInt(color.substring(5, 7), 16);

        // Calculate the perceived brightness of the color.
        var brightness = (r * 299 + g * 587 + b * 114) / 1000;

        // Return 'light' if the brightness is greater than 128, otherwise return 'dark'.

        if (brightness < 128) {
          return "dark";
        } else {
          if (brightness > 220) {
            return "superlight";
          } else {
            return "light";
          }
        }
      } else {
        // The color is not in hex format, so return 'unknown'.
        return null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
}
