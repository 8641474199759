/*
 * Copyright by Intland Software
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Intland Software. ("Confidential Information"). You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Intland.
 */
var WidgetClient = /** @class */ (function () {
    function WidgetClient(window, widgetId, origin) {
        var _this = this;
        this.window = window;
        this.widgetId = widgetId;
        this.origin = origin;
        this.nextExchangeId = 0;
        this.eventSubscriptions = {};
        this.conversationCallbacks = {};
        window.addEventListener('message', function (event) { return _this.consumeMessageEvent(event); });
    }
    WidgetClient.prototype.call = function (func, payload) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.nextExchangeId++;
            _this.conversationCallbacks[_this.nextExchangeId] = (function (exchangeId) {
                return function (response) {
                    if (response.type === 'error') {
                        return reject(response.payload);
                    }
                    if (message.func !== func) {
                        throw new Error("Response type mismatch. Expected: \"".concat(func, "\". Actual: \"").concat(response.type, "\""));
                    }
                    resolve(response.payload);
                    delete _this.conversationCallbacks[exchangeId];
                };
            })(_this.nextExchangeId);
            var message = {
                type: 'RPC',
                widgetId: _this.widgetId,
                exchangeId: _this.nextExchangeId,
                func: func,
                payload: payload
            };
            if (!_this.window.parent) {
                throw new Error('window must have a parent');
            }
            _this.window.parent.postMessage(message, _this.origin);
        });
    };
    WidgetClient.prototype.subscribe = function (event, callback) {
        var _this = this;
        var cloneCallback = function (x) { return callback(x); };
        this.eventSubscriptions[event] = this.eventSubscriptions[event] || [];
        this.eventSubscriptions[event].push(cloneCallback);
        return function () {
            var idx = _this.eventSubscriptions[event].indexOf(cloneCallback);
            _this.eventSubscriptions[event].splice(idx, 1);
        };
    };
    WidgetClient.prototype.consumeMessageEvent = function (event) {
        if (this.origin !== '*' && event.origin !== this.origin) {
            console.log("Ignoring message because of origin mismatch. Expected: \"".concat(this.origin, "\". Actual: \"").concat(event.origin, "\"."));
            return;
        }
        var data = event.data;
        if (!isMessageBody(data)) {
            console.warn('Is not a message', data);
            return;
        }
        if (isEventResponse(data)) {
            return this.consumeEvent(data);
        }
        if (!isExchangeResponse(data)) {
            if (!isExchangeError(data)) {
                console.warn('Malformed message', data);
                return;
            }
        }
        else {
            if (data.widgetId !== this.widgetId)
                return;
        }
        var callback = this.conversationCallbacks[data.exchangeId];
        if (!callback) {
            throw new Error('Widget ' + this.widgetId + ' is missing callback for exchangeId ' + data.exchangeId);
        }
        callback(data);
    };
    WidgetClient.prototype.consumeEvent = function (event) {
        var callbacks = this.eventSubscriptions[event.event] || [];
        callbacks.forEach(function (callback) {
            callback(event.payload);
        });
    };
    return WidgetClient;
}());
export { WidgetClient };
function isMessageBody(response) {
    if (!('payload' in response))
        return false;
    return !!response.type;
}
function isExchangeResponse(response) {
    if (response.type !== 'RPC')
        return false;
    if (!response.func)
        return false;
    return !!response.exchangeId;
}
function isExchangeError(response) {
    return response.type === 'error';
}
function isEventResponse(response) {
    if (response.type !== 'event')
        return false;
    return !!response.event;
}
