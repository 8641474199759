export default (
  state = {
    type: { attributes: [], relationships: [], roles: [], team: [] },
    dataType: [],
    types: [],
    containerTypes: [],
    allViews: [],
    allViewsByTypeId: [],
    allViewsByUser: [],
    allViewsByContainerId: [],
    viewByViewId: [],
    userViewByViewId: [],
    allViewsByAppIdUserSide: [],
    allViewsByContainerInstanceId: [],
    allViewsListingByAppId: [],
  },
  action
) => {
  switch (action.type) {
    case "SAVE_VIEWS":
      return { ...state, types: [...state.types, action.response] };
    case "SAVE_VIEWS_BY_ORDER":
      return { ...state, types: [...state.types, action.response] };
    case "UPDATE_VIEWS":
      return { ...state, types: [...state.types, action.response] };
    case "FETCH_VIEWS_WITH_TYPEID":
      return { ...state, types: [action.response] };
    case "FETCH_ALL_VIEWS":
      return { ...state, allViews: [action.response] };
    case "FETCH_ALL_VIEWS_BY_APPID":
      return { ...state, allViewsByTypeId: [action.response] };
    case "FETCH_ALL_VIEWS_LISTING_BY_APPID":
      return { ...state, allViewsListingByAppId: [action.response] };

    case "FETCH_ALL_VIEWS_BY_CONTAINERINSTANCEID":
      return { ...state, allViewsByContainerInstanceId: [action.response] };
    case "FETCH_ALL_VIEWS_BY_APPID_USER_SIDE":
      return { ...state, allViewsByAppIdUserSide: [action.response] };
    case "FETCH_VIEWS_BY_USER":
      return { ...state, allViewsByUser: [action.response] };
    case "FETCH_VIEWS_BY_CONTAINER_ID":
      return { ...state, allViewsByContainerId: [action.response] };
    case "FETCH_ALL_VIEWS_BY_VIEWID":
      return { ...state, viewByViewId: [action.response] };
    case "FETCH_SINGLE_USER_VIEWS_BY_VIEWID":
      return { ...state, userViewByViewId: [action.response] };
    case "DELETE_VIEWS": {
      return {
        ...state,
        users: [...state.types.filter((v) => v.id !== action.response)],
      };
    }
    case "GET_VIEWS_BY_ROLES":
      return { ...state, types: [...state.types, action.response] };

    default:
      return state;
  }
};
