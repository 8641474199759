import React, { useEffect, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import {
  RichTextEditorComponent,
  Inject,
  Image,
  Link,
  HtmlEditor,
  Count,
  Table,
  FileManager,
} from "@syncfusion/ej2-react-richtexteditor";
import TextLoader from "../../../../Loaders/TextLoader";
import InputLabel from "../../Common/Components/InputLabel";
import "./Widget.css";

export default function LongTextWidget(props) {
  const [attr, setAttr] = useState();
  const [loader, setLoader] = useState(true);
  const [objectLoader, setObjectLoader] = useState(true);
  const [dataItem, setDataItem] = useState("");
  const [dataItemFlag, setDataItemFlag] = useState();

  useEffect(() => {
    setLoader(true);
    setObjectLoader(true);
  }, []);

  useEffect(() => {
    if (props.attr) {
      setAttr(props.attr);
      setLoader(false);
    }
  }, [props.attr]);

  useEffect(() => {
    if (props.attr && props.attr.internalName) {
      if (
        props.facts[props.attr.internalName] &&
        typeof props.facts[props.attr.internalName] != "object"
      ) {
        setDataItem(props.facts[props.attr.internalName]);
        setDataItemFlag(true);
      } else {
        setObjectLoader(false);
      }
    }
  }, [props.facts]);
  useEffect(() => {
    if (dataItemFlag) {
      setObjectLoader(false);
    }
  }, [dataItemFlag]);

  return (
    <Grid item xs>
      {loader ? (
        <Skeleton variant="text" />
      ) : (
        <>
          <InputLabel attr={attr} objectType={props.objectType} />

          <div className="root">
            {objectLoader ? (
              <div className="loaderStyle parahSpace">
                <TextLoader />
              </div>
            ) : (
              <div className="parahSpace">
                <div className="control-pane">
                  <div className="control-section" id="rte">
                    <div className="rte-control-section">
                      <RichTextEditorComponent
                        id={attr.name}
                        readonly={true}
                        value={dataItem}
                        className="inputField"
                      >
                        <Inject
                          services={[
                            Image,
                            Link,
                            HtmlEditor,
                            Count,
                            Table,
                            FileManager,
                          ]}
                        />
                      </RichTextEditorComponent>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </Grid>
  );
}
