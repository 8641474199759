// import {dateTimeFormat} from "../formatGridNData/dateFormat"
import { gridDateTimeFormat } from "../formatGridNData/dateFormat";

function removeTags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
}

export default function FormatExternalGridData(data, attributes) {
  attributes?.map((typeContain) => {
    if (typeContain !== undefined && typeContain !== null && typeContain) {
      let typeContainerName = typeContain.internalName;
      let typeContainerKey = typeContain.dataType.key;

      data?.map((attribute) => {
        if (attribute && typeContainerName && attribute[typeContainerName]) {
          if (typeContainerKey === "DATETIME" || typeContainerKey === "DATE") {
            if (
              attribute[typeContainerName] &&
              attribute[typeContainerName] != null
            ) {
              let dateValue;
              if (
                attribute[typeContainerName] &&
                attribute[typeContainerName].displayValue &&
                attribute[typeContainerName].displayValue != ""
              ) {
                dateValue = attribute[typeContainerName].displayValue;
              } else {
                dateValue = attribute[typeContainerName];
              }
              if (typeof dateValue == "string") {
                //for ISO string
                attribute[typeContainerName] = gridDateTimeFormat(dateValue);
              } else if (typeof dateValue == "object") {
                //for GMT format
                let dateVal = new Date(dateValue + " UTC");
                let isoVal = dateVal.toISOString(); //first converting to ISO string
                attribute[typeContainerName] = gridDateTimeFormat(isoVal);
              } else {
                //for time stamp
                let isoVal = new Date(dateValue).toISOString(); //first converting to ISO string
                attribute[typeContainerName] = gridDateTimeFormat(isoVal);
              }
              return attribute;
            }
          } else if (typeContainerKey === "FILE") {
            if (
              attribute[typeContainerName] !== undefined &&
              attribute[typeContainerName] != null &&
              attribute[typeContainerName]
            ) {
              if (
                attribute[typeContainerName].value !== undefined &&
                attribute[typeContainerName].value !== null &&
                attribute[typeContainerName].value != "" &&
                attribute[typeContainerName].value &&
                attribute[typeContainerName].value.length > 0
              ) {
                let namesArr = attribute[typeContainerName].value.map(
                  (attr) => {
                    return attr.filename;
                  }
                );
                attribute[typeContainerName] = namesArr.toString();
              } else {
                attribute[typeContainerName] = "";
              }
              return attribute;
            }
          } else if (typeContainerKey === "LONG_TEXT") {
            let finalData = removeTags(attribute[typeContainerName]).substring(
              0,
              50
            );
            return finalData;
          } else {
            return attribute[typeContainerName];
          }
        } else {
          return attribute[typeContainerName];
        }
      });
    }
  });
  return data;
}
