import React, { useEffect, useState } from "react";

/*------------------------Material imports starts--------------------------------------*/
import {
  Tooltip,
  IconButton,
  TextField,
  Popover,
  Typography,
  InputAdornment,
  Chip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { makeStyles } from "tss-react/mui";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
/*------------------------Material imports starts--------------------------------------*/

import "./ToolbarReln.css";
import LanguageTranslation from "../../../../Language/LanguageTranslation";
import UserToolBarMoreOption from "../../../../userToolbar/UserToolBarMoreOption";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    backgroundColor: theme.palette.background.dark,
    maxHeight: "50",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.main,
    // '&:hover': {
    //   backgroundColor: theme.palette.primary.main,
    // },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function ToolbarRelationshipGeneralIntegration(props) {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuopen, setMenuOpen] = useState(false);
  const [anchorList, setAnchorList] = useState(null);
  const [searchDisplay, setSearchDisplay] = useState("none");
  const [relationshipInfoLogic, setRelationshipInfoLogic] = useState(null);

  const [searchValue, setSearchValue] = useState("");

  const openInfo = Boolean(anchorEl);

  const selectClicked = () => {
    if (props.showSelect) {
      props.myGrid.current[props.relationshipId].showColumns("checkbox");
      props.setShowCheckBox(true);
      props.setShowSelect(false);
      setMenuOpen(false);
    } else {
      props.myGrid.current[props.relationshipId].hideColumns("checkbox");
      props.setShowCheckBox(false);
      props.setShowSelect(true);
      setMenuOpen(false);
    }
  };

  /*----------------------Set reln info popover ------------------*/
  useEffect(() => {
    if (props?.relationshipDescription) {
      setRelationshipInfoLogic(
        <>
          <span>
            <IconButton
              aria-label="url rules"
              size="small"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              style={{ marginTop: -4 }}
            >
              <InfoOutlinedIcon />
            </IconButton>
          </span>
        </>
      );
    }
  }, [props?.relationshipDescription, props.relationshipInfo]);

  useEffect(() => {
    setMenuOpen(false);
  }, [props.relnRef]);
  /*----------------------Set reln info popover ------------------*/

  const handlePopoverOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const searchIconClicked = () => {
    props.searchChange(searchValue);
  };

  const toggleSearch = () => {
    setSearchDisplay(searchDisplay == "none" ? "block" : "none");
  };

  const listtypeopenShow = (event) => {
    setAnchorList(event.currentTarget);
    setMenuOpen(true);
  };

  const listtypeopenClose = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/*----------------- Relationship title ---------------------*/}

        <label className="inputLabels-toobarReln">
          {props.relationshipName}
          {props.relationshipInfo !== undefined
            ? props.relationshipInfo
              ? relationshipInfoLogic
              : ""
            : ""}{" "}
          {props.relationshipMandatory && props.editable ? (
            <span style={{ color: "#D32F2F" }}>*</span>
          ) : null}
        </label>

        {/*----------------- Relationship title ---------------------*/}
        {/*----------------- Relationship info ---------------------*/}
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={openInfo}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>
            {props?.relationshipDescription}
          </Typography>
        </Popover>
        {/*----------------- Relationship info ---------------------*/}
        {!props.showtoolbar ? (
          <>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {/*----------------- Search icon ---------------------*/}
              {props.relationshipMapped !== undefined &&
              props.relationshipMapped !== null &&
              props.relationshipMapped === false ? (
                ""
              ) : (
                <IconButton
                  className={classes.buttonstyle}
                  color="primary"
                  size="small"
                  onClick={toggleSearch}
                >
                  <Tooltip
                    title={<LanguageTranslation key="SEARCH" text="Search" />}
                  >
                    <SearchIcon />
                  </Tooltip>
                </IconButton>
              )}
              {/*----------------- Search icon ---------------------*/}
              {/*----------------- Search textField ---------------------*/}
              <div style={{ display: searchDisplay }}>
                <TextField
                  id={"search_text"}
                  name={"search"}
                  label={
                    <LanguageTranslation
                      key="ENTER_SEARCH_TEXT"
                      text="Enter search text"
                    />
                  }
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={searchIconClicked}
                          color="primary"
                          size="small"
                          cursor="pointer"
                        >
                          <ManageSearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      searchIconClicked();
                    }
                  }}
                />
              </div>
              {/*----------------- Search textField ---------------------*/}

              {props.isEdit && props.layoutType === "Edit" ? (
                <>
                  {/*----------------- Link icon ---------------------*/}
                  <IconButton
                    //  className={classes.buttonstyle}
                    disabled={props.diasbledAdding}
                    onClick={(e) => {
                      e.preventDefault();
                      props.attacedLink();
                    }}
                    size="small"
                    color="primary"
                  >
                    <Tooltip
                      title={<LanguageTranslation key="LINK" text="Link" />}
                    >
                      <LinkIcon />
                    </Tooltip>
                  </IconButton>
                  {/*----------------- Link icon ---------------------*/}
                  {/*----------------- De-attach icon ---------------------*/}
                  <IconButton
                    //className={classes.buttonstyle}
                    onClick={() => {
                      props.deattacedLink();
                    }}
                    disabled={!props.shouldDelete}
                    size="small"
                    color="primary"
                  >
                    <Tooltip
                      title={<LanguageTranslation key="REMOVE" text="Remove" />}
                    >
                      <LinkOffIcon />
                    </Tooltip>
                  </IconButton>
                  {/*-----------------  De-attach icon ---------------------*/}
                </>
              ) : null}
              {/*-----------------  Triple dot icon ---------------------*/}
              {props.relationshipMapped !== undefined &&
              props.relationshipMapped !== null &&
              props.relationshipMapped === false ? (
                ""
              ) : (
                <IconButton
                  // className={classes.buttonstyle}
                  onClick={(e) => listtypeopenShow(e)}
                  size="small"
                  color={menuopen ? "primary" : "default"}
                >
                  <Tooltip
                    title={<LanguageTranslation key="MORE" text="More" />}
                  >
                    <MoreVertIcon />
                  </Tooltip>
                </IconButton>
              )}
              {/*-----------------  Triple dot icon ---------------------*/}
              {/*----------------- More menu ---------------------*/}
              {menuopen ? (
                <UserToolBarMoreOption
                  listtypeopenClose={listtypeopenClose}
                  menuopen={menuopen}
                  anchorEl={anchorList}
                  excelExportShow={true}
                  columnChooserShow={true}
                  columnChooserclick={() => props.columnChooserclick()}
                  excelExport={() => props.excelExport()}
                  selectClicked={selectClicked}
                  showSelect={
                    props.showSelect !== undefined &&
                    props.showSelect !== null &&
                    props.showSelect
                      ? props.showSelect
                      : false
                  }
                  callingFromRelationship={true}
                  layouttype={props.layoutType}
                />
              ) : null}
              {/*----------------- More menu ---------------------*/}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}
