export default (

    state = { 
        permission: [],
    },   
    action
) => {
    switch (action.type) {
        case "FETCH_PERMISSIONS": 
        //  return { ...state, permission: [...state.permission,action.response] }; 
            return { ...state, permission: action.response }; 
        default:
             return state;  
    }
};
 