export default (state = { language: [],selactedLanguage: {} }, action) => {
    switch (action.type) {
        case "FETCH_ALL_LANGUAGES":
            return { ...state, language: action.response };
        case "FETCH_SELECTED_LANGUAGES": {
            return {
                ...state, selactedLanguage: action.response
            };
        }
        case "ADD_LANGUAGE_RECORD":
            return { ...state, language: [...state.language, action.response] };
        
         case "UPDATE_LANGUAGE_RECORD":
               return { ...state, language: [...state.language, action.response] };
         default:
            return state; 
    }
};