import React from "react";
import { Placeholder } from 'semantic-ui-react'


const ParagraphLoader = () => {
  
    return (
      //   <Card
      // >
  <div style={{margin: 20,}}>
             <Placeholder style={{ maxWidth: '75%'}}>
                 <Placeholder.Paragraph>
                   <Placeholder.Line />
                   <Placeholder.Line />
                   <Placeholder.Line /> 
                   <Placeholder.Line />
                   <Placeholder.Line />
                 </Placeholder.Paragraph>
                 <Placeholder.Paragraph>
                   <Placeholder.Line />
                   <Placeholder.Line />
                   <Placeholder.Line />
                 </Placeholder.Paragraph> 
               </Placeholder>
             
               <Placeholder style={{ maxWidth: '75%'}}>
                 <Placeholder.Paragraph>
                   <Placeholder.Line />
                   <Placeholder.Line />
                   <Placeholder.Line />
                   <Placeholder.Line />
                   <Placeholder.Line />
                 </Placeholder.Paragraph>
                 <Placeholder.Paragraph>
                   <Placeholder.Line />
                   <Placeholder.Line />
                   <Placeholder.Line />
                 </Placeholder.Paragraph>
               </Placeholder>
             
               <Placeholder style={{ maxWidth: '75%'}}>
                 <Placeholder.Paragraph>
                   <Placeholder.Line />
                   <Placeholder.Line />
                   <Placeholder.Line />
                   <Placeholder.Line />
                   <Placeholder.Line />
                 </Placeholder.Paragraph>
                 <Placeholder.Paragraph>
                   <Placeholder.Line />
                   <Placeholder.Line />
                   <Placeholder.Line />
                 </Placeholder.Paragraph>
               </Placeholder>
               </div>
       
      
     
    );
};

export default ParagraphLoader;