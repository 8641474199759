import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Skeleton from "@mui/material/Skeleton";

const AccordionLoader = () => {
  return (
    <Tabs variant="scrollable" scrollButtons="auto">
      <Tab label={<Skeleton variant="text" width={100} height={20} />} />
      <Tab label={<Skeleton variant="text" width={100} height={20} />} />
      <Tab label={<Skeleton variant="text" width={100} height={20} />} />
      <Tab label={<Skeleton variant="text" width={100} height={20} />} />
      <Tab label={<Skeleton variant="text" width={100} height={20} />} />
    </Tabs>
  );
};

export default AccordionLoader;
