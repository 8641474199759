export const dateFormat = (date) => {
  let finalDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  return finalDate;
};

export const dateTimeFormat = (date, allSettings, appId) => {
  let fetchedDate;
  let fetchedTime;

  if (allSettings !== undefined && allSettings && allSettings.length !== 0) {
    //----------------------------date settings ----------------------

    const filteredDateSettings = allSettings.filter(
      (al) =>
        al.app_id == appId &&
        al.category == "dateformat" &&
        al.name == "dateformat_settings"
    );
    if (filteredDateSettings.length !== 0) {
      const filDateSetting = filteredDateSettings.map((fs) => {
        return {
          app_id: fs.app_id,
          category: fs.category,
          id: fs.id,
          name: fs.name,
          value: JSON.parse(fs.value),
        };
      });
      fetchedDate = filDateSetting[0]?.value[0]?.dateFormat;
    }
    // --------------------------time settingsc--------------------
    const filteredTimeSettings = allSettings.filter(
      (al) =>
        al.app_id == appId &&
        al.category == "datetimeformat" &&
        al.name == "datetimeformat_settings"
    );
    if (filteredTimeSettings.length !== 0) {
      const filTimeSetting = filteredTimeSettings.map((fs) => {
        return {
          app_id: fs.app_id,
          category: fs.category,
          id: fs.id,
          name: fs.name,
          value: JSON.parse(fs.value),
        };
      });
      fetchedTime = filTimeSetting[0]?.value[0]?.dateTimeFormat;
    }
  }
  var d = new Date(date);

  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var day = d.getDate();
  var year = d.getFullYear();
  var monthName = monthNames[d.getMonth()];
  var hours = d.getHours();
  var minutes = d.getMinutes();
  var seconds = d.getSeconds();
  var ampm = hours >= 12 ? "PM" : "AM";

  // if(fetchedTime === "hh:mm:ss (12 hour)"){
  //   hours = hours % 12;
  // }
  hours = hours % 12;

  hours = hours ? hours : 12; // the hour '0' should be '12'

  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  var strTime = hours + ":" + minutes + " " + ampm;
  if (fetchedDate === "MM/DD/YYYY") {
    return monthName + "/" + day + "/" + year + " " + strTime;
  } else if (fetchedDate === "MM-DD-YYYY") {
    return monthName + "-" + day + "-" + year + " " + strTime;
  } else {
    //default ( if no settings are selected from admin)
    return monthName + " " + day + " " + year + " " + strTime;
  }
};

export const gridDateTimeFormat = (date) => {
  let localDate = new Date(date);
  const userOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const utcDate = new Date(localDate.getTime() + userOffset);
  return utcDate;
};

export const dateTimeFormatforTimeStamp = (value, allSettings) => {
  const dateObject = new Date(value);
  const humanDateFormat = dateObject.toLocaleString();
  return humanDateFormat;
};

export const dateFormat1 = (allSettings, appId) => {
  let fetchedDate;

  if (allSettings !== undefined && allSettings && allSettings.length !== 0) {
    //date settings ----------------------
    const filteredDateSettings = allSettings.filter(
      (al) =>
        al.app_id == appId &&
        al.category == "dateformat" &&
        al.name == "dateformat_settings"
    );

    if (filteredDateSettings.length !== 0) {
      const filDateSetting = filteredDateSettings.map((fs) => {
        return {
          app_id: fs.app_id,
          category: fs.category,
          id: fs.id,
          name: fs.name,
          value: JSON.parse(fs.value),
        };
      });
      fetchedDate = filDateSetting[0]?.value[0]?.dateFormat;

      if (fetchedDate === "MM/DD/YYYY") {
        fetchedDate = "MMM/dd/yyyy";
      } else if (fetchedDate === "MM-DD-YYYY") {
        fetchedDate = "MMM-dd-yyyy";
      } else {
        //default ( if no settings are selected from admin)
        fetchedDate = "MMM dd yyyy";
      }
    } else {
    }

    return fetchedDate;

    // return monthName+ " " + day+", "+year+"  "+strTime;
  }
};
export const sendDateTimeFormat = (dateString) => {
  if (typeof dateString == "object") {
    let dateVal = new Date(dateString + " UTC");
    let isoVal = dateVal.toISOString();
    return isoVal;
  } else {
    return dateString;
  }

  // const userOffset = new Date().getTimezoneOffset()*60*1000;
  // const localDate = new Date(dateString);
  // const userOffset = new Date().getTimezoneOffset()*60*1000;
  // const utcDate = new Date(localDate.getTime() + userOffset);
  // const utcDate = new Date(localDate.getTime() + userOffset);
};

export const sendDateFormat = (dateString) => {
  if (typeof dateString == "object") {
    let dateVal = new Date(dateString + " UTC");
    let isoVal = dateVal.toISOString();
    return isoVal;
  } else {
    return dateString;
  }
  // var d = new Date(dateString)

  // const userOffset = new Date().getTimezoneOffset()*60*1000;
  // let localDate = new Date(dateString);

  // const utcDate = new Date(localDate.getTime() + userOffset);
};
export const dateFormatAccordingToSettings = (date, dateSettings) => {
  let localDate = new Date(date);

  const userOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const utcDate = new Date(localDate.getTime() + userOffset);

  let monthNamesAbr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let monthNumber = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  let dayNumber = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];
  let singleDayNumber = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];
  let day = utcDate.getDate();
  if (typeof date != "number" && typeof date == "string") {
    let splitDate = date.split("-");
    if (splitDate && splitDate.length && splitDate[2]) {
      let finalDate = splitDate[2].split("T");
      if (typeof finalDate == "object" && finalDate[0]) {
        //This method is done because getDate() is giving wrong values sometimes
        day = finalDate[0];
      }
    }
  }

  let year = utcDate.getFullYear();
  let month = utcDate.getMonth();

  if (day != NaN && month != NaN && year != NaN) {
    if (dateSettings === "dd/MM/yyyy") {
      return day + "/" + monthNumber[month] + "/" + year;
    } else if (dateSettings === "MM/dd/yyyy") {
      return monthNumber[month] + "/" + day + "/" + year;
    } else if (dateSettings === "d MMM yyyy") {
      //default ( if no settings are selected from admin)
      return day + " " + monthNamesAbr[month] + " " + year;
    } else if (dateSettings === "MMM d yyyy") {
      //default ( if no settings are selected from admin)
      return monthNamesAbr[month] + " " + day + " " + year;
    } else {
      return monthNames[month] + " " + day + " " + year;
    }
  }
};
export const dateTimeFormatAccordingToSettings = (date, dateSettings) => {
  let localDate = new Date(date);
  const userOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const utcDate = new Date(localDate.getTime() + userOffset);

  let monthNamesAbr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let monthNumber = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  let dayNumber = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];
  let singleDayNumber = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];
  let day = utcDate.getDate();

  // let splitDateTime = date.split('T')
  // if(splitDateTime[0]){
  //   let splitDate = splitDateTime[0].split('-')
  //   if(splitDate[2]){
  //     day = splitDate[2]  //This method is done because getDate() is giving wrong values sometimes
  //   }
  // }
  if (typeof date != "number" && typeof date == "string") {
    let splitDateTime = date.split("-");
    if (splitDateTime && splitDateTime.length && splitDateTime[2]) {
      let finalDate = splitDateTime[2].split("T");
      if (typeof finalDate == "object" && finalDate[0]) {
        //This method is done because getDate() is giving wrong values sometimes
        day = finalDate[0];
      }
    }
  }
  let year = utcDate.getFullYear();
  let month = utcDate.getMonth();
  let hours = utcDate.getHours();
  let minutes = utcDate.getMinutes();
  let seconds = utcDate.getSeconds();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;

  hours = hours ? hours : 12; // the hour '0' should be '12'

  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  let strTime = hours + ":" + minutes + " " + ampm;
  // 30 July 2010 15:05 UTC
  if (day != NaN && month != NaN && year != NaN) {
    if (
      dateSettings &&
      dateSettings !== null &&
      dateSettings === "dd/MM/yyyy hh:mm a"
    ) {
      return day + "/" + monthNumber[month] + "/" + year + " " + strTime;
    } else if (
      dateSettings &&
      dateSettings !== null &&
      dateSettings === "MM/dd/yyyy hh:mm a"
    ) {
      return monthNumber[month] + "/" + day + "/" + year + " " + strTime;
    } else if (
      dateSettings &&
      dateSettings !== null &&
      dateSettings === "d MMM yyyy hh:mm a"
    ) {
      //default ( if no settings are selected from admin)
      return day + " " + monthNamesAbr[month] + " " + year + " " + strTime;
    } else if (
      dateSettings &&
      dateSettings !== null &&
      dateSettings === "MMM d yyyy hh:mm a"
    ) {
      //default ( if no settings are selected from admin)
      return monthNamesAbr[month] + " " + day + " " + year + " " + strTime;
    } else if (
      dateSettings &&
      dateSettings !== null &&
      dateSettings === "UTC"
    ) {
      //default ( if no settings are selected from admin)
      return (
        day + " " + monthNames[month] + " " + year + " " + strTime + " UTC"
      );
    } else {
      return monthNames[month] + " " + day + " " + year + " " + strTime;
    }
  }
};

export const formatDate = (date) => {
  let formattedDate = new Date(date);
  return formattedDate;
};

export const formatDateTime = (dateTime) => {
  const dateWithOffset = new Date(dateTime); // Assuming this is a date with timezone offset
  // Get the time in milliseconds
  const timeInMillis = dateWithOffset.getTime();
  // Get the timezone offset in milliseconds
  const timezoneOffsetInMillis = dateWithOffset.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
  // Remove the timezone offset
  const dateWithoutOffset = new Date(timeInMillis + timezoneOffsetInMillis);
  return dateWithoutOffset;
};

export const formatCreatedOModifiedOnForGridListing = (res) => {
  const formattedData = res.map((val) => {
    if (val.createdOn && val.createdOn != null) {
      val.createdOn = formatDateTime(val.createdOn);
    }

    if (val.modifiedOn && val.modifiedOn != null) {
      val.modifiedOn = formatDateTime(val.modifiedOn);
    }
    return val;
  });
  return formattedData;
};

export const formatFileUploadForGridListing = (res) => {
  const formattedData = res.map((val) => {
    if (val.uploadedOn && val.uploadedOn != null) {
      val.uploadedOn = formatDateTime(val.uploadedOn);
    }
    return val;
  });
  return formattedData;
};

// Function to format date dynamically
export function formatDateForKanbanandPlanning(dateString, format) {
  // Create a Date object from the string
  const date = new Date(dateString);
  // Extract parts of the date
  const day = String(date.getDate()).padStart(2, "0"); // Day with leading zero
  const dayWithoutZero = String(date.getDate()); // Day without leading zero
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month with leading zero
  const monthWithoutZero = String(date.getMonth() + 1); // Month without leading zero
  const year = date.getFullYear();

  // Get month name
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthName = monthNames[date.getMonth()]; // e.g., "Apr"

  // Replace format tokens with actual date values
  return format
    .replace("dd", day)
    .replace("d", dayWithoutZero)
    .replace("MMM", monthName)
    .replace("MM", month)
    .replace("M", monthWithoutZero)
    .replace("yyyy", year);
}

// Function to format date-time dynamically
export function formatDateTimeForKanbanandPlanning(dateString, format) {
  // Create a Date object from the string
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, "0"); // Day with leading zero
  const dayWithoutZero = String(date.getUTCDate()); // Day without leading zero
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month with leading zero
  const monthWithoutZero = String(date.getUTCMonth() + 1); // Month without leading zero
  const year = date.getUTCFullYear();

  // Get month name
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthName = monthNames[date.getUTCMonth()]; // e.g., "Sep"
  // Extract parts of the time
  let hours = date.getUTCHours();
  const minutes = String(date.getUTCMinutes()).padStart(2, "0"); // Minutes with leading zero
  const ampm = hours >= 12 ? "PM" : "AM"; // Determine AM/PM
  hours = hours % 12 || 12; // Convert to 12-hour format, '0' becomes '12'
  const hoursFormatted = String(hours).padStart(2, "0"); // Hours with leading zero

  // Replace format tokens with actual date and time values
  return format
    .replace("dd", day)
    .replace("d", dayWithoutZero)
    .replace("MMM", monthName)
    .replace("MM", month)
    .replace("M", monthWithoutZero)
    .replace("yyyy", year)
    .replace("hh", hoursFormatted)
    .replace("mm", minutes)
    .replace("a", ampm);
}
