export default (

    state = { 
        teamRoles: [],
        teamRolesforTypesandTeams : [],
    },   
    action
) => {
    switch (action.type) {
        case "FETCH_TEAM_ROLES": 
            return { ...state, teamRoles: action.response };
        case "FETCH_TEAM_ROLES_FOR_TYPES_AND_TEAMS": 
            return { ...state, teamRolesforTypesandTeams: action.response };
        default:
             return state; 
    }
};
