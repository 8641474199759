import React from "react";
import "./ArofinityLoader.css";
import Arofinity_logo from "../icons/Arofinity_logo.png";

const ArofinityLoader = () => {
  return (
    <div style={{ marginTop: "20%" }}>
      <img
        src={Arofinity_logo}
        className="center"
        style={{ width: 100, pointerEvents: "none" }}
      />
    </div>
  );
};

export default ArofinityLoader;
