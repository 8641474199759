export function getAttributesIdAccTheGrid(displayLayout, tabVal, attrVal) {
  let flatDat = { reln: [], typeAttr: [], comment: [], worklog: [] };
  displayLayout?.displayGrids.map((grid) => {
    if (!grid.tabs) {
      grid.widgets?.forEach((widget) => {
        if (widget.category == "ATTRIBUTE") {
          let attr = attrVal.find((e) => e.id === widget.attributeId);
          if (
            attr &&
            attr.dataType &&
            attr.dataType.key &&
            attr.dataType.key === "REFERENCED_ATTRIBUTE"
          ) {
            flatDat.typeAttr.push(attr.referencedType);
          } else if (
            attr &&
            attr.dataType &&
            attr.dataType.key &&
            attr.dataType.key == "TYPE_PICK_LIST"
          ) {
            flatDat.typeAttr.push(attr.referencedType);
            flatDat.typePickList.push(attr.id);
          }
        } else if (widget.category == "RELATIONSHIP") {
          flatDat.reln.push(widget.relationshipId);
        }
      });
    } else {
      if (grid.tabs && grid.tabs.length) {
        let tabGrids = null;
        let tabIndex = 0;
        if (grid.id && tabVal && tabVal[grid.id]) {
          tabIndex = tabVal[grid.id];
        }
        if (grid.tabs[tabIndex] && grid.tabs[tabIndex].grids) {
          tabGrids = grid.tabs[tabIndex].grids.map((tabGrid) => {
            /*-------------------Loading data on clicking tab--------------------------------*/
            tabGrid.widgets?.map((obj) => {
              if (obj.category == "RELATIONSHIP") {
                flatDat.reln.push(obj.relationshipId);
              } else {
                let attr = attrVal.find((e) => e.id === obj.attributeId);
                if (
                  attr &&
                  attr.dataType &&
                  attr.dataType.key &&
                  attr.dataType.key === "REFERENCED_ATTRIBUTE"
                ) {
                  flatDat.typeAttr.push(attr.referencedType);
                } else if (
                  attr &&
                  attr.dataType &&
                  attr.dataType.key &&
                  attr.dataType.key == "TYPE_PICK_LIST"
                ) {
                  flatDat.typeAttr.push(attr.referencedType);
                  flatDat.typePickList.push(attr.id);
                }
              }
            });
            /*-------------------Loading data on clicking tab--------------------------------*/
          });
        }
      }
    }
  });
  return flatDat;
}

export function exists(arr, search) {
  let extractedData = arr.filter((row) => row.id == search).map((data) => data);
  return extractedData;
}
