//import createStyles from '@mui/styles/createStyles';
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  "@global": {
    "*": {
      boxSizing: "border-box",
      margin: 0,
      padding: 0,
    },
    html: {
      height: "100%",
      width: "100%",
    },
    body: {
      backgroundColor: "#f4f6f8",
      height: "100%",
      width: "100%",
    },
    a: {
      textDecoration: "none",
    },
    "#root": {
      height: "100%",
      width: "100%",
    },
  },
}));

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
