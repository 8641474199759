import React from "react";

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  ColumnChooser,
  Selection,
  Toolbar,
  ExcelExport,
  Inject,
  Resize,
  Reorder,
  Sort,
  Filter,
  Edit,
  Freeze,
} from "@syncfusion/ej2-react-grids";

import { Box, Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

import "./Loader.css";
import "../GridHeightCss/Results.css";

const GridLoader = () => {
  let templatesArray = [
    {
      name: "dummy",
      category: "dummy",
      createdBy: "dummy",
      createdOn: "dummy",
    },
    {
      name: "dummy",
      category: "dummy",
      createdBy: "dummy",
      createdOn: "dummy",
    },
    {
      name: "dummy",
      category: "dummy",
      createdBy: "dummy",
      createdOn: "dummy",
    },
    {
      name: "dummy",
      category: "dummy",
      createdBy: "dummy",
      createdOn: "dummy",
    },
    {
      name: "dummy",
      category: "dummy",
      createdBy: "dummy",
      createdOn: "dummy",
    },
    {
      name: "dummy",
      category: "dummy",
      createdBy: "dummy",
      createdOn: "dummy",
    },
    {
      name: "dummy",
      category: "dummy",
      createdBy: "dummy",
      createdOn: "dummy",
    },
    {
      name: "dummy",
      category: "dummy",
      createdBy: "dummy",
      createdOn: "dummy",
    },
    {
      name: "dummy",
      category: "dummy",
      createdBy: "dummy",
      createdOn: "dummy",
    },
    {
      name: "dummy",
      category: "dummy",
      createdBy: "dummy",
      createdOn: "dummy",
    },
  ];

  function trustTemplate(props) {
    return (
      <Typography variant="caption">
        <Skeleton />
      </Typography>
    );
  }

  return (
    <Box className="gridHeightandwidth">
      <GridComponent
        
        height="100%"
        width="100%"
        dataSource={templatesArray ? templatesArray : []}
        showColumnChooser={true}
        allowResizing={true}
        statelessTemplates={["directiveTemplates"]}
        readOnly
      >
        <ColumnsDirective>
          <ColumnDirective
            template={trustTemplate}
            allowSorting={false}
            allowFiltering={false}
            width="100"
            maxWidth="100"
          ></ColumnDirective>
          <ColumnDirective
            field="name"
            template={trustTemplate}
            headerText={""}
          ></ColumnDirective>
          <ColumnDirective
            field="category"
            template={trustTemplate}
            headerText={""}
          ></ColumnDirective>
          <ColumnDirective
            field="createdBy"
            template={trustTemplate}
            headerText={""}
          ></ColumnDirective>
          <ColumnDirective
            field="createdOn"
            template={trustTemplate}
            headerText={""}
          ></ColumnDirective>
        </ColumnsDirective>
        <Inject
          services={[
            Toolbar,
            ExcelExport,
            Selection,
            ColumnChooser,
            Resize,
            Reorder,
            Sort,
            Filter,
            Edit,
            Freeze,
          ]}
        />
      </GridComponent>
    </Box>
  );
};

export default GridLoader;
