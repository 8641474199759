import React from "react";
import { IconButton } from "@mui/material";
import "./AvatarPicklist.css";
import { shadeColor,colorBrightness } from "../components/common/MakingColorShadeLighter";

const AvatarPickList = ({ stateField, finalVal, attrInternalName, id }) => {
  let i = 0;
  let iconEnter = true;
  let arr = [];
  arr = finalVal.map((dat) => {
    if (dat.DynamicIcon) {
      return dat.name;
    } else if (dat.DynamicIcon == "") {
      i = i + 1;
      return dat.name;
    } else if (dat.name) {
      return dat.name;
    } else {
      return "";
    }
  });
  if (finalVal.length == i) {
    iconEnter = false;
  }

  return (
    <>
      {stateField &&
      stateField.length > 0 &&
      stateField[0].internalName &&
      attrInternalName == stateField[0].internalName &&
      finalVal &&
      finalVal.length > 0
        ? finalVal.map((res) => {
           
            return res.DynamicColor !== "white" ? (
              <>
                <div
                  id={`${id}${Math.random()}`}
                  className="chipWithoutWhiteColor" 
                  style={{               
                    backgroundColor:
                      res.DynamicColor !== undefined &&
                      res.DynamicColor !== null &&
                      res.DynamicColor &&
                      res.DynamicColor !== ""                  
                        ?                                 
                        (
                          colorBrightness(res.DynamicColor) !== undefined && colorBrightness(res.DynamicColor) !== null ?
                          (colorBrightness(res.DynamicColor) === "dark" ? shadeColor(res.DynamicColor, 200) :
                          (
                            colorBrightness(res.DynamicColor) === "superlight" ? res.DynamicColor :
                            shadeColor(res.DynamicColor,50)
                          ))
                         : res.DynamicColor)                             
                        : "rgb(233,233,233)",
                  }} 
                >
                  <span
                    style={{
                      color: shadeColor(res.DynamicColor, -130)
                    }}
                  >
                    {res.name}
                  </span>
                </div>
              </>
            ) : (
              <div className="chip-white" key={`${id}${Math.random()}`}>
                <div className="chip-content">{res.name}</div>
              </div>
            );
          })
        : finalVal.length > 0 && iconEnter
        ? finalVal.map((res) =>
            res.DynamicIcon && res.DynamicIcon != "" ? (
              <>
                <IconButton
                  key={`${id}${Math.random()}`}
                  style={{ height: 10, color: res.DynamicColor }}
                  size="large"
                >
                  <res.DynamicIcon />
                </IconButton>
                <span>{res.name}</span>
              </>
            ) : (
              <div style={{ marginLeft: "28%" }} key={`${id}${Math.random()}`}>
                {res.name}
              </div>
            )
          )
        : arr.toString()}
    </>
  );
};

export default AvatarPickList;
