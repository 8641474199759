export default (
    state = {
        layout: { grids: [], displayGrids: [], tabs: [] },
        typeLayouts: [],
        chosenLayout: { grids: [], displayGrids: [], tabs: [] },
        viewLayout: { grids: [], displayGrids: [], tabs: [] },
        creationLayout: { grids: [], displayGrids: [], tabs: [] },
        relationCreationLayout: { grids: [], displayGrids: [], tabs: [] },
        previewLayout: { grids: [], displayGrids: [], tabs: [] },
        relationPreviewLayout: { grids: [], displayGrids: [], tabs: [] },
        childLayout: { grids: [], displayGrids: [], tabs: [] },
        categories: [],
        editLayout: { grids: [], displayGrids: [], tabs: [] },
    },
    action
) => {
    switch (action.type) {
        case "FETCH_TYPE_LAYOUT":
            return { ...state, chosenLayout: action.response };
        case "FETCH_CREATION_TYPE_LAYOUT":
            return { ...state, creationLayout: action.response };
        case "FETCH_RELATION_CREATION_TYPE_LAYOUT":
            return { ...state, relationCreationLayout: action.response };  
        case "FETCH_VIEW_TYPE_LAYOUT":
            return { ...state, viewLayout: action.response };
        case "FETCH_EDIT_TYPE_LAYOUT":
            return { ...state, editLayout: action.response };
        case "FETCH_PREVIEW_TYPE_LAYOUT":
            return { ...state, previewLayout: action.response };
        case "FETCH_RELATION_PREVIEW_TYPE_LAYOUT":
            return { ...state, relationPreviewLayout: action.response };

        case "FETCH_LAYOUT":
            const grids = action.response.grids.map((grid) => {
                
                // let layoutGrid = { id: grid.id, rows: [] };
/*---------------- added order in the response to fix the reordering grid issue --------------------------------------*/
                let layoutGrid = { id: grid.id, rows: [] , order: grid.order};
                let j = 0;
                for (let i = 0; i < grid.rowsNumber; i++) {
                    let columns = [];
                    for (let k = 0; k < grid.colsNumber; k++) {
                        columns.push({
                            id: j,
                            value: null,
                            rownum: i,
                            colnum: k,
                        });
                        j++;
                    }
                    layoutGrid.rows.push({ id: i, columns: columns, distribution: grid.distribution , properties: grid.properties});
                }
                grid.widgets.map((t) => {
                    Object.entries(t).forEach(
                        ([key, value]) =>
                            (layoutGrid.rows[t.rownum].columns[t.colnum][
                                key
                            ] = value)
                    );
                });
                return layoutGrid;
            });

            const tabs = action.response.tabs;
            tabs.forEach((tabWidget) => {
                tabWidget.tabs.forEach((tab) => {
                    const layoutGrids = tab.grids.map((grid) => {
                        let layoutGrid = { id: grid.id, rows: [] };
                        let j = 0;
                        for (let i = 0; i < grid.rowsNumber; i++) {
                            let columns = [];
                            for (let k = 0; k < grid.colsNumber; k++) {
                                columns.push({
                                    id: j,
                                    value: null,
                                    rownum: i,
                                    colnum: k,
                                });
                                j++;
                            }
                            layoutGrid.rows.push({ id: i, columns: columns });
                        }
                        grid.widgets.map((t) => {
                            Object.entries(t).forEach(
                                ([key, value]) =>
                                    (layoutGrid.rows[t.rownum].columns[
                                        t.colnum
                                    ][key] = value)
                            );
                        });
                     
                        return layoutGrid;
                    });
                    tab.grids = layoutGrids;
                });
            });

            const accordion = action.response.accordion;
            accordion.forEach((accordionWidget) => {
                accordionWidget.accordion.forEach((accordion) => {
                    const layoutGrids = accordion.grids.map((grid) => {
                        let layoutGrid = { id: grid.id, rows: [] };
                        let j = 0;
                        for (let i = 0; i < grid.rowsNumber; i++) {
                            let columns = [];
                            for (let k = 0; k < grid.colsNumber; k++) {
                                columns.push({
                                    id: j,
                                    value: null,
                                    rownum: i,
                                    colnum: k,
                                });
                                j++;
                            }
                            layoutGrid.rows.push({ id: i, columns: columns });
                        }
                        grid.widgets.map((t) => {
                            Object.entries(t).forEach(
                                ([key, value]) =>
                                    (layoutGrid.rows[t.rownum].columns[
                                        t.colnum
                                    ][key] = value)
                            );
                        });
                     
                        return layoutGrid;
                    });
                    accordion.grids = layoutGrids;
                });
            });
           
            action.response.grids = [...grids, ...tabs];   
            action.response.grids = [...action.response.grids,...accordion]      
            action.response.grids.sort((v1, v2) => v1.order - v2.order);
    
// ------------ we are sorting the array as per the order ---------------------------------

            return { ...state, layout: action.response };
        case "SAVE_LAYOUT":
            return {
                ...state,
                typeLayouts: [...state.typeLayouts, action.response],
                tabs: [],
            };
        case "FETCH_LAYOUTS":
            return { ...state, typeLayouts: action.response };
        case "FETCH_CHILD_TYPE_LAYOUT":
            return { ...state, childLayout: action.response };
        case "FETCH_LAYOUT_CATEGORIES":
            return { ...state, categories: action.response };
        case "CLEAR_LAYOUTS":
            return {
                layout: { grids: [], displayGrids: [], tabs: [] },
                typeLayouts: [],
                chosenLayout: { grids: [], displayGrids: [], tabs: [] },
                viewLayout: { grids: [], displayGrids: [], tabs: [] },
                creationLayout: { grids: [], displayGrids: [], tabs: [] },
                relationCreationLayout: {
                    grids: [],
                    displayGrids: [],
                    tabs: [],
                },
                previewLayout: { grids: [], displayGrids: [], tabs: [] },
                relationPreviewLayout: {
                    grids: [],
                    displayGrids: [],
                    tabs: [],
                },
                childLayout: { grids: [], displayGrids: [], tabs: [] },
                categories: [],
                editLayout: { grids: [], displayGrids: [], tabs: [] },
            };
        case "DELETE_LAYOUTS":
            return {
                ...state,
                typeLayouts: state.typeLayouts.filter(
                    (layout) => !action.response.includes(layout.id)
                ),
            };

        default:
            return state;
    }
};
