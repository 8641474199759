export default (

    state = {
        all_app_images: [],
        profile_pic: "",
        app_pic: "",
    },
    action
) => {
    switch (action.type) {
        case "FETCH_PROFILE_PIC":
            return { ...state, profile_pic: action.response };
        case "FETCH_APP_PIC":
            return { ...state, app_pic: action.response };
        case "FETCH_ALL_APP_PIC":
        return { ...state, all_app_images: action.response };
        default:
            return state;
    }
};
