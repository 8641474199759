import typeSettingsApi from "../apis/typeSettingsApi";
import typeApi from "../apis/typeApi";
import { configHeader } from "./header-configure";
import { redirect } from "./redirect";

export const fetchTypeSettings = (data) => async (dispatch) => {
  try {
    const response = await typeApi.get("/setting", configHeader());
    dispatch({ type: "FETCH_TYPE_SETTINGS", response: response });
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const fetchTypeSettingsByApp = (appId) => async (dispatch) => {
  try {
    const response = await typeSettingsApi.get( 
      `/appid?appId=${appId}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const saveTypeSettings = (data) => async (dispatch) => {
  try {
    const response = await typeSettingsApi.post("", data, configHeader());
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const updateTypeSettings = (data) => async (dispatch) => {
  try {
    const response = await typeSettingsApi.put("", data, configHeader());
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const fetchtypeSettingsWithTypeId = (typeId) => async (dispatch) => {
  try {
    const response = await typeSettingsApi.get(
      `/ByType?typeId=${typeId}`,
      configHeader()
    );
    dispatch({ type: "FETCH_TYPE_SETTINGS_WITH_TYPE_ID", response: response });
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const fetchRelationtypeSettings = (typeId) => async (dispatch) => {
  try {
    const response = await typeSettingsApi.get(
      `/ByType?typeId=${typeId}`,
      configHeader()
    );
    dispatch({
      type: "FETCH_TYPE_SETTINGS_WITH_RELATIONTYPE_ID",
      response: response,
    });
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const fetchSettingsbyCategory =
  (appId, typeId, category) => async (dispatch) => {
    try {
      const response = await typeSettingsApi.get(
        `/ByCategory?appId=${appId}&category=${category}&typeId=${typeId}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

export const fetchSettingsbyName =
  (appId, typeId, name) => async (dispatch) => {
    try {
      const response = await typeSettingsApi.get(
        `/ByName?appId=${appId}&name=${name}&typeId=${typeId}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

export const fetchStateAndTitleSettingsByAppIdAndTypeIdAndName =
  (data) => async (dispatch) => {
    //only for State and Title
    try {
      const response = await typeSettingsApi.post(
        `/value/stateortitle`,
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

export const fetchBooleanSettingsByAppIdAndTypeIdAndCategoryName =
  (data) => async (dispatch) => {
    //only for version , pop over and history
    try {
      const response = await typeSettingsApi.post(
        `/value`,
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

export const fetchTitleSettingsAttribute = (typeId) => async (dispatch) => {
  try {
    const response = await typeSettingsApi.get(
      `/title/setting/data?typeId=${typeId}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const isTitleSettingPresent = (connectedId) => async (dispatch) => {
  try {
    
    const response = await typeSettingsApi.get( 
      `/title/setting/exists?typeid=${connectedId}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};
