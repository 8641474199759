import importApi from "../apis/importApi";
import { configHeader, configHeaderMultipart } from "./header-configure";
import { redirect } from "./redirect";

//For fetching import grid listing data
export const fetchImportListingByAppId = (appId) => async (dispatch) => {
  try {
    const response = await importApi.get(
      `/data/details?appId=${appId}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//For getting Import summary by Id
export const fetchBasicImportSummaryById = (id) => async (dispatch) => {
  try {
    const response = await importApi.get(
      `/summary?importId=${id}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const fetchImportSummaryDetailsById = (id) => async (dispatch) => {
  try {
    const response = await importApi.get(
      `/summary/details?importId=${id}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//For import start
export const importStart = (data) => async (dispatch) => {
  try {
    const response = await importApi.post(`/data/start`, data, configHeader());
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//For import relationship start
export const importRelationshipStart = (data) => async (dispatch) => {
  try {
    const response = await importApi.post(
      `/data/relationship/start`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//For import status
export const importDataStatus = (importSettingId) => async (dispatch) => {
  try {
    const response = await importApi.get(
      `/data/status?importSettingId=` + importSettingId,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const setImportApi = (dataArray) => async (dispatch) => {
  dispatch({ type: "FETCH_IMPORT_ID", response: dataArray });
};

//For import file upload
export const importFileUpload = (data, id) => async (dispatch) => {
  try {
    const response = await importApi.post(
      `/data/file?appId=${id}`,
      data,
      configHeaderMultipart()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};
