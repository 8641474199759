import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
/*-----------------------------------Import From Material Starts here--------------------------------------------------*/
import { Box, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
/*-----------------------------------Import From Material Ends here--------------------------------------------------*/
/*-----------------------------------Import From Common Files ------------------------------------*/
import TabLoader from "../../../../Loaders/TabLoader";
/*-----------------------------------Import From Common Files------------------------------------*/
/*--------------------Import From Action Starts here--------------------------------------------------*/
import { externalViewSettings } from "../../../../components/actions/integrationApiActions";
/*--------------------Import From Action Ends here--------------------------------------------------*/

const TabView = (props) => {
  let { appId } = useParams();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (appId != "" && props.objectId && props.objectId != "") {
      let data = {
        objectId: props.objectId,
        appId: appId,
      };
      dispatch(externalViewSettings(data)).then((tabSett) => {
        if (tabSett.status === 200 && tabSett.data) {
          props.setTabData(tabSett.data);
          if (
            tabSett.data &&
            tabSett.data.length &&
            tabSett.data[0] &&
            tabSett.data[0].id
          ) {
            props.setTabValue(tabSett.data[0].id);
          } else {
            props.setTabError(true);
          }
          setLoader(false);
        } else {
          setLoader(false);
        }
      });
    }
  }, [appId, props.objectId]);

  const handleChange = (e, newValue) => {
    props.setTabValue(newValue);
  };

  return (
    <TabContext value={props.tabValue}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        {loader ? (
          <TabLoader />
        ) : (
          <TabList onChange={handleChange} aria-label="Tab">
            {props.tabData.length > 0
              ? props.tabData.map((obj) => (
                  <Tab label={obj.viewName} value={obj.id} />
                ))
              : null}
          </TabList>
        )}
      </Box>
    </TabContext>
  );
};
export default TabView;
