export default (
    state = {
        attributes: [],
        chosenAttribute: { possibleValues: [], dataType: {} },
    },
    action
) => {
    switch (action.type) {
        case "FETCH_ATTRIBUTES":
            return { ...state, attributes: action.response };
        case "FETCH_ATTRIBUTE":
            return { ...state, chosenAttribute: action.response };
        default:
            return state;
    }
};
