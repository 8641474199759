export default (state = {
    // status: false
    status: {'show': false, 'success': true},
    showWaitingMessage: false,
}, action) => {
    switch (action.type) {
      
        case "SHOW_IMPORT_MESSAGE":
                return { ...state, status: action.response };
        case "SHOW_IMPORT_WAITING_MESSAGE":
                    return { ...state, showWaitingMessage: action.response };
        default:
            return state;
    }
};


