import appSettingsApi from "../apis/appSettingsApi";
import typeSettingsApi from "../apis/typeSettingsApi";
import { configHeader } from "./header-configure";
import { redirect } from "./redirect";
import { configHeaderwithData } from "./header-configure";
import { configHeaderwithCancel } from "./header-configure";
import ApiUrl from "../apis/ApiUrl";

/*-----------------------new API's starts here------------------------------ */

export const fetchAllcategory = (data) => async (dispatch) => {
  try {
    const response = await appSettingsApi.get(
      "/refactored/allcategory",
      configHeader()
    );
    dispatch({ type: "FETCH_ALL_CATEGORY", response: response });
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const fetchAppSettingsByAppId = (appId) => async (dispatch) => {
  try {
    const response = await appSettingsApi.get(
      `/refactored/app?appId=${appId}`,
      configHeader()
    );
    dispatch({ type: "FETCH_APP_SETTTINGS_BY_APPID", response: response });
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const fetchAppSettingsByAppIdAndCategory =
  (appId, category) => async (dispatch) => {
    try {
      const response = await appSettingsApi.get(
        `refactored/category?appId=${appId}&category=${category}`,
        configHeader()
      );
      dispatch({
        type: "FETCH_APP_SETTTINGS_BY_APPID_AND_CATEGORY",
        response: response,
      });
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

export const saveAppSettingsByAppIdAndCategory = (data) => async (dispatch) => {
  try {
    const response = await appSettingsApi.post( 
      "/refactored/save",
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const updateAppSettingsByAppIdAndCategory =
  (data) => async (dispatch) => {
    try {
      const response = await appSettingsApi.put(
        "/refactored/update",
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

export const updateAppSettingsEnableDisable = (data) => async (dispatch) => {
  try {
    const response = await appSettingsApi.put(
      "/refactored/update/enable",
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const fetchAppSettingsByAppSettingDetailsId =
  (appSettingDetailsId) => async (dispatch) => {
    try {
      const response = await appSettingsApi.get(
        `/refactored/object?id=${appSettingDetailsId}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

export const deleteAppSettingsByAppSettingDetailsIds =
  (data) => async (dispatch) => {
    try {
      const response = await appSettingsApi.delete(
        "/refactored/object/delete",
        configHeaderwithData(data)
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//To update signup link setting
export const updateSignUpLinkSettingsByAppIdAndCategory =
  (data) => async (dispatch) => {
    try {
      const response = await appSettingsApi.put(
        "/refactored/signuplink/update",
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//To get signup link setting is enabled or not
export const fetchSignUpLinkSettingsIsEnableOrDisable =
  (appId) => async (dispatch) => {
    try {
      const response = await appSettingsApi.get(
        `refactored/signuplink/isenabled?appId=${appId}&category=signuplink`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//To fetch file settings
export const fetchFileSettingsByAppIdAndcategoryId =
  (appId, categoryId) => async (dispatch) => {
    try {
      const response = await appSettingsApi.get(
        `/refactored/file-max-size?appId=${appId}&categoryId=${categoryId}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//To enable/disable file settings
export const enableDisableFileSettingsByAppIdAndcategoryId =
  (data) => async (dispatch) => {
    try {
      const response = await appSettingsApi.put(
        `/refactored/switch-file-max-size`,
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//To update file size
export const saveFileSettingsSizeByAppIdAndcategoryId =
  (data, controllerRef) => async (dispatch) => {
    try {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      controllerRef.current = new AbortController();
      const response = await appSettingsApi.put(
        `/refactored/file-max-size`,
        data,
        configHeaderwithCancel(controllerRef)
      );
      return response;
    } catch (e) {
      if (e?.response && e?.response?.status && e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//To get user side Document view setting listing
export const fetchDocumentViewListing = (data) => async (dispatch) => {
  try {
    const response = await appSettingsApi.post(
      `/refactored/documentview/enabledtype`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//To get user side Document view setting listing
export const fetchDocumentViewEnableDisable = (data) => async (dispatch) => {
  try {
    const response = await appSettingsApi.post(
      `/refactored/documentview/enable`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//To get Kanban setting listing data by app Id and category
export const fetchListingByAppIdAndCategory =
  (appId, category) => async (dispatch) => {
    try {
      const response = await appSettingsApi.get(
        `/refactored/category/appSettingsDetails?appId=${appId}&category=${category}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//To get App setting is enabled or disable by app Id and category
export const fetchAppSettingEnableDisableByAppIdAndCategory =
  (appId, category) => async (dispatch) => {
    try {
      const response = await appSettingsApi.get(
        `/refactored/category/enabled?appId=${appId}&category=${category}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//Fetch all three colour settings according to APP Id
export const fetchAllThreeColourSettingsByAppId =
  (appId) => async (dispatch) => {
    try {
      const response = await appSettingsApi.get(
        `/refactored/appsettings/colors?appId=${appId}`,
        configHeader()
      );
      dispatch({
        type: "FETCH_ALL_THREE_COLOUR_SETTTINGS_BY_APPID",
        response: response,
      });
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//Fetch all apps all colour settings
export const fetchAllThreeColourSettingsOfAllApps = () => async (dispatch) => {
  try {
    const response = await appSettingsApi.get(
      `/refactored/allapps/colors`,
      configHeader()
    );
    dispatch({
      type: "FETCH_ALL_THREE_COLOUR_SETTTINGS_OF_ALL_APPS",
      response: response,
    });
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//to fetch Planning dropdown data user side
export const fetchPlanningDropDownData = (dataTosend) => async (dispatch) => {
  try {
    const response = await appSettingsApi.post(
      `/refactored/planning/enabledtype`,
      dataTosend,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//to fetch calendar dropdown data user side
export const fetchCalendarDropDownData = (dataTosend) => async (dispatch) => {
  try {
    const response = await appSettingsApi.post(
      `/refactored/calendar/enabledtype`,
      dataTosend,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//to fetch Kanban dropdown data user side
export const fetchKanbanDropDownData = (dataTosend) => async (dispatch) => {
  try {
    const response = await appSettingsApi.post(
      `/refactored/kanban/enabledtype`,
      dataTosend,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//to fetch Gantt dropdown data user side
export const fetchGanttDropDownData = (dataTosend) => async (dispatch) => {
  try {
    const response = await appSettingsApi.post(
      `/refactored/gantt/enabledtype-roles`,
      dataTosend,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//to fetch Wiki dropdown data user side
export const fetchWikiDropDownData = (dataTosend) => async (dispatch) => {
  try {
    const response = await appSettingsApi.post(
      `/refactored/wiki/enabledtype`,
      dataTosend,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//For fetching dashboard data user side according to permission
export const fetchDashboardDropDownData =
  (appId, containerId, instanceId) => async (dispatch) => {
    try {
      const response = await appSettingsApi.get(
        `/refactored/container/dashboard/user?appId=${appId}&containerId=${containerId}&containerInstanceId=${instanceId}`,
        configHeader()
      );
      if (!response.data) {
        response.data = {
          grids: [],
          tabs: [],
        };
      }
      dispatch({ type: "FETCH_USER_DASHBOARD", response: response.data });
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//Get all app settings according to container manager settings at user side
export const fetchAppSettingsByContainerInstance =
  (data) => async (dispatch) => {
    try {
      const response = await appSettingsApi.post(
        `/refactored/container-appsettings`,
        data,
        configHeader()
      );
      dispatch({
        type: "FETCH_APP_SETTINGS_BY_CONTAINER_INSTANCE",
        response: response,
      });
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//Get only necessary app settings data according to container manager settings at user side
export const fetchAppSettingsByContainerInstance_refactored =
  (data) => async (dispatch) => {
    try {
      const response = await appSettingsApi.post(
        `/refactored/container/appsettings/data`,
        data,
        configHeader()
      );
      dispatch({
        type: "FETCH_APP_SETTINGS_BY_CONTAINER_INSTANCE_ID",
        response: response,
      });
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//update app settings at container manager side
export const updateAppSettingsByContainerInstance =
  (data) => async (dispatch) => {
    try {
      const response = await appSettingsApi.put(
        "/refactored/isenable/container-appsettings",
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//update single app setting at container manager side
export const updateSingleAppSettingByContainerInstance =
  (data) => async (dispatch) => {
    try {
      const response = await appSettingsApi.put(
        "/refactored/container/appsettings/isenabled",
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//Get Dashboard Listing by App id and Container Id
export const fetchDashboardListByAppIdAndContainerId =
  (appId, containerId) => async (dispatch) => {
    try {
      const response = await appSettingsApi.get(
        `/refactored/container/dashboard?appId=${appId}&containerId=${containerId}`,
        configHeader()
      );
      dispatch({
        type: "FETCH_DASHBOARD_LIST_BY_APPID_AND_CONTAINERID",
        response: response,
      });
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//Get Test Management settings at user side by App id, Container Id and containerInstanceId
export const fetchTestManagementdListByAppIdAndContainerId =
  (data) => async (dispatch) => {
    try {
      const response = await appSettingsApi.post(
        `/refactored/testManagement/container`,
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

export const fetchCategoryIdByCategoryName = (name) => async (dispatch) => {
  try {
    const response = await appSettingsApi.get(
      `/refactored/category/name?category=${name}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};
/*-----------------------new API's ends here------------------------------ */
/*-----------------------old API's starts here------------------------------ */

export const fetchAppSettings = (data) => async (dispatch) => {
  try {
    // const response = data
    const response = await ApiUrl.get("/setting", configHeader());
    dispatch({ type: "FETCH_APP_SETTINGS", response: response });
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const fetchAppColorSettings = (data) => async (dispatch) => {
  try {
    // const response = data
    const response = await appSettingsApi.get(
      "/appsetting/colors",
      configHeader()
    );
    if (response && response?.status) {
      if (response.status === 401) {
        dispatch({ type: "FETCH_TIMEOUT_STATUS", response: true });
        window.location.href = process.env.REACT_APP_URL + "/login";
        return false;
      } else {
        dispatch({ type: "FETCH_APP_COLOR_SETTINGS", response: response });
        return response;
      }
    }
    // dispatch({ type: "FETCH_APP_COLOR_SETTINGS", response: response });
    // return response
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const saveAppSettings = (data) => async (dispatch) => {
  try {
    const response = await appSettingsApi.post("", data, configHeader());
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const updateAppSettings = (data) => async (dispatch) => {
  try {
    const response = await appSettingsApi.put("", data, configHeader());
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const fetchKanbanSettings = (appId, category) => async (dispatch) => {
  try {
    // const response = data
    const response = await ApiUrl.get(
      `/setting?${appId}/${category}`,
      configHeader()
    );
    dispatch({ type: "FETCH_KANBAN_SETTINGS", response: response });

    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const fetchAppSettingsWithAppId = (appId) => async (dispatch) => {
  try {
    const response = await typeSettingsApi.get(
      `/appid?appId=${appId}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//Get all app settings according to container manager settings at user side
export const fetchAppSettingsByContainerManager =
  (appId, containerId, containerInstanceId) => async (dispatch) => {
    try {
      const response = await appSettingsApi.get(
        `/ByApp/container?appId=${appId}&containerId=${containerId}&containerInstanceId=${containerInstanceId}`,
        configHeader()
      );
      dispatch({
        type: "FETCH_APP_SETTINGS_BY_CONTAINER_MANAGER",
        response: response,
      });
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//update app settings at container manager side
export const updateAppSettingsByContainerManager =
  (data) => async (dispatch) => {
    try {
      const response = await appSettingsApi.put(
        "/container",
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//Get all app settings by user
export const fetchAppSettingsByUser = (data) => async (dispatch) => {
  try {
    const response = await appSettingsApi.post(
      "/ByApp/container/user",
      data,
      configHeader()
    );
    dispatch({ type: "FETCH_APP_SETTINGS_BY_USER", response: response });
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//to fetch backlog dropdown data user side
export const fetchBacklogDropDownData =
  (appId, containerId, containerInstanceId) => async (dispatch) => {
    try {
      const response = await appSettingsApi.get(
        `backlog/enabledtype?appId=${appId}&containerId=${containerId}&containerInstanceId=${containerInstanceId}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

export const fetchAppSettingsByCategory =
  (appId, category) => async (dispatch) => {
    try {
      const response = await appSettingsApi.get(
        `/ByCategory?appId=${appId}&category=${category}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

/*-----------------------old API's ends here------------------------------ */
