export default (

    state = { 
        workflow: [],
    },   
    action
) => {
    switch (action.type) {
        case "FETCH_WORKFLOW": 
            return { ...state, workflow: action.response };
        default:
             return state;  
    }
};
