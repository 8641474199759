 
export default (

    state = { 
        globalSettings: [],
        emailSettings: [],
    },    
    action
) => {
    switch (action.type) {
        case "FETCH_GLOBAL_SETTINGS": 
             return { ...state, globalSettings: action.response };

             case "FETCH_EMAIL_SETTINGS": 
             return { ...state, emailSettings: action.response };
        
        default:
             return state;   
    }
};