import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import ErrorBoundary from "../../ErrorBoundry";

export default function LanguageTranslation({ key, text }) {
  const { t } = useTranslation();

  return (
    <ErrorBoundary>
      {key !== "" && text
        ? i18next.exists(i18next.language[key])
          ? t(i18next.language[key]) === ""
            ? text
            : t(i18next.language[key])
          : text
        : ""}
    </ErrorBoundary>
  );
}

export const LanguagePlaceholderFn = (t, key, text) => {
  let response =
    key !== "" && text
      ? i18next.exists(i18next.language[key])
        ? t(i18next.language[key]) === ""
          ? text
          : t(i18next.language[key])
        : text
      : "";
  return response;
};

export const LanguageChange = (value) => {
  i18next.changeLanguage(value);
};
