export default (
  state = {
    //old
    appSettings: [],
    appSettingsByContainerManager: [],
    appSettingsByUser: [],
    appColorSettingsByUser: [],

    //new
    allcategory: [],
    appSettingsByAppId: [],
    appSettingsByAppIdAndCategory: [],
    allColourSettingsByAppId: [],
    allColourSettingsOfAllApps: [],
    appSettingsByContainerInstance: [],
    appSettingsByContainerInstanceId: [],
  },
  action
) => {
  switch (action.type) {
    //new
    case "FETCH_ALL_CATEGORY":
      return { ...state, allcategory: action.response.data };
    case "FETCH_APP_SETTTINGS_BY_APPID":
      return { ...state, appSettingsByAppId: action.response.data };
    case "FETCH_APP_SETTTINGS_BY_APPID_AND_CATEGORY":
      return { ...state, appSettingsByAppIdAndCategory: action.response.data };
    case "FETCH_ALL_THREE_COLOUR_SETTTINGS_BY_APPID":
      return { ...state, allColourSettingsByAppId: action.response.data };
    case "FETCH_ALL_THREE_COLOUR_SETTTINGS_OF_ALL_APPS":
      return { ...state, allColourSettingsOfAllApps: action.response.data };
    case "FETCH_APP_SETTINGS_BY_CONTAINER_INSTANCE":
      return { ...state, appSettingsByContainerInstance: action.response.data };
    case "FETCH_APP_SETTINGS_BY_CONTAINER_INSTANCE_ID":
      return {
        ...state,
        appSettingsByContainerInstanceId: action.response.data,
      };

    //old
    case "FETCH_APP_SETTINGS":
      return { ...state, appSettings: action.response.data };
    case "FETCH_APP_COLOR_SETTINGS":
      return { ...state, appColorSettingsByUser: action.response.data };
    case "FETCH_APP_SETTINGS_BY_CONTAINER_MANAGER":
      return { ...state, appSettingsByContainerManager: action.response.data };
    case "FETCH_APP_SETTINGS_BY_USER":
      return { ...state, appSettingsByUser: action.response.data };
    default:
      return state;
  }
};
