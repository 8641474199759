export default (
    state = { typeConstraints: [], relationTypeConstraints: [] },
    action
) => { 
    switch (action.type) {
        case "SAVE_CONSTRAINT":
            return {
                ...state,
                // typeConstraints: [...state.typeConstraints, action.response],
                typeConstraints: [state.typeConstraints, action.response],
            };
        case "FETCH_TYPE_CONSTRAINTS":
            return { ...state, typeConstraints: action.response };
        case "FETCH_RELATION_TYPE_CONSTRAINTS":
            return { ...state, relationTypeConstraints: action.response };
        case "FETCH_CONSTRAINT":
            return { ...state, constraint: action.response };
        case "DELETE_CONSTRAINTS":
            return {
                ...state,
                typeConstraints: {
                    ...state.typeConstraints,
                    data: state.typeConstraints?.data.filter(
                        (constraint) => !action.response?.data?.data?.includes(constraint.id)
                    )
                },
            };
        default:
            return state;
    }
};
