export default (
  state = {
    dashboard: { grids: [], displayGrids: [], tabs: [] },
    appLayouts: [],
    chosenDashboard: { grids: [], displayGrids: [], tabs: [] },
    userDashboard: { grids: [], displayGrids: [], tabs: [] },
  },
  action
) => {
  switch (action.type) {
    case "FETCH_TYPE_LAYOUT":
      return { ...state, chosenLayout: action.response };

    case "FETCH_DASHBOARD":
     
      const grids = action.response.grids.map((grid) => {
        let layoutGrid = { id: grid.id, rows: [] };
        let j = 0;
        for (let i = 0; i < grid.rowsNumber; i++) {
          let columns = [];
          for (let k = 0; k < grid.colsNumber; k++) {
            columns.push({
              id: j,
              value: null,
              rownum: i,
              colnum: k,
            });
            j++;
          }
          layoutGrid.rows.push({
            id: i,
            columns: columns,
            distribution: grid.distribution,
            properties: grid.properties,
          });
        }
        grid.widgets.map((t) => {
          Object.entries(t).forEach(
            ([key, value]) =>
              (layoutGrid.rows[t.rownum].columns[t.colnum][key] = value)
          );
        });
        return layoutGrid;
      });

      const tabs = action.response.tabs;
      tabs.forEach((tabWidget) => {
        tabWidget.tabs.forEach((tab) => {
          const layoutGrids = tab.grids.map((grid) => {
            let layoutGrid = { id: grid.id, rows: [] };
            let j = 0;
            for (let i = 0; i < grid.rowsNumber; i++) {
              let columns = [];
              for (let k = 0; k < grid.colsNumber; k++) {
                columns.push({
                  id: j,
                  value: null,
                  rownum: i,
                  colnum: k,
                });
                j++;
              }
              layoutGrid.rows.push({
                id: i,
                columns: columns,
                distribution: grid.distribution,
                properties: grid.properties,
              });
            }
            grid.widgets.map((t) => {
              Object.entries(t).forEach(
                ([key, value]) =>
                  (layoutGrid.rows[t.rownum].columns[t.colnum][key] = value)
              );
            });
            return layoutGrid;
          });
          tab.grids = layoutGrids;
        });
      });
      action.response.grids = [...grids, ...tabs];
      action.response.grids.sort((v1, v2) => v1.order > v2.order);

      return { ...state, dashboard: action.response };

    case "FETCH_USER_DASHBOARD":
    
      // const userGrids = action.response.grids.map((grid) => {
      //   let layoutGrid = { id: grid.id, rows: [] };
      //   let j = 0;
      //   for (let i = 0; i < grid.rowsNumber; i++) {
      //     let columns = [];
      //     for (let k = 0; k < grid.colsNumber; k++) {
      //       columns.push({
      //         id: j,
      //         value: null,
      //         rownum: i,
      //         colnum: k,
      //       });
      //       j++;
      //     }
      //     layoutGrid.rows.push({
      //       id: i,
      //       columns: columns,
      //       distribution: grid.distribution,
      //       properties: grid.properties,
      //     });
      //   }
      //   grid.widgets.map((t) => {
      //     Object.entries(t).forEach(
      //       ([key, value]) =>
      //         (layoutGrid.rows[t.rownum].columns[t.colnum][key] = value)
      //     );
      //   });
      //   return layoutGrid;
      // });

      // const userTabs = action.response.tabs;
      // tabs.forEach((tabWidget) => {
      //   tabWidget.tabs.forEach((tab) => {
      //     const layoutGrids = tab.grids.map((grid) => {
      //       let layoutGrid = { id: grid.id, rows: [] };
      //       let j = 0;
      //       for (let i = 0; i < grid.rowsNumber; i++) {
      //         let columns = [];
      //         for (let k = 0; k < grid.colsNumber; k++) {
      //           columns.push({
      //             id: j,
      //             value: null,
      //             rownum: i,
      //             colnum: k,
      //           });
      //           j++;
      //         }
      //         layoutGrid.rows.push({ id: i, columns: columns });
      //       }
      //       grid.widgets.map((t) => {
      //         Object.entries(t).forEach(
      //           ([key, value]) =>
      //             (layoutGrid.rows[t.rownum].columns[t.colnum][key] = value)
      //         );
      //       });
      //       return layoutGrid;
      //     });
      //     tab.grids = layoutGrids;
      //   });
      // });
      // action.response.grids = [...userGrids, ...userTabs];
      // action.response.grids.sort((v1, v2) => v1.order > v2.order);
      //console.log(action.response)

      return { ...state, userDashboard: action.response };

    case "SAVE_DASHBOARD":
      return {
        ...state,
        appLayouts: [...state.appLayouts, action.response],
        tabs: [],
      };
    case "FETCH_APP_DASHBOARDS":
      return { ...state, appLayouts: action.response };

    case "DELETE_DASHBOARDS":
      return {
        ...state,
        appLayouts: state.appLayouts.filter(
          (layout) => !action.response.includes(layout.id)
        ),
      };

    default:
      return state;
  }
};
