import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

/*------------------------Material imports--------------------------------------*/
import { Grid, Tabs, Tab, Box } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { makeStyles } from "tss-react/mui";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
/*--------------------------------------------------------------------------------*/
/*---------------------------------------Other libraries--------------------------*/
import _ from "lodash";
/*--------------------------------------------------------------------------------*/
/*---------------------------------common files-----------------------------------*/
import "./ViewLayoutExternal.css";
import LongTextWidget from "./Widgets/LongTextWidget";
import ShortTextWidget from "./Widgets/ShortTextWidget";
import PickListWidget from "./Widgets/PickListWidget";
import DateExtWidget from "../Common/Widgets/DateExtWidget";
import DatetimeExtWidget from "../Common/Widgets/DatetimeExtWidget";
import DontShowWidget from "./Widgets/DontShowWidget";
import RelationshipWidget from "./Widgets/RelationshipWidget";
import ErrorBoundary from "../../../ErrorBoundry";
/*--------------------------------------------------------------------------------*/
/*-------------------------------------action calls-------------------------------*/
import { fetchAppSettingsByAppIdAndCategory } from "../../actions/appSettingsActions";
import {
  fetchExternalRelationshipByTypeIdIsEdit,
  fetchAlreadyLinkedExternalRelationshipdataByObjectId,
} from "../../actions/integrationApiActions";
/*--------------------------------------------------------------------------------*/

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  avatarImg: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const InnerLayout = (props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  let { appId } = useParams();
  const allUsers = useSelector(
    (state) => state.usersInfo.allUserAccordinToAppId.data
  );
  const [tabVal, setTabVal] = useState({});
  const [editableData, setEditableData] = useState(_.cloneDeep(props.data));
  const [layoutToDisplay, setLayoutToDisplay] = useState([]);
  const [dateFormat, setDateFormat] = useState("MMM d yyyy");
  const [relationships, setRelationships] = useState([]);
  const [dateTimeFormat, setDateTimeFormat] = useState("MMM d yyyy hh:mm a");
  const [relnLoaderOff, setRelnLoaderOff] = useState({});

  /*---------------------setRelationships--------------------*/
  useEffect(() => {
    if (props.typeId !== undefined && props.typeId !== null && props.typeId) {
      dispatch(fetchExternalRelationshipByTypeIdIsEdit(props.typeId)).then(
        (obj) => {
          if (obj.status === 200) {
            if (
              obj.data !== undefined &&
              obj.data !== null &&
              obj.data &&
              obj.data.length > 0
            ) {
              setRelationships(obj.data);
            }
          } else {
            props.setSeverity("error");
            props.setAlert(true);
            props.setMsg(`${obj.data}`);
          }
        }
      );
    }
  }, [props.typeId]);
  /*---------------------setRelationships--------------------*/
  /*------------------get relationship ids in layout-------------------*/
  useEffect(() => {
    if (relationships && relationships.length > 0) {
      let relnData = props.relationDataToSave;
      let loadReln = {};
      relationships.map((obj) => {
        const dataTobeSend = {
          relationId: obj.id,
          objectId: props.objectId,
          containerId: parseInt(props.containerId),
          containerInstanceId: parseInt(props.containerInstanceId),
          typeId: parseInt(props.typeId),
        };
        dispatch(
          fetchAlreadyLinkedExternalRelationshipdataByObjectId(dataTobeSend)
        ).then((result) => {
          if (result !== undefined && result !== null && result) {
            if (
              result.status !== undefined &&
              result.status !== null &&
              result.status &&
              result.status === 200
            ) {
              if (result.data) {
                relnData[obj.id] = result.data;
              }
              loadReln[obj.id] = true;
              setRelnLoaderOff({
                ...loadReln,
                [obj.id]: true,
              });
            } else {
              loadReln[obj.id] = true;
              setRelnLoaderOff({
                ...loadReln,
                [obj.id]: true,
              });
            }
          }
        });
      });
      props.setRelationData(relnData);
      props.setRelationDataToSave(relnData);
    }
  }, [relationships]);
  /*-------------------------------------------------------------------*/

  /*---------------------calling apis to fetch Date and date time format settings starts here--------------------*/
  useEffect(() => {
    if (appId != undefined && appId !== null && appId) {
      dispatch(fetchAppSettingsByAppIdAndCategory(appId, "dateformat")).then(
        (obj) => {
          if (obj.data) {
            if (
              obj.data &&
              obj.data.length !== 0 &&
              obj.data[0].appSettingDetails !== undefined &&
              obj.data[0].appSettingDetails !== null &&
              obj.data[0].appSettingDetails &&
              obj.data[0].appSettingDetails.length > 0 &&
              obj.data[0].appSettingDetails[0].value !== undefined &&
              obj.data[0].appSettingDetails[0].value !== null &&
              obj.data[0].appSettingDetails[0].value &&
              JSON.parse(obj.data[0].appSettingDetails[0].value) &&
              JSON.parse(obj.data[0].appSettingDetails[0].value)[0].dateFormat
            ) {
              setDateFormat(
                JSON.parse(obj.data[0].appSettingDetails[0].value)[0].dateFormat
              );
            }
          }
        }
      );
      dispatch(
        fetchAppSettingsByAppIdAndCategory(appId, "datetimeformat")
      ).then((obj) => {
        if (obj.data) {
          if (
            obj.data &&
            obj.data.length !== 0 &&
            obj.data[0].appSettingDetails !== undefined &&
            obj.data[0].appSettingDetails !== null &&
            obj.data[0].appSettingDetails &&
            obj.data[0].appSettingDetails.length > 0 &&
            obj.data[0].appSettingDetails[0].value !== undefined &&
            obj.data[0].appSettingDetails[0].value !== null &&
            obj.data[0].appSettingDetails[0].value &&
            JSON.parse(obj.data[0].appSettingDetails[0].value) &&
            JSON.parse(obj.data[0].appSettingDetails[0].value)[0].dateTimeFormat
          ) {
            setDateTimeFormat(
              JSON.parse(obj.data[0].appSettingDetails[0].value)[0]
                .dateTimeFormat
            );
          }
        }
      });
    }
  }, [appId]);

  useEffect(() => {
    const clonedData = _.cloneDeep(props.data);
    setEditableData(clonedData);
  }, [props.data]);
  /*----------------------------------------------------------------------*/

  /*------------------------------------useeffect to set tab value to  0 initially------------------------------------*/
  useEffect(() => {
    if (
      props.fetchedLayout &&
      props.fetchedLayout?.displayGrids &&
      props.fetchedLayout?.displayGrids.length > 0
    ) {
      props.fetchedLayout?.displayGrids.map((grid) => {
        if (grid.tabs && grid.tabs.length > 0) {
          setTabVal({ ...tabVal, [grid.id]: 0 });
        }
      });
    }
  }, [props.fetchedLayout?.displayGrids]);

  /*------------------------------------useeffect to design state layoutToDisplay------------------------------------*/
  useEffect(() => {
    const displayLayout = props.fetchedLayout;
    props.attributes.forEach((at) => (at.isOnLayout = false));
    if (displayLayout && !displayLayout?.displayGrids) {
      if (displayLayout.grids && displayLayout.tabs) {
        displayLayout.displayGrids = [
          ...displayLayout.grids,
          ...displayLayout.tabs,
        ];
      }
    }
    if (
      displayLayout?.displayGrids &&
      displayLayout?.displayGrids.length > 0 &&
      props.attributes.length > 0 &&
      props.data
    ) {
      setLayoutToDisplay(
        displayLayout?.order.map((ord) => {
          let gridField = displayLayout?.displayGrids.filter(
            (gridDat) => gridDat.id == ord.id
          );
          let grid = gridField.length && gridField[0] ? gridField[0] : null;
          if (ord.category == "grid" && grid) {
            const rows = [];
            for (let j = 0; j < grid.rowsNumber; j++) {
              let columns = [];
              for (let i = 0; i < grid.colsNumber; i++) {
                columns.push(<Grid item xs key={i}></Grid>);
              }
              rows.push(
                <Grid
                  container
                  spacing={2}
                  key={`keys${j}`}
                  style={{ marginLeft: "10px" }}
                >
                  {columns}
                </Grid>
              );
            }
            if (!props.attributes.length) {
              return;
            }
            adjustWidgets(grid.widgets, rows);
            return (
              <div className={classes.gridroot} key={`grid + ${grid.id}`}>
                {rows}
              </div>
            );
          } else if (ord.category == "tab" && grid) {
            const renderTabs = grid.tabs
              ? grid.tabs.map((tab, index) => {
                  return <Tab label={tab.name} value={index} tabid={grid.id} />;
                })
              : null;
            const tabPanel = grid.tabs
              ? grid.tabs.map((tab, index) => {
                  const tabGrids = tab.grids
                    ? tab.grids.map((tabGrid) => {
                        const rows = [];
                        for (let j = 0; j < tabGrid.rowsNumber; j++) {
                          let columns = [];
                          for (let i = 0; i < tabGrid.colsNumber; i++) {
                            columns.push(<Grid item xs key={i}></Grid>);
                          }
                          rows.push(
                            <Grid container spacing={2} key={`secondKey${j}`}>
                              {columns}
                            </Grid>
                          );
                        }
                        adjustWidgets(tabGrid.widgets, rows);
                        return <Grid>{rows}</Grid>;
                      })
                    : null;
                  return <TabPanel value={index}>{tabGrids}</TabPanel>;
                })
              : null;
            return (
              <TabContext value={tabVal[grid.id] ? tabVal[grid.id] : 0}>
                <Box
                  sx={{
                    bgcolor: "background.paper",
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <Tabs
                    value={tabVal[grid.id] ? tabVal[grid.id] : 0}
                    onChange={handleChangeTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    textColor="black"
                  >
                    {renderTabs}
                  </Tabs>
                </Box>
                {tabPanel}
              </TabContext>
            );
          }
        })
      );
    } else if (
      displayLayout?.displayGrids &&
      displayLayout?.displayGrids.length == 0
    ) {
      setLayoutToDisplay(
        <div className="noLayoutMsg">
          No layout exists for this Type. Please contact your administrator
        </div>
      );
    } else if (!displayLayout.displayGrids) {
      setLayoutToDisplay(
        <div className="noLayoutMsg">
          No layout exists for this Type. Please contact your administrator
        </div>
      );
    }
  }, [
    props.fetchedLayout,
    props.relationDataToSave,
    relnLoaderOff,
    tabVal,
    props.attributes,
    props.data,
    props.relationships,
    props.typeSettings,
    editableData,
    dateTimeFormat,
    dateFormat,
    allUsers,
  ]);
  /*-----props.fetchedLayout->layout load, tabVal->tab change, props.attributes-> type attributes load, commentValue & emptyComment->change comments, checking state of types , relnRef-> to search or coloumn choose or excel export in relationship grid------*/

  /*-----------------------------fn where attributes of layout are designed----------------------------------------*/
  const adjustWidgets = (widgets, rows) => {
    widgets.forEach((widget) => {
      if (widget.category === "ATTRIBUTE") {
        let attr = props.attributes.find(
          (e) => parseInt(e.id) === parseInt(widget.attributeId)
        );
        if (attr) {
          attr.isOnLayout = true;
          if (
            attr.dataType.key === "AUTO_INCREMENT" ||
            attr.dataType.key === "COMMENTS" ||
            attr.dataType.key === "WORKLOG" ||
            attr.dataType.key === "REFERENCED_ATTRIBUTE"
          ) {
            rows[widget.rownum].props.children[widget.colnum] = (
              <DontShowWidget attr={attr} />
            );
          } else if (attr.dataType.key === "LONG_TEXT") {
            rows[widget.rownum].props.children[widget.colnum] =
              attr.visibility != null && attr.visibility == false ? null : (
                <LongTextWidget attr={attr} facts={props.data} />
              );
          } else if (attr.dataType.key === "PICK_LIST") {
            rows[widget.rownum].props.children[widget.colnum] =
              attr.visibility != null && attr.visibility == false ? null : (
                <PickListWidget attr={attr} facts={props.data} />
              );
          } else if (attr.dataType.key === "DATE") {
            rows[widget.rownum].props.children[widget.colnum] =
              attr.visibility != null && attr.visibility == false ? null : (
                <DateExtWidget
                  attr={attr}
                  facts={props.data}
                  dateFormat={dateFormat}
                />
              );
          } else if (attr.dataType.key === "DATETIME") {
            rows[widget.rownum].props.children[widget.colnum] =
              attr.visibility != null && attr.visibility == false ? null : (
                <DatetimeExtWidget
                  attr={attr}
                  facts={props.data}
                  dateTimeFormat={dateTimeFormat}
                />
              );
          } else {
            rows[widget.rownum].props.children[widget.colnum] =
              attr.visibility != null && attr.visibility === false ? null : (
                <ShortTextWidget attr={attr} facts={props.data} />
              );
          }
        }
      } else if (widget.category === "LABEL") {
        rows[widget.rownum].props.children[widget.colnum] = (
          <Grid>{widget.value}</Grid>
        );
      } else if (
        widget.category === "RELATIONSHIP" &&
        widget.relationshipId !== undefined &&
        widget.relationshipId !== null &&
        widget.relationshipId !== 0 &&
        widget.relationshipId
      ) {
        rows[widget.rownum].props.children[widget.colnum] = (
          <RelationshipWidget
            relationshipId={widget.relationshipId}
            relationships={relationships}
            layoutType={props.layoutType}
            isContainer={false}
            widget={widget}
            dateFormat={dateFormat}
            dateTimeFormat={dateTimeFormat}
            appId={appId}
            objectId={props.objectId}
            typeId={props.typeId}
            layouttype={"view"}
            relationData={props.relationDataToSave}
            setRelationDataToSave={props.setRelationDataToSave}
            relnLoaderOff={relnLoaderOff}
            containerId={props.containerId}
            containerInstanceId={props.containerInstanceId}
            setMsg={props.setMsg}
            setSeverity={props.setSeverity}
            setAlert={props.setAlert}
            noMappedRlationship={props.noMappedRlationship}
            integrationProjectName={props.integrationProjectName}
            setChangedAttribute={props.setChangedAttribute}
          />
        );
      }
    });
  };
  /*-----------------------------------------------------------------------------------------------------------*/

  /*-----------------------------------fn called when tab changed-----------------------------------------*/
  const handleChangeTab = (event, value) => {
    let tabId = event.target.attributes.tabid.value;
    setTabVal({ ...tabVal, [tabId]: value });
  };
  /*------------------------------------------------------------------------------------------------------*/
  return (
    <ErrorBoundary>
      {props.showErrormessage ? (
        <h1>
          <Alert severity="info">
            <AlertTitle>{props.errorMessage}</AlertTitle>
          </Alert>
        </h1>
      ) : (
        layoutToDisplay
      )}{" "}
    </ErrorBoundary>
  );
};
export default InnerLayout;
