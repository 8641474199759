import React, { Suspense } from "react";
import ArofinityLoader from "./ArofinityLoader";

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<ArofinityLoader />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
