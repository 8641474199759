 
export default (

    state = { 
        typeSettings: [],
        typeSettingswithtypeid: [],
        typeSettingswithrelationtypeid: []
    },    
    action
) => {
    switch (action.type) {
        case "FETCH_TYPE_SETTINGS": 
             return { ...state, typeSettings: action.response };
        case "FETCH_TYPE_SETTINGS_WITH_TYPE_ID": 
             return { ...state, typeSettingswithtypeid: action.response };
        case "FETCH_TYPE_SETTINGS_WITH_RELATIONTYPE_ID": 
             return { ...state, typeSettingswithrelationtypeid: action.response };
        
        default:
             return state;   
    }
};