/*
 * Copyright by Intland Software
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Intland Software. ("Confidential Information"). You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Intland.
 */
import { EventNames, FunctionNames } from "@intland/cb-widget-shared";
import { WidgetClient } from "./widget-client";
/**
 * An API abstraction which provides an interface for a frontend application running inside an IFrame inside codeBeamer,
 * to communicate with the codeBeamer UI via the browser's `postMessage` API.
 *
 * @public
 */
var WidgetApi = /** @class */ (function () {
    /**
     * Initializes an API instance.
     *
     * @param window - A window-like instance which provides the `postMessage` interface. Usually just simply `window`.
     * @param widgetId - A unique string to represent this widget API instance.
     * @param origin - The origin of codeBeamer. Can be `"*"` if the host is unknown.
     */
    function WidgetApi(window, widgetId, origin) {
        this.client = new WidgetClient(window, widgetId, origin);
    }
    /**
     * Request a JWT token for the currently logged-in user. The token may expire.
     */
    WidgetApi.prototype.authenticate = function () {
        return this.client.call(FunctionNames.AUTHENTICATE, undefined);
    };
    /**
     * Asks codeBeamer to resize the IFrame window vertically inside the UI. Only works on the Tracker Item Details page.
     *
     * @param height - Preferred height in browser pixels.
     */
    WidgetApi.prototype.resize = function (height) {
        return this.client.call(FunctionNames.RESIZE, { height: height });
    };
    /**
     * Queries which tracker item is selected on the codeBeamer UI. May only work on certain pages.
     * It may return `undefined` if there is no tracker item selected.
     */
    WidgetApi.prototype.getSelectedItem = function () {
        return this.client.call(FunctionNames.GET_SELECTED_ITEM, undefined);
    };
    /**
     * Requests the user provided configuration for this particular widget instance.
     */
    WidgetApi.prototype.getConfig = function () {
        return this.client.call(FunctionNames.GET_CONFIG, undefined);
    };
    /**
     * Asks codeBeamer to reload a Tracker Item if it's present on the UI. May only work on certain pages.
     */
    WidgetApi.prototype.reloadItem = function (config) {
        return this.client.call(FunctionNames.RELOAD_ITEM, config);
    };
    /**
     * Requests the Swagger API's base URL, including the proper context. Ex.: https://codebeamer.com/cb/api
     */
    WidgetApi.prototype.getBaseURL = function () {
        return this.client.call(FunctionNames.GET_BASEURL, undefined);
    };
    /**
     * Registers a callback to be called whenever the user selects a tracker item on the codeBeamer UI. May only work on
     * certain pages.
     *
     * @param callback - A function to be called when the selection changes.
     */
    WidgetApi.prototype.onSelectedItemChange = function (callback) {
        return this.client.subscribe(EventNames.SELECTED_ITEM_CHANGED, function (payload) { return callback(payload.itemId); });
    };
    return WidgetApi;
}());
export { WidgetApi };
