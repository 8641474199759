// import {dateTimeFormat} from "../formatGridNData/dateFormat"
import {
  gridDateTimeFormat,
  gridDateTimeFormatIfObject,
  dateTimeFormatforTimeStamp,
} from "../formatGridNData/dateFormat";

function removeTags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
}

export default function FormatGridDataForLayouts(data, attributes, users) {
  attributes?.map((typeContain) => {
    let typeContainerName = typeContain.internalName;
    let typeContainerKey = typeContain.dataType.key;

    data?.map((attribute) => {
      if (attribute && typeContainerName && attribute[typeContainerName]) {
        if (typeContainerKey === "DATETIME" || typeContainerKey === "DATE") {
          if (
            attribute[typeContainerName] &&
            attribute[typeContainerName] != null
          ) {
            // console.log(attribute[typeContainerName])
            if (typeof attribute[typeContainerName] == "string") {
              //for ISO string
              attribute[typeContainerName] = gridDateTimeFormat(
                attribute[typeContainerName]
              );
            } else if (typeof attribute[typeContainerName] == "object") {
              //for GMT format
              let dateVal = new Date(attribute[typeContainerName] + " UTC");
              let isoVal = dateVal.toISOString(); //first converting to ISO string
              attribute[typeContainerName] = gridDateTimeFormat(isoVal);
            } else {
              //for time stamp
              let isoVal = new Date(attribute[typeContainerName]).toISOString(); //first converting to ISO string
              attribute[typeContainerName] = gridDateTimeFormat(isoVal);
            }
            return attribute;
          }
        } else if (typeContainerKey === "MULTIPLE_USERS") {
          if (
            attribute[typeContainerName] &&
            attribute[typeContainerName] != null
          ) {
            if (typeof attribute[typeContainerName] == "number") {
              let fetchuser =
                users !== undefined &&
                users !== null &&
                users &&
                users.length > 0
                  ? users.filter(
                      (obj) => obj.id == attribute[typeContainerName]
                    )
                  : [];
              attribute[typeContainerName] = fetchuser;
            }
            return attribute;
          }
        } else if (typeContainerKey === "INTEGER") {
          if (
            attribute[typeContainerName] &&
            attribute[typeContainerName] != null
          ) {
            //console.log("enter")
            // if(typeof(attribute[typeContainerName])=='number'){
            //     let fetchuser = users.filter(obj=>obj.id==attribute[typeContainerName])
            //     //console.log(fetchuser)
            //     attribute[typeContainerName] = fetchuser
            // }
            return attribute;
          }
        } else if (typeContainerKey == "FILE") {
          if (typeof attribute[typeContainerName] == "string") {
          } else {
            if (
              attribute[typeContainerName] &&
              attribute[typeContainerName].value &&
              attribute[typeContainerName].value.length > 0
            ) {
              let namesArr = attribute[typeContainerName].value.map((attr) => {
                return attr.filename;
              });
              attribute[typeContainerName] = namesArr.toString();
            } else {
              attribute[typeContainerName] = "";
            }
          }
          return attribute;
        } else if (typeContainerKey == "TYPE_PICK_LIST") {
          typeContain.displayString.split(";");
          const attr = typeContain?.displayString
            ? JSON.parse(typeContain.displayString)
            : [];
          if (
            attribute[typeContainerName] &&
            Array.isArray(attribute[typeContainerName]) &&
            attribute[typeContainerName].length > 0 &&
            attr &&
            attr.length
          ) {
            let refval = "";
            for (let i = 0; i < attribute[typeContainerName].length; i++) {
              let formattingRef = "";
              let id = "";
              for (let j = 0; j < attr.length; j++) {
                if (
                  attribute[typeContainerName][i] &&
                  attr[j].internalName &&
                  attribute[typeContainerName][i].value &&
                  attribute[typeContainerName][i].value[attr[j].internalName]
                ) {
                  if (
                    attr[j].dataType == "PICK_LIST" ||
                    attr[j].dataType == "MULTIPLE_USERS" ||
                    attr[j].dataType == "GROUP"
                  ) {
                    if (
                      attribute[typeContainerName][i]?.value[
                        attr[j].internalName
                      ].length > 0 &&
                      attribute[typeContainerName][i]?.value[
                        attr[j].internalName
                      ][0].name
                    ) {
                      let picklist = attribute[typeContainerName][i]?.value[
                        attr[j].internalName
                      ].map((obj) => obj.name);
                      formattingRef =
                        formattingRef + ", " + picklist.toString();
                      id = id + " " + attribute[typeContainerName][i]?.value.id;
                    } else {
                    }
                  } else if (attr[j].dataType == "DATETIME") {
                    if (
                      attribute[typeContainerName][i]?.value[
                        attr[j].internalName
                      ]
                    ) {
                      let dateRetrive =
                        attribute[typeContainerName][i]?.value[
                          attr[j].internalName
                        ];
                      // console.log(dateRetrive)
                      let finalDat = "";
                      // if(typeof(dateRetrive)=="string"){  //for ISO string
                      //   console.log(1)
                      //   finalDat = gridDateTimeFormat(
                      //     dateRetrive);
                      // }else
                      if (typeof dateRetrive == "object") {
                        //for GMT format
                        // console.log(2)
                        let dateVal = new Date(dateRetrive + " UTC");
                        let isoVal = dateVal.toISOString(); //first converting to ISO string
                        finalDat = gridDateTimeFormat(isoVal);
                      } else {
                        //for time stamp
                        // console.log(3)
                        let isoVal = new Date(dateRetrive).toISOString(); //first converting to ISO string
                        finalDat = gridDateTimeFormat(isoVal);
                      }
                      // console.log(finalDat)
                      formattingRef = formattingRef + ", " + finalDat;
                    }
                  } else if (attr[j].dataType == "FILE") {
                    if (
                      attribute[typeContainerName][i]?.value[
                        attr[j].internalName
                      ] &&
                      attribute[typeContainerName][i]?.value[
                        attr[j].internalName
                      ].value &&
                      attribute[typeContainerName][i]?.value[
                        attr[j].internalName
                      ].value.length
                    ) {
                      let filename = attribute[typeContainerName][i]?.value[
                        attr[j].internalName
                      ].value.map((obj) => obj.filename);
                      formattingRef =
                        formattingRef + ", " + filename.toString();
                      id = id + " " + attribute[typeContainerName][i]?.value.id;
                    }
                  } else if (attr[j].dataType == "LONG_TEXT") {
                    formattingRef =
                      formattingRef +
                      ", " +
                      removeTags(
                        attribute[typeContainerName][i]?.value[
                          attr[j].internalName
                        ]
                      );
                    id = id + " " + attribute[typeContainerName][i]?.value.id; //id + " " +attribute[typeContainerName][i]?.value.ID
                  } else {
                    formattingRef =
                      formattingRef +
                      ", " +
                      attribute[typeContainerName][i]?.value[
                        attr[j].internalName
                      ];
                    id = id + " " + attribute[typeContainerName][i]?.value.id; //id + " " +attribute[typeContainerName][i]?.value.ID
                  }
                } else {
                  if (
                    attribute[typeContainerName] &&
                    attribute[typeContainerName].length > 0 &&
                    attribute[typeContainerName][0].name
                  ) {
                    formattingRef = ", " + attribute[typeContainerName][0].name;
                  }
                }
              }
              if (i == 0) {
                refval = formattingRef;
                let res = formattingRef.slice(1);
                refval = { name: res, id: id };
              } else {
                //refval = refval + ";" + formattingRef; -> commented this line because of [objet object] was coming if attribute taken on display string is multivalued
                let res = formattingRef.slice(1);
                if (refval["name"]) {
                  refval["name"] = refval["name"] + ";" + res;
                  refval["id"] = refval["id"] + "," + id;
                }
              }
            }
            attribute[typeContainerName] = [refval];
          }
          // console.log(attribute)
          return attribute;
        } else if (typeContainerKey == "REFERENCED_ATTRIBUTE") {
          typeContain.displayString.split(";");
          const attr = typeContain?.displayString
            ? JSON.parse(typeContain.displayString)
            : [];
          if (
            attribute[typeContainerName] &&
            Array.isArray(attribute[typeContainerName]) &&
            attribute[typeContainerName].length > 0
          ) {
            // console.log(attribute[typeContainerName])
            let refval = "";
            for (let i = 0; i < attribute[typeContainerName].length; i++) {
              let formattingRef = "";
              let id = "";
              for (let j = 0; j < attr.length; j++) {
                // console.log(attribute[typeContainerName])
                // console.log(attr[j].internalName)
                if (
                  attribute[typeContainerName][i] &&
                  attr[j].internalName &&
                  attribute[typeContainerName][i].value &&
                  attribute[typeContainerName][i].value[attr[j].internalName]
                ) {
                  // console.log(attribute[typeContainerName][i].value[attr[j].internalName])
                  // console.log(attr[j].dataType.key)
                  if (
                    attr[j].dataType == "PICK_LIST" ||
                    attr[j].dataType == "MULTIPLE_USERS" ||
                    attr[j].dataType == "GROUP"
                  ) {
                    // console.log(attribute[typeContainerName][i]?.value[attr[j].internalName])
                    if (
                      attribute[typeContainerName][i]?.value[
                        attr[j].internalName
                      ].length > 0 &&
                      attribute[typeContainerName][i]?.value[
                        attr[j].internalName
                      ][0].name
                    ) {
                      let picklist = attribute[typeContainerName][i]?.value[
                        attr[j].internalName
                      ].map((obj) => obj.name);
                      formattingRef =
                        formattingRef + ", " + picklist.toString();
                      // console.log(formattingRef)
                      id = id + " " + attribute[typeContainerName][i]?.value.id;
                    } else {
                    }
                  } else if (
                    attr[j].dataType == "DATETIME" ||
                    attr[j].dataType == "DATE"
                  ) {
                    if (
                      attribute[typeContainerName][i]?.value[
                        attr[j].internalName
                      ]
                    ) {
                      let dateRetrive =
                        attribute[typeContainerName][i]?.value[
                          attr[j].internalName
                        ];
                      let finalDat = "";
                      if (typeof dateRetrive == "string") {
                        //for ISO string
                        finalDat = gridDateTimeFormat(dateRetrive);
                      } else if (typeof dateRetrive == "object") {
                        //for GMT format
                        let dateVal = new Date(dateRetrive + " UTC");
                        let isoVal = dateVal.toISOString(); //first converting to ISO string
                        finalDat = gridDateTimeFormat(isoVal);
                      } else {
                        //for time stamp
                        let isoVal = new Date(dateRetrive).toISOString(); //first converting to ISO string
                        finalDat = gridDateTimeFormat(isoVal);
                      }
                      formattingRef = formattingRef + ", " + finalDat;
                    }
                  } else if (attr[j].dataType == "FILE") {
                    if (
                      attribute[typeContainerName][i]?.value[
                        attr[j].internalName
                      ] &&
                      attribute[typeContainerName][i]?.value[
                        attr[j].internalName
                      ].value &&
                      attribute[typeContainerName][i]?.value[
                        attr[j].internalName
                      ].value.length
                    ) {
                      let filename = attribute[typeContainerName][i]?.value[
                        attr[j].internalName
                      ].value.map((obj) => obj.filename);
                      formattingRef =
                        formattingRef + ", " + filename.toString();
                      id = id + " " + attribute[typeContainerName][i]?.value.id;
                    }
                  } else if (attr[j].dataType.key == "LONG_TEXT") {
                    if (
                      attribute[typeContainerName][i]?.value[
                        attr[j].internalName
                      ]
                    ) {
                      formattingRef =
                        formattingRef +
                        ", " +
                        removeTags(
                          attribute[typeContainerName][i]?.value[
                            attr[j].internalName
                          ]
                        );
                      id = id + " " + attribute[typeContainerName][i]?.value.id; //id + " " +attribute[typeContainerName][i]?.value.ID
                    }
                  } else {
                    if (
                      attribute[typeContainerName][i]?.value[
                        attr[j].internalName
                      ]
                    ) {
                      formattingRef =
                        formattingRef +
                        ", " +
                        attribute[typeContainerName][i]?.value[
                          attr[j].internalName
                        ];
                      id = id + " " + attribute[typeContainerName][i]?.value.id;
                      //id + " " +attribute[typeContainerName][i]?.value.ID
                    }
                  }
                }
              }
              if (i == 0) {
                let res = formattingRef.slice(1);
                refval = res;
              } else {
                let res = formattingRef.slice(1);
                if (res) {
                  refval = refval + ";" + res;
                }
              }
            }
            attribute[typeContainerName] = refval;
          }
          return attribute;
        }else {
          return attribute;
        }
        // else if (typeContainerKey == "TYPE_PICK_LIST") {
        //   let attr = [];
        //   console.log('typeContain',typeContain)
        //   if (typeContain.displayString) {
        //     attr = typeContain.displayString.split(";");
        //   }
        //   console.log('attr',attr)
        //   if (attr.length == 0) {
        //     attr = typeContain.displayString.split(",");
        //   }
        //   console.log('attr',attr)
        //   console.log('attribute',attribute)
        //   if (
        //     attribute[typeContainerName] &&
        //     Array.isArray(attribute[typeContainerName]) &&
        //     attribute[typeContainerName].length > 0 &&
        //     attr &&
        //     attr.length
        //   ) {
        //     let refval = "";
        //     for (let i = 0; i < attribute[typeContainerName].length; i++) {
        //        console.log(attribute[typeContainerName][i])
        //       let formattingRef = "";
        //       let id = "";
        //       for (let j = 0; j < attr.length; j++) {
        //         // console.log("111")
        //         console.log(attribute[typeContainerName])
        //         console.log(attr)
        //         if (
        //           attribute[typeContainerName][i] &&
        //           attr[j].internalName &&
        //           attribute[typeContainerName][i].value &&
        //           attribute[typeContainerName][i].value[attr[j].internalName]
        //         ) {
        //           console.log("2222")
        //           console.log(attribute[typeContainerName][i]?.value[attr[j].internalName])
        //           if (
        //             attr[j].dataType.key == "PICK_LIST" ||
        //             attr[j].dataType.key == "MULTIPLE_USERS" ||
        //             attr[j].dataType.key == "GROUP"
        //           ) {
        //             if (
        //               attribute[typeContainerName][i]?.value[
        //                 attr[j].internalName
        //               ].length > 0 &&
        //               attribute[typeContainerName][i]?.value[
        //                 attr[j].internalName
        //               ][0].name
        //             ) {
        //               let picklist = attribute[typeContainerName][i]?.value[
        //                 attr[j].internalName
        //               ].map((obj) => obj.name);
        //               formattingRef =
        //                 formattingRef + ", " + picklist.toString();
        //               //console.log(formattingRef)
        //               id = id + " " + attribute[typeContainerName][i]?.value.id;
        //             } else {
        //             }
        //           } else if (attr[j].dataType.key == "FILE") {
        //             if (
        //               attribute[typeContainerName][i]?.value[
        //                 attr[j].internalName
        //               ] &&
        //               attribute[typeContainerName][i]?.value[
        //                 attr[j].internalName
        //               ].value &&
        //               attribute[typeContainerName][i]?.value[
        //                 attr[j].internalName
        //               ].value.length
        //             ) {
        //               let filename = attribute[typeContainerName][i]?.value[
        //                 attr[j].internalName
        //               ].value.map((obj) => obj.filename);
        //               formattingRef =
        //                 formattingRef + ", " + filename.toString();
        //               id = id + " " + attribute[typeContainerName][i]?.value.id;
        //             }
        //           } else if (attr[j].dataType.key == "LONG_TEXT") {
        //             formattingRef =
        //               formattingRef +
        //               ", " +
        //               removeTags(
        //                 attribute[typeContainerName][i]?.value[
        //                   attr[j].internalName
        //                 ]
        //               );
        //             id = id + " " + attribute[typeContainerName][i]?.value.id; //id + " " +attribute[typeContainerName][i]?.value.ID
        //           } else {
        //             // console.log("enter2")
        //             // console.log(attribute[typeContainerName][i])
        //             formattingRef =
        //               formattingRef +
        //               ", " +
        //               attribute[typeContainerName][i]?.value[
        //                 attr[j].internalName
        //               ];
        //             id = id + " " + attribute[typeContainerName][i]?.value.id; //id + " " +attribute[typeContainerName][i]?.value.ID
        //           }
        //         } else {
        //           // console.log(attribute[typeContainerName][0].name)
        //           if (
        //             attribute[typeContainerName] &&
        //             attribute[typeContainerName].length > 0 &&
        //             attribute[typeContainerName][0].name
        //           ) {
        //             formattingRef = ", " + attribute[typeContainerName][0].name;
        //           }
        //         }
        //       }
        //       if (i == 0) {
        //         // console.log("1")
        //         console.log('formattingRef',formattingRef)
        //         refval = formattingRef;
        //         console.log('refval in first',refval)
        //         let res = formattingRef.slice(1);
        //         console.log('res',res)
        //         refval = { name: res, id: id };
        //       } else {
        //         // console.log("2")
        //         //refval = refval + ";" + formattingRef;
        //         let res = formattingRef.slice(1);
        //         if (refval["name"]) {
        //           refval["name"] = refval["name"] + ";" + res;
        //           refval["id"] = refval["id"] + "," + id;
        //         }
        //         console.log('refval',refval)
        //       }
        //     }
        //     console.log('refval final',refval)
        //     attribute[typeContainerName] = [refval];
        //   }
        //   return attribute;
        // } else if (typeContainerKey == "REFERENCED_ATTRIBUTE") {
        //   let attr = [];
        //   if (typeContain.displayString) {
        //     attr = typeContain.displayString.split(";");
        //   }
        //   if (attr.length == 0) {
        //     attr = typeContain.displayString.split(",");
        //   }
        //   if (
        //     attribute[typeContainerName] &&
        //     Array.isArray(attribute[typeContainerName]) &&
        //     attribute[typeContainerName].length > 0
        //   ) {
        //     // console.log(attribute[typeContainerName])
        //     let refval = "";
        //     for (let i = 0; i < attribute[typeContainerName].length; i++) {
        //       let formattingRef = "";
        //       let id = "";
        //       for (let j = 0; j < attr.length; j++) {
        //         // console.log(attribute[typeContainerName])
        //         // console.log(attr[j].internalName)
        //         if (
        //           attribute[typeContainerName][i] &&
        //           attr[j].internalName &&
        //           attribute[typeContainerName][i].value &&
        //           attribute[typeContainerName][i].value[attr[j].internalName]
        //         ) {
        //           // console.log(attribute[typeContainerName][i].value[attr[j].internalName])
        //           //console.log(attr[j].dataType.key)
        //           if (
        //             attr[j].dataType.key == "PICK_LIST" ||
        //             attr[j].dataType.key == "MULTIPLE_USERS" ||
        //             attr[j].dataType.key == "GROUP"
        //           ) {
        //             //console.log(attribute[typeContainerName][i]?.value[attr[j].internalName])
        //             if (
        //               attribute[typeContainerName][i]?.value[
        //                 attr[j].internalName
        //               ].length > 0 &&
        //               attribute[typeContainerName][i]?.value[
        //                 attr[j].internalName
        //               ][0].name
        //             ) {
        //               let picklist = attribute[typeContainerName][i]?.value[
        //                 attr[j].internalName
        //               ].map((obj) => obj.name);
        //               formattingRef =
        //                 formattingRef + ", " + picklist.toString();
        //               //console.log(formattingRef)
        //               id = id + " " + attribute[typeContainerName][i]?.value.id;
        //             } else {
        //             }
        //           } else if (attr[j].dataType.key == "FILE") {
        //             if (
        //               attribute[typeContainerName][i]?.value[
        //                 attr[j].internalName
        //               ] &&
        //               attribute[typeContainerName][i]?.value[
        //                 attr[j].internalName
        //               ].value &&
        //               attribute[typeContainerName][i]?.value[
        //                 attr[j].internalName
        //               ].value.length
        //             ) {
        //               let filename = attribute[typeContainerName][i]?.value[
        //                 attr[j].internalName
        //               ].value.map((obj) => obj.filename);
        //               formattingRef =
        //                 formattingRef + ", " + filename.toString();
        //               id = id + " " + attribute[typeContainerName][i]?.value.id;
        //             }
        //           } else if (attr[j].dataType.key == "LONG_TEXT") {
        //             formattingRef =
        //               formattingRef +
        //               ", " +
        //               removeTags(
        //                 attribute[typeContainerName][i]?.value[
        //                   attr[j].internalName
        //                 ]
        //               );
        //             id = id + " " + attribute[typeContainerName][i]?.value.id; //id + " " +attribute[typeContainerName][i]?.value.ID
        //           } else {
        //             formattingRef =
        //               formattingRef +
        //               ", " +
        //               attribute[typeContainerName][i]?.value[
        //                 attr[j].internalName
        //               ];
        //             id = id + " " + attribute[typeContainerName][i]?.value.id;
        //             //id + " " +attribute[typeContainerName][i]?.value.ID
        //           }
        //         }
        //       }
        //       if (i == 0) {
        //         let res = formattingRef.slice(1);
        //         refval = res;
        //       } else {
        //         let res = formattingRef.slice(1);
        //         refval = refval + ";" + res;
        //       }
        //     }
        //     attribute[typeContainerName] = refval;
        //   }
        //   return attribute;
        // }
      } else {
        return attribute;
      }
    });
  });
  return data;
}
