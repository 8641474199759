export default (
    state = { objects: [], objectswithrelations: [], chosenObject: {}, chosenObjectHistory: {}, createobjects:{}, backlogobjects: [], assignContainerTitle:"" },
    action
) => {
    switch (action.type) {
        case "FETCH_INSTANCE":
            return { ...state, objects: action.response };
        case "FETCH_INSTANCE_WITH_RELATION":
            return { ...state, objectswithrelations: action.response };
        case "FETCH_RELATION_INSTANCE":
            return { ...state, relationObjects: action.response };
        case "SAVE_INSTANCE":
            return { ...state, objects: action.response };
        case "CREATE_INSTANCE":
            return { ...state, createobjects: action.response };
        case "FETCH_OBJECT":
            return { ...state, chosenObject: action.response };
        case "FETCH_OBJECT_HISTORY":
            return { ...state, chosenObjectHistory: action.response };
        case "CLEAR_TYPE_INSTANCES": {
            return { ...state, objects: [] };
        }
        case "ASSIGN_CONTAINERTITLE": {
            return { ...state, assignContainerTitle:action.response  };
        }
        case "FETCH_BACKLOG_INSTANCE": {
            return { ...state, backlogobjects:action.response  };
        }
        case "MODIFY_SINGLE_TYPE" :{
            return { ...state, objects: [...state.objects, action.response] };
        }
        case "VIEW_FILTER_SETTING_INSTANCE" :{
            return { ...state, objects: [...state.objects, action.response] };
        }
        case "UPDATE_INLINE_DATA" :{
            return { ...state, objects: [...state.objects, action.response] };
        }
        default:
            return state;
    }
};
