import ApiUrl from "../apis/ApiUrl";
import layoutApi from "../apis/layoutApi";
import { configHeader } from "./header-configure";
import { redirect } from "./redirect";
import { configHeaderwithId } from "./header-configure";

export const fetchAttributes = () => async (dispatch) => {
  try {
    const response = await layoutApi.get("/attributes", configHeader());
    if (!response.data) {
      response.data = {};
    }
    dispatch({ type: "FETCH_ATTRIBUTES", response: response.data });
  } catch (e) {
    redirect(e, dispatch);
  }
};

export const fetchLayout = (id) => async (dispatch) => {
  try {
    const response = await ApiUrl.get(`/layout?id=${id}`, configHeader());
    if (!response.data) {
      response.data = {};
    }
    dispatch({ type: "FETCH_LAYOUT", response: response.data });
  } catch (e) {
    redirect(e, dispatch);
  }
};

export const fetchLayoutCategories = () => async (dispatch) => {
  try {
    const response = await layoutApi.get("/categories", configHeader());
    if (!response.data) {
      response.data = {};
    }
    dispatch({ type: "FETCH_LAYOUT_CATEGORIES", response: response.data });
  } catch (e) {
    redirect(e, dispatch);
  }
};

export const fetchLayouts = (typeId) => async (dispatch) => {
  try {
    const response = await layoutApi.get(`/type?id=${typeId}`, configHeader());
    if (!response.data) {
      response.data = {};
    }
    dispatch({ type: "FETCH_LAYOUTS", response: response.data });
  } catch (e) {
    redirect(e, dispatch);
  }
};

export const saveLayout = (data) => async (dispatch) => {
  try {
    const response = await layoutApi.post("", data, configHeader());
    if (!response.data) {
      response.data = {};
    }
    // response.data.message = "Layout Saved Succesfuly";
    dispatch({ type: "SAVE_LAYOUT", response: response.data });
    dispatch({ type: "DISPATCH_MESSAGE", response: response });
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const modifyLayout = (data) => async (dispatch) => {
  try {
    const response = await layoutApi.put("", data, configHeader());
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const fetchViewCategory = (id) => async (dispatch) => {
  try {
    const response = await layoutApi.get(
      `/type/category?id=${id}&category=VIEW`,
      configHeader()
    );
    return response;
  } catch (e) {
    redirect(e, dispatch);
  }
};

export const fetchViewLayout = (id) => async (dispatch) => {
  try {
    const response = await ApiUrl.get(`/layout?id=${id}`, configHeader());
    dispatch({ type: "FETCH_VIEW_TYPE_LAYOUT", response: response });
    return response;
  } catch (e) {
    redirect(e, dispatch);
  }
};

export const fetchEditCategory = (id) => async (dispatch) => {
  try {
    const response = await layoutApi.get(
      `/type/category?id=${id}&category=EDIT`,
      configHeader()
    );
    return response;
  } catch (e) {
    redirect(e, dispatch);
  }
};

export const fetchEditLayout = (id) => async (dispatch) => {
  try {
    const response = await ApiUrl.get(`/layout?id=${id}`, configHeader());
    dispatch({ type: "FETCH_EDIT_TYPE_LAYOUT", response: response });
    return response;
  } catch (e) {
    redirect(e, dispatch);
  }
};

export const fetchCreationCategory = (id) => async (dispatch) => {
  try {
    const response = await layoutApi.get(
      `/type/category?id=${id}&category=CREATE`,
      configHeader()
    );
    dispatch({ type: "FETCH_EDIT_TYPE_LAYOUT", response: response });
    return response;
  } catch (e) {
    redirect(e, dispatch);
  }
};

export const fetchCreationLayout = (id) => async (dispatch) => {
  try {
    const response = await ApiUrl.get(`/layout?id=${id}`, configHeader());
    dispatch({ type: "FETCH_CREATION_TYPE_LAYOUT", response: response });
    return response;
  } catch (e) {
    redirect(e, dispatch);
  }
};

export const fetchRelationCreationLayout = (id) => async (dispatch) => {
  try {
    const response = await layoutApi.get(
      `/type/category?id=${id}&category=CREATE`,
      configHeader()
    );
    if (!response.data) {
      response.data = {};
    }
    if (response?.data?.id) {
      const result = response.data.id
        ? await ApiUrl.get(`/layout?id=${response.data.id}`, configHeader())
        : {};
      if (result && result.data) {
        dispatch({
          type: "FETCH_RELATION_CREATION_TYPE_LAYOUT",
          response: result.data,
        });
      } else {
        dispatch({
          type: "FETCH_RELATION_CREATION_TYPE_LAYOUT",
          response: result,
        });
      }
    }
    return response;
  } catch (e) {
    redirect(e, dispatch);
  }
};

export const fetchPreviewCategory = (id) => async (dispatch) => {
  try {
    const response = await layoutApi.get(
      `/type/category?id=${id}&category=PREVIEW`,
      configHeader()
    );
    return response;
  } catch (e) {
    redirect(e, dispatch);
  }
};

export const fetchPreviewLayout = (id) => async (dispatch) => {
  try {
    const response = await ApiUrl.get(`/layout?id=${id}`, configHeader());
    dispatch({ type: "FETCH_PREVIEW_TYPE_LAYOUT", response: response });
    return response;
  } catch (e) {
    redirect(e, dispatch);
  }
};

export const fetchRelationPreviewLayout = (id) => async (dispatch) => {
  try {
    const response = await layoutApi.get(
      `/type/category?id=${id}&category=PREVIEW`,
      configHeader()
    );
    if (!response.data) {
      response.data = {};
    }
    if (response?.data?.id) {
      const result = response.data.id
        ? await layoutApi.get(`?id=${response.data.id}`, configHeader())
        : {};
      if (result && result.data) {
        dispatch({
          type: "FETCH_RELATION_PREVIEW_TYPE_LAYOUT",
          response: result.data,
        });
      } else {
        dispatch({
          type: "FETCH_RELATION_PREVIEW_TYPE_LAYOUT",
          response: result,
        });
      }
    }
    return response;
  } catch (e) {
    redirect(e, dispatch);
  }
};

export const deleteLayouts = (data) => async (dispatch) => {
  try {
    const response = await layoutApi.delete("", configHeaderwithId(data));
    dispatch({ type: "DELETE_LAYOUTS", response: data?.ids });
    return response;
  } catch (e) {
    redirect(e, dispatch);
  }
};

export const clearLayouts = () => {
  return { type: "CLEAR_LAYOUTS" };
};
