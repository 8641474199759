import React from "react";

/*------------------------Material imports--------------------------------------*/
import {
  Typography,
  IconButton
} from "@mui/material";
import { withStyles } from 'tss-react/mui';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import MuiDialogTitle from "@mui/material/DialogTitle";
import HeightIcon from "@mui/icons-material/Height";
import MinimizeIcon from "@mui/icons-material/Minimize";
/*-----------------------------------Other libraries--------------------------------------*/

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    gridroot: {
      flexGrow: 1,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    closeButton_Mobile: {
      position: "absolute",
      right: theme.spacing(4),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
      marginRight: 11,
    },
    enlargeDialog: {
      // marginLeft: '92%',
      // marginTop: '-5%',
      // transform: 'rotate(40deg)'
      position: "absolute",
      right: theme.spacing(4),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
      marginRight: 11,
    },
    save_Mobile: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  });

export const DialogTitle = withStyles((props) => {
  const { children, classes, onClose, dialogSize, handleSize, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>

      <div style={{ display: "flex" }}>
        <IconButton
          aria-label={
            dialogSize === "md" ? "maximize dialog" : "minimize dialog"
          }
          className={classes.enlargeDialog}
          title={dialogSize === "md" ? "maximize" : "minimize"}
          onClick={handleSize}
          size="large"
        >
          {dialogSize === "md" ? <HeightIcon /> : <MinimizeIcon />}
        </IconButton>

        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => onClose(false)}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </div>
    </MuiDialogTitle>
  );
}, styles);

export const DialogTitleMobile = withStyles((props) => {
  const { children, classes, onClose, save, loading, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>

      <div style={{ display: "flex" }}>
        <IconButton
          aria-label="close"
          className={classes.closeButton_Mobile}
          onClick={() => onClose(false)}
          size="large"
        >
          <CloseIcon />
        </IconButton>

        <IconButton
          className={classes.save_Mobile}
          disabled={loading ? true : false}
          onClick={save}
          color="primary"
          size="large"
        >
          <CheckCircleIcon />
        </IconButton>
      </div>
    </MuiDialogTitle>
  );
}, styles);
