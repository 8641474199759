import appExportJsonApi from "../apis/appExportJsonApi";
import { configHeader } from "./header-configure";
import { configHeaderMultipart } from "./header-configure";
import { redirect } from "./redirect";

export const exportApp = (appId) => async (dispatch) => {
  try {
    const response = await appExportJsonApi.get(
      `/apps/export?appId=${appId}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const importApp = (form_data) => async (dispatch) => {
  try {
    const response = await appExportJsonApi.post(
      `/apps/import`,
      form_data,
      configHeaderMultipart()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const importAppAlert = (show, success) => async (dispatch) => {
  await dispatch({
    type: "SHOW_IMPORT_MESSAGE",
    response: { show: show, success: success },
  });
};

export const importWaitingMessage = (show) => async (dispatch) => {
  await dispatch({
    type: "SHOW_IMPORT_WAITING_MESSAGE",
    response: { show: show },
  });
};
