export default (
  state = {
    emailTemplate: [],
    emailTemplateByTypeId : [],
  },
  action
) => {
  switch (action.type) {
    case "FETCH_TEMPLATE":
      return { ...state, emailTemplate: action.response };
    // return { ...state, emailTemplate: [...state.emailTemplate]};
    case "FETCH_TEMPLATE_BY_TYPE_ID":
      return { ...state, emailTemplate: action.response };
    case "SAVE_TEMPLATE":
      return {
        ...state,
        emailTemplate: [...state.emailTemplate, action.response],
      };
    default:
      return state;
  }
};
