import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";

export default function ({ title, header, remove, open, setOpen }) {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      aria-labelledby="alert-dialog-slide-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {title ? title : "Delete"}
      </DialogTitle>
      <DialogContent>{header}</DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary" size="small">
          No
        </Button>
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={remove}
          content="Submit"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
