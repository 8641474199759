export default (state = { roles: []}, action) => {
    switch (action.type) {
        case "FETCH_ALL_ROLES":
            return { ...state, roles: action.response };
        case "FETCH_APP_ROLES":
            return { ...state, roles: action.response }; 
        case "DELETE_ROLE": {
            return {
                ...state,
                roles: [...state.roles.filter((v) => v.id !== action.response)],
            };
        }
        case "SAVE_ROLE":
            return { ...state, roles: [...state.roles, action.response] };
        default:
            return state;
    }
};
