export default (
  state = {
    externalProduct: { name: "", value: false },
  },
  action
) => {
  switch (action.type) {
    case "EXTERNAL_PRODUCT_ENABLE_DISABLE":
      return { ...state, externalProduct: action.response };
    default:
      return state;
  }
};
