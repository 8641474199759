import { fetchAppSettingsByAppIdAndCategory } from "../actions/appSettingsActions";

export const DateAndDateTimeSettings = (
  dispatch,
  appId,
  setDateFormat,
  setDateTimeFormat
) => {
  dispatch(fetchAppSettingsByAppIdAndCategory(appId, "dateformat")).then(
    (obj) => {
      if (obj.data) {
        if (
          obj.data &&
          obj.data.length !== 0 &&
          obj.data[0].appSettingDetails !== undefined &&
          obj.data[0].appSettingDetails !== null &&
          obj.data[0].appSettingDetails &&
          obj.data[0].appSettingDetails.length > 0 &&
          obj.data[0].appSettingDetails[0].value !== undefined &&
          obj.data[0].appSettingDetails[0].value !== null &&
          obj.data[0].appSettingDetails[0].value &&
          JSON.parse(obj.data[0].appSettingDetails[0].value) &&
          JSON.parse(obj.data[0].appSettingDetails[0].value)[0].dateFormat
        ) {
          setDateFormat(
            JSON.parse(obj.data[0].appSettingDetails[0].value)[0].dateFormat
          );
        } else {
          setDateFormat("MMM d yyyy");
        }
      }
    }
  );
  dispatch(fetchAppSettingsByAppIdAndCategory(appId, "datetimeformat")).then(
    (obj) => {
      if (obj.data) {
        if (
          obj.data &&
          obj.data.length !== 0 &&
          obj.data[0].appSettingDetails !== undefined &&
          obj.data[0].appSettingDetails !== null &&
          obj.data[0].appSettingDetails &&
          obj.data[0].appSettingDetails.length > 0 &&
          obj.data[0].appSettingDetails[0].value !== undefined &&
          obj.data[0].appSettingDetails[0].value !== null &&
          obj.data[0].appSettingDetails[0].value &&
          JSON.parse(obj.data[0].appSettingDetails[0].value) &&
          JSON.parse(obj.data[0].appSettingDetails[0].value)[0].dateTimeFormat
        ) {
          setDateTimeFormat(
            JSON.parse(obj.data[0].appSettingDetails[0].value)[0].dateTimeFormat
          );
        } else {
          setDateTimeFormat("MMM d yyyy hh:mm a");
        }
      }
    }
  );
};
