import migrationApi from "../apis/migrationApi";
import { configHeader } from "./header-configure";
import { redirect } from "./redirect";

//For getting attributes by type Id according to new integration concepet
export const fetchMigrationListingByAppId = (appId) => async (dispatch) => {
  try {
    const response = await migrationApi.get(
      `/grid/details?appId=${appId}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//To save first step of migration
export const createMigration = (data) => async (dispatch) => {
  try {
    const response = await migrationApi.post(`/save`, data, configHeader());
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//To update first step of migration
export const updateSystemMigration = (data) => async (dispatch) => {
  try {
    const response = await migrationApi.put(`/update`, data, configHeader());
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//To Get System details for first step of migration
export const fetchSystemMigration = (migrationId) => async (dispatch) => {
  try {
    const response = await migrationApi.get(
      `/details?id=${migrationId}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//To get status of migration in each step
export const getMigrationStatus = (migrationId) => async (dispatch) => {
  try {
    const response = await migrationApi.get(
      `/status?id=` + migrationId,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//To get types by migration id
export const getTypeByMigration = (migrationId) => async (dispatch) => {
  try {
    const response = await migrationApi.get(
      `/type/get?migrationId=` + migrationId,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//To get only Enable List:
export const getEnableMigration = (migrationId) => async (dispatch) => {
  try {
    const response = await migrationApi.get(
      `/type/get/enable?migrationId=` + migrationId,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//API to List of Migration Attributes:
export const getMigrationAttributes = (migrationId) => async (dispatch) => {
  try {
    const response = await migrationApi.get(
      `/attributes/get?migrationTypeId=` + migrationId,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//API to save Migration Type:
export const saveMigrationTypes = (data) => async (dispatch) => {
  try {
    const response = await migrationApi.post(
      `/type/save`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//API to save Migration Attributes:
export const saveMigrationAttributes = (data) => async (dispatch) => {
  try {
    const response = await migrationApi.post(
      `/attributes/save`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//API to update Migration Type:
export const updateMigrationType = (data) => async (dispatch) => {
  try {
    const response = await migrationApi.put(
      `/type/update`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//delete migration by Id
export const deleteMigrationById = (id) => async (dispatch) => {
  try {
    const response = await migrationApi.delete(
      `/delete?id=${id}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//API to start Migration :
export const startMigration = (data) => async (dispatch) => {
  try {
    const response = await migrationApi.post(
      `/data/start`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//For getting  migration detail
export const fetchMigrationDetail = (migrationId) => async (dispatch) => {
  try {
    const response = await migrationApi.get(
      `/details/all?id=${migrationId}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//For getting basic migration summary by Id
export const fetchBasicMigrationSummarById = (id) => async (dispatch) => {
  try {
    const response = await migrationApi.get(
      `/get/summary?id=${id}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//API to Fetch migration status by migration id
export const fetchMigrationStatusById = (id) => async (dispatch) => {
  try {
    const response = await migrationApi.get(
      `/status/summary?id=${id}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//Migration started flag
export const migrationFlag = (migrationData) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_MIGRATION_ID", response: migrationData });
  } catch (e) {
    // redirect(e, dispatch);
  }
};

//API to Count data before migration
export const fetchMigrationCount = (data) => async (dispatch) => {
  try {
    const response = await migrationApi.post(
      `/data/before/count`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//API which will return the id and name of migrations which are in-progress
export const fetchUsersMigration = () => async (dispatch) => {
  try {
    const response = await migrationApi.get(
      `/data/get/status/inprogress`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

// API to get the summary details on clicking on eye icon
export const fetchViewSummaryDetailsByImportId = (id) => async (dispatch) => {
  try {
    const response = await migrationApi.get(
      `/summary/get/details?migrationsummaryid=${id}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};
//For getting arofinity relationships by migration Id
export const fetchRelnByMigrationId = (migrationId) => async (dispatch) => {
  try {
    const response = await migrationApi.get(
      `/relationship/get/enabled?migrationid=${migrationId}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//API to save Migration Reln Mapping:
export const saveMigrationRelns = (data) => async (dispatch) => {
  try {
    const response = await migrationApi.post(
      `/relationship/save`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//API to give the relationship list for migration where From Type is Arofinity
export const fetchRelnForAro = (migrationId) => async (dispatch) => {
  try {
    const response = await migrationApi.get(
      `/relationship/arofinity?migrationId=${migrationId}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};
