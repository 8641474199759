export default (
  state = {
    allApps: [],
    adminApps: [],
    chosenApp: [],
    showCreateNewAppDialog: false,
    showSettinsValue: [],
  },
  action
) => {
  switch (action.type) {
    case "FETCH_APPS":
      return { ...state, allApps: action.response };
    case "FETCH_ADMIN_APPS":
      return { ...state, adminApps: action.response };
    case "SAVE_APP":
      return { ...state, allApps: [...state.allApps, action.response] };
    case "FETCH_APP":
      return { ...state, chosenApp: action.response };
    case "SHOW_CREATE_APP_DIALOG":
      return { ...state, showCreateNewAppDialog: action.response };
    case "APP_SETTINGS_SAVE_VALUE":
      return { ...state, showSettinsValue: action.response };
    default:
      return state;
  }
};
