import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import _ from "lodash";

import { Box, IconButton, Drawer, Divider, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Skeleton from "@mui/material/Skeleton";

import "../Css/Preview.css";
import "./TypeObjectPreview.css";
import ParagraphLoader from "../../../Loaders/ParagraphLoader";
import ErrorBoundary from "../../../ErrorBoundry";
import InnerLayout from "./InnerLayout";

import { fetchPreviewCategory } from "../../actions/layoutActions";
import { typewithSelectedAttributes } from "../../actions/typeRefactoredActions";
import { fetchTitleSettingsAttribute } from "../../actions/typeSettingsActions";
import { fetchExternalTypeInstanceData } from "../../actions/integrationApiActions";

const useStyles = makeStyles()((theme) => ({
  drawer: {
    width: "100%",
    flexShrink: 0,
    marginBottom: "0px",
  },
  drawerOpen: {
    width: "100%",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    top: -15,
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  toolbarSplitter: {
    display: "flex",
    marginRight: 0,
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

const TypeObjectExternalPreview = (props) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [fetchedLayout, setFetchedLayout] = useState({});
  const [fetchedLayoutFlag, setFetchedLayoutFlag] = useState(false);
  const [loader, setLoader] = useState(true);
  const [attributes, setAttributes] = useState([]);
  const [titleSettings, setTitleSettings] = useState([]);
  const [title, setTitle] = useState("");
  const [dataItem, setDataItem] = useState({});
  const [relationData, setRelationData] = useState([]);
  const [relationDataToSave, setRelationDataToSave] = useState({});
  const [titleLoader, setTitleLoader] = useState(true);
  const [showErrormessage, setshowErrormessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const leftDrawerStatus = useSelector((state) => {
    return state.userDrawer.status.show;
  });

  useEffect(() => {
    if (
      props.objectId !== undefined &&
      props.objectId !== null &&
      props.objectId &&
      props.objectId !== ""
    ) {
      setDataItem({});
      setErrorMessage("");
      setshowErrormessage(false);
      setLoader(true);
      setFetchedLayoutFlag(false);
      setTitle("");
      setTitleLoader(false);
    } else {
      props.setVisibleSidebar(false);
    }
  }, [props.objectId]);

  useEffect(() => {
    if (props.typeId != "") {
      let formattedData = {
        containerObjectId: parseInt(props.containerInstanceId),
        containerId: parseInt(props.containerId),
        typeId: parseInt(props.typeId),
        typeAttributes: [],
      };
      dispatch(typewithSelectedAttributes(formattedData)).then((res) => {
        if (res.data) {
          if (res.data.attributes) {
            let attr = res.data.attributes;
            setAttributes(attr);
          }
        }
      });
      dispatch(fetchTitleSettingsAttribute(props.typeId)).then((res) => {
        if (
          res.status !== undefined &&
          res.status !== null &&
          res.status &&
          res.status === 200
        ) {
          if (res.data !== undefined && res.data !== null && res.data) {
            setTitleSettings(res.data);
          } else {
            props.setMsg("Some error occur");
            props.setSeverity("error");
            props.setAlert(true);
          }
        } else if (
          res.status !== undefined &&
          res.status !== null &&
          res.status &&
          res.status === 409 &&
          res.data !== undefined &&
          res.data !== null &&
          res.data &&
          res.data === "Title settings attribute is not found"
        ) {
          setTitle("");
          setTitleLoader(false);
        } else {
          // props.setMsg(`${res.data}`);
          // props.setSeverity("error");
          // props.setAlert(true);
          setTitle("");
          setTitleLoader(false);
        }
      });
    }
  }, [props.typeId]);

  useEffect(() => {
    if (
      props.typeId !== "" &&
      props.objectId !== "" &&
      props.containerId !== "" &&
      props.containerInstanceId !== ""
    ) {
      const dataTosend = {
        typeId: parseInt(props.typeId),
        containerId: parseInt(props.containerId),
        containerInstanceId: parseInt(props.containerInstanceId),
        objectId: props.objectId.toString(),
      };

      dispatch(fetchExternalTypeInstanceData(dataTosend)).then((res) => {
        if (res !== undefined && res !== null && res) {
          if (
            res.status !== undefined &&
            res.status !== null &&
            res.status &&
            res.status === 200
          ) {
            if (res.data !== undefined && res.data !== null && res.data) {
              setDataItem(res.data[0]);
            } else {
              setDataItem({});
              setErrorMessage(
                `Some error occured while fetching the, External type object instance data`
              );
              setshowErrormessage(true);
            }
          } else {
            setDataItem({});
            setErrorMessage(`${res.data}`);
            setshowErrormessage(true);
          }
        } else {
          setDataItem({});
          setErrorMessage(
            `Some error occured while fetching the, External type object instance data`
          );
          setshowErrormessage(true);
        }
      });
    }
  }, [
    props.typeId,
    props.containerInstanceId,
    props.containerId,
    props.objectId,
  ]);

  useEffect(() => {
    if (
      (dataItem.id || errorMessage != "") &&
      attributes.length > 0 &&
      fetchedLayoutFlag
    ) {
      setLoader(false);
    }
  }, [dataItem, attributes, fetchedLayoutFlag]);
  /*------------------------call this fn when you have to show same msg in snckbar----------------*/
  const commonError = (place) => {
    if (place) {
      props.setMsg("Some error occur " + place);
    } else {
      props.setMsg("Some error occur");
    }
    props.setSeverity("error");
    props.setAlert(true);
  };
  /*----------------------------------------------------------------------*/
  /*-------------------------Set relation data----------------------------------*/
  // useEffect(() => {
  //   if (props.typeId !== undefined && props.typeId !== null && props.typeId) {
  //     dispatch(fetchExternalRelationshipByTypeId(props.typeId)).then((obj) => {
  //       if (obj.status === 200) {
  //         if (
  //           obj.data !== undefined &&
  //           obj.data !== null &&
  //           obj.data &&
  //           obj.data.length > 0
  //         ) {
  //           setRelationData(obj.data);
  //         } else {
  //           setNoMappedRlationship(true);
  //         }
  //       } else {
  //         // props.setSeverity("error");
  //         // props.setAlert(true);
  //         // props.setMsg(`${obj.data}`);
  //       }
  //     });
  //   }
  // }, [props.typeId]);
  /*-------------------------Set relation data----------------------------------*/

  const closePreview = () => {
    props.setVisibleSidebar(false);
  };
  /*------------------------use effect for initial loading----------------*/
  useEffect(() => {
    if (props.typeId !== "" && props.objectId !== "") {
      dispatch(fetchPreviewCategory(props.typeId))
        .then((obj) => {
          if (obj.status == "200" && obj.data && obj.data.id) {
            setFetchedLayout(obj.data);
          } else {
            setFetchedLayout(obj.data);
          }
          setFetchedLayoutFlag(true);
        })
        .catch((err) => {
          commonError("during fetching layout category");
        });
    }
  }, [props.objectId, props.typeId]);
  /*----------------------------------------------------------------------*/
  /*-------------------------Set relation data----------------------------------*/
  // useEffect(() => {
  //   if (dataItem && dataItem.relations) {
  //     setRelationData(dataItem.relations);
  //   }
  // }, [dataItem]);
  /*-------------------------Set relation data----------------------------------*/
  /*--------------------Fetch title-------------------------------*/
  useEffect(() => {
    if (
      dataItem !== undefined &&
      dataItem !== null &&
      dataItem &&
      titleSettings !== undefined &&
      titleSettings !== null &&
      titleSettings &&
      titleSettings.internalName !== undefined &&
      titleSettings.internalName !== null &&
      titleSettings.internalName
    ) {
      if (
        dataItem[titleSettings.internalName] !== undefined &&
        dataItem[titleSettings.internalName] !== null &&
        dataItem[titleSettings.internalName]
      ) {
        setTitle(dataItem[titleSettings.internalName]);
        setTitleLoader(false);
      } else {
        setTitle("");
        setTitleLoader(false);
      }
    }
  }, [dataItem, titleSettings]);
  /*--------------------Fetch title-------------------------------*/
  const content = (
    <ErrorBoundary>
      <Box
        style={{
          display: "flex",
          justifyContent:
            props.SplitterPreview !== undefined &&
            props.SplitterPreview !== null &&
            props.SplitterPreview
              ? null
              : "space-between",
          alignItems: "center",
          padding: "0px 10px 0px 16px",
          marginTop:
            props.SplitterPreview !== undefined &&
            props.SplitterPreview !== null &&
            props.SplitterPreview
              ? 60
              : null,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            height: 50,
          }}
          alignContent="center"
        >
          {props.width <= 786 ? (
            <IconButton
              aria-label="back"
              style={{ marginLeft: -16 }}
              color="primary"
              size="large"
              onClick={(e) => props.setVisibleSidebar(false)}
            >
              <ArrowBackIcon />
            </IconButton>
          ) : (
            ""
          )}

          {!titleLoader ? (
            title ? (
              <ErrorBoundary>
                <div
                  className="previewHeading"
                  title={title}
                  style={{
                    width:
                      props.SplitterPreview !== undefined &&
                      props.SplitterPreview !== null &&
                      props.SplitterPreview
                        ? leftDrawerStatus
                          ? props.width / 4.5 - 50
                          : props.width / 4.5
                        : props.component !== undefined &&
                          props.component === "externalTypeView"
                        ? ""
                        : leftDrawerStatus
                        ? props.width / 4 - 50
                        : props.width / 4,
                  }}
                >
                  {title !== undefined && title ? title : ""}
                </div>
              </ErrorBoundary>
            ) : (
              ""
            )
          ) : (
            <div style={{ width: 100 }}>
              {" "}
              <Skeleton />
            </div>
          )}
        </Box>
        <div
          className={
            props.SplitterPreview !== undefined &&
            props.SplitterPreview !== null &&
            props.SplitterPreview
              ? classes.toolbarSplitter
              : classes.toolbar
          }
          style={{
            marginLeft:
              props.SplitterPreview !== undefined &&
              props.SplitterPreview !== null &&
              props.SplitterPreview
                ? "2%"
                : null,
            position:
              props.SplitterPreview !== undefined &&
              props.SplitterPreview !== null &&
              props.SplitterPreview
                ? "fixed"
                : null,
            right:
              props.SplitterPreview !== undefined &&
              props.SplitterPreview !== null &&
              props.SplitterPreview
                ? 0
                : null,
          }}
        >
          <IconButton
            style={{ display: props.width <= 786 ? "none" : "" }}
            onClick={closePreview}
            size="large"
          >
            {theme.direction === "rtl" ? <CloseIcon /> : <CloseIcon />}
          </IconButton>
        </div>
      </Box>
      <Divider />
      <div
        id="mySidenav"
        className="mySidenav"
        style={{
          marginTop: 10,
          marginLeft:
            props.SplitterPreview !== undefined &&
            props.SplitterPreview !== null &&
            props.SplitterPreview
              ? null
              : 0,
          marginBottom:
            props.SplitterPreview !== undefined &&
            props.SplitterPreview !== null &&
            props.SplitterPreview
              ? null
              : "15px",
          overflowX: "hidden",
          overflowY: "auto",
          textAlign: "justify",
          height:
            props.SplitterPreview !== undefined &&
            props.SplitterPreview !== null &&
            props.SplitterPreview
              ? "calc(100vh - 120px)"
              : null,
          position:
            props.SplitterPreview !== undefined &&
            props.SplitterPreview !== null &&
            props.SplitterPreview
              ? "relative"
              : null,
          marginRight:
            props.SplitterPreview !== undefined &&
            props.SplitterPreview !== null &&
            props.SplitterPreview
              ? leftDrawerStatus
                ? 340
                : props.rightMargin !== undefined
                ? props.rightMargin
                : 89
              : null,
        }}
      >
        {loader ? (
          <ParagraphLoader></ParagraphLoader>
        ) : (
          <div style={{ marginRight: 35 }}>
            <InnerLayout
              fetchedLayout={fetchedLayout}
              layoutType={props.layoutType}
              attributes={attributes}
              data={dataItem}
              objectId={props.objectId}
              typeId={props.typeId}
              renderRTE={props.renderRTE ? props.renderRTE : null}
              setRenderRTE={props.setRenderRTE ? props.setRenderRTE : null}
              showErrormessage={showErrormessage}
              errorMessage={errorMessage}
              containerId={props.containerId}
              containerInstanceId={props.containerInstanceId}
              setMsg={props.setMsg}
              setSeverity={props.setSeverity}
              setAlert={props.setAlert}
              integrationProjectName={props.integrationProjectName}
              setRelationData={setRelationData}
              relationData={relationData}
              relationDataToSave={relationDataToSave}
              setRelationDataToSave={setRelationDataToSave}
            />
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
  return props.SplitterPreview !== undefined &&
    props.SplitterPreview !== null &&
    props.SplitterPreview ? (
    <ErrorBoundary>{content}</ErrorBoundary>
  ) : (
    <Drawer
      variant="permanent"
      anchor="right"
      id={
        props.width <= 786
          ? "preview-drawer-mobile"
          : props.component !== undefined &&
            props.component === "externalTypeView"
          ? "preview-drawer-externalTypeView"
          : "preview-drawer"
      }
      className={cx(classes.drawer, {
        [classes.drawerOpen]: props.visibleSidebar,
        [classes.drawerClose]: !props.visibleSidebar,
      })}
      classes={{
        paper: cx({
          [classes.drawerOpen]: props.visibleSidebar,
          [classes.drawerClose]: !props.visibleSidebar,
        }),
      }}
    >
      {content}
    </Drawer>
  );
};

export default TypeObjectExternalPreview;
