export default (
    state = { 
        roleId: [],
    },   
    action
) => {
    switch (action.type) {
        case "ROLEID": 
             return { ...state, roleId: action.response };
        default:
             return state;   
    }
};