import axios from "axios";
const CancelToken = axios.CancelToken;

export const configHeader = () => {
  return {
    headers: {
      "content-type": "application/json",
      Authorization: localStorage.getItem("token")
        ? "Bearer " + localStorage.getItem("token")
        : "",
    },
  };
};

export const configHeaderMultipart = () => {
  return {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: localStorage.getItem("token")
        ? "Bearer " + localStorage.getItem("token")
        : "",
    },
  };
};

export const configHeaderwithData = (data) => {
  return {
    headers: {
      "content-type": "application/json",
      Authorization: localStorage.getItem("token")
        ? "Bearer " + localStorage.getItem("token")
        : "",
    },
    data,
  };
};

export const configHeaderwithId = (id) => {
  return {
    headers: {
      "content-type": "application/json",
      Authorization: localStorage.getItem("token")
        ? "Bearer " + localStorage.getItem("token")
        : "",
    },
    data: id,
  };
};

export const configHeaderwithCancel = (controllerAbort) => {
  return {
    headers: {
      "content-type": "application/json",
      Authorization: localStorage.getItem("token")
        ? "Bearer " + localStorage.getItem("token")
        : "",
    },
    signal: controllerAbort.current.signal,
  };
};

export const configHeaderWithoutContentType = () => {
  return {
    headers: {
      Authorization: localStorage.getItem("token")
        ? "Bearer " + localStorage.getItem("token")
        : "",
    },
  };
};

export const configHeaderWithoutAuthorisation = (id) => {
  return {
    headers: {
      "content-type": "application/json",
    },
  };
};
