import serviceApi from "../apis/serviceApi";

export const getversion = () => async (dispatch) => {
  try {
    const response = await serviceApi.get("/version");
    dispatch({ type: "FETCH_VERSION", response: response });
    return response;
  } catch (e) {
    return e.response;
  }
};
