import i18n from "i18next";
import HttpApi  from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
const url = process.env.REACT_APP_URL

i18n
.use(HttpApi)
.use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    supportedLngs: ['en', 'de'],
    fallbackLng: 'en',
    debug: false,
    // Options for language detector
    detection: {
      order: ['cookie','htmlTag','path'], 
      caches: ['cookie'],
    },
    // react: { useSuspense: false },
    backend: {
          loadPath:  url+"/api/language/allrecords"
    },
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

  export default i18n;