import React from "react";
import { useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import AvatarGroup from "@mui/material/AvatarGroup";

const useStyles = makeStyles()((theme) => ({
  root: {
    "& .MuiAvatarGroup-avatar": {
      border: 0,
      width: theme.spacing(3),
      height: theme.spacing(3),
      color: "#180f0f",
      fontSize: 11,
      backgroundColor: "#e2e2e2"
    },
  },

  avatarImg: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    // top: 10
  },
  avatarImgBacklog: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    top: 10,
  },
  avatarVal: {
    top: "-3px",
    position: "relative",
  },
}));

const AvatarUsers = ({ empUsers, val, id, comp }) => {
  const { classes } = useStyles();
  const users = useSelector(
    (state) => state.usersInfo.allUserAccordinToAppId.data
  );

  return (
    <>
      {val !== undefined && val && val != "" ? (
        <div key={id} style={{ display: "flex", alignItems: "center" }}>
          <AvatarGroup max={2} total={empUsers !== undefined && empUsers ? empUsers.length : 0} className={classes.root}>

              {empUsers.length > 0
              ? empUsers.map((res) => {
                  let initials = res.nameInitials;
                  if (res.id !== undefined && res.id !== null && res.id) {                   
                    if (
                      res.profilePhotoUrl !== undefined &&
                      res.profilePhotoUrl !== null &&
                      res.profilePhotoUrl &&
                      res.profilePhotoUrl !== "Not found"
                    
                    ) {
                      return (
                        <Avatar
                          key={res.name}
                          className={
                            (comp !== undefined) & (comp == "Backlog")
                              ? classes.avatarImgBacklog
                              : classes.avatarImg
                          }
                          src={res.profilePhotoUrl}
                        ></Avatar>
                      );
                    } else {
                      return (
                        <Avatar
                          key={res.name}
                          style={{
                            backgroundColor:
                              res.color !== undefined && res.color
                                ? res.color
                                : "grey",
                            color: "#6b6b6b",
                            fontSize: 11,
                            border: 0,
                          }}
                          className={
                            (comp !== undefined) & (comp == "Backlog")
                              ? classes.avatarImgBacklog
                              : classes.avatarImg
                          }
                        >
                          {initials !== undefined && initials
                            ? initials.toUpperCase()
                            : res.name.charAt(0)}
                        </Avatar>
                      );
                    }
                  }
            
                })
              : null}

          </AvatarGroup>

          <span
            className={classes.avatarVal}
            style={{
              left:
                empUsers.length !== 1 
                  ? 5
                  : 20,
            }}
          >

            {empUsers !== undefined && empUsers && empUsers.length !== 0 && empUsers
                    ?.map((user) => user?.name)
                    .join(" , ")}
          </span>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default AvatarUsers;
