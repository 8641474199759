import usersApi from "../apis/usersApi";
import ApiUrl from "../apis/ApiUrl";
import { configHeader } from "./header-configure";
import { redirect } from "./redirect";

export const fetchAllUsers = () => async (dispatch) => {
  try {
    const response = await ApiUrl.get("/users", configHeader());
    dispatch({ type: "FETCH_ALL_USERS", response: response });
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

//all users data excluding group and roles
export const fetchAllUsersBasicData = () => async (dispatch) => {
  try {
    const response = await ApiUrl.get("/users/grid", configHeader());
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const updateUser = (data) => async (dispatch) => {
  try {
    const response = await usersApi.put("", data, configHeader());
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const deleteUser = (id) => async (dispatch) => {
  // this API no longer exists
  try {
    const response = await ApiUrl.delete(`/users?id=${id}`, configHeader());
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const enableOrDisableUser = (data) => async (dispatch) => {
  try {
    const response = await ApiUrl.put(
      "/users/enable",
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

//fetch user by id (including groups and roles)
export const fetchUserById = (id) => async (dispatch) => {
  try {
    const response = await ApiUrl.delete(`/users?id=${id}`, configHeader());
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const showCreateUserDialog = (value) => async (dispatch) => {
  dispatch({ type: "SHOW_CREATE_USER_DIALOG", response: value });
};

export const fetchAllUserswithImageByteArray = () => async (dispatch) => {
  try {
    const response = await usersApi.get("/userprofiledata", configHeader());
    dispatch({
      type: "FETCH_ALL_USERS_WITH_IMAGE_BYTEARRAY",
      response: response,
    });
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const fetchAllUsersAccToAppId = (appId) => async (dispatch) => {
  try {
    const response = await usersApi.get(`/app?appid=${appId}`, configHeader());
    dispatch({
      type: "FETCH_ALL_USERS_ACCORDING_TO_APPID",
      response: response,
    });
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

//To check user is super admin or not
export const toCheckLoggedInUserIsSuperAdminOrNot = () => async (dispatch) => {
  try {
    const response = await usersApi.get(`/check/superadmin`, configHeader());
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const fetchUserColorInitials = (id) => async (dispatch) => {
  try {
    const response = await usersApi.get(
      `/intials/color?userId=${id}`,
      configHeader()
    );
    if (!response.data) {
      response.data = {};
    }
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const fetchUserDetailsWithGroups = (id) => async (dispatch) => {
  try {
    const response = await usersApi.get(
      `/${id}?id=${id}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

//To fetch user list according to container InstanceId
export const fetchContainerInstanceMembers =
  (containerId, containerInstanceId) => async (dispatch) => {
    try {
      const response = await usersApi.get(
        `/containerInstanceMembers?containerId=${containerId}&containerInstanceId=${containerInstanceId}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

  //To check number of logged in users

export const toGetNumberOfLoggedInUsers = () => async (dispatch) => {
  try {
    const response = await usersApi.get(`/loggedin/users/count`, configHeader());
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};
