export default (
    state = {
        type: { attributes: [], relationships: [], roles: [] },
        dataType: [],
        types: [],
        containerTypes: [],
        allowedTypes: [],
        allowedTypesforTypesandTeams : [],
    },
    action
) => {
    switch (action.type) {
        case "FETCH_DATA_TYPE": {
            return { ...state, dataType: action.response };
        }
        case "FETCH_TYPE":
            return { ...state, type: action.response };
        case "FETCH_TYPES":
            return { ...state, types: action.response };
        case "FETCH_CONTAINER_TYPE_ROLES":
            return { ...state, roles: action.response };
        case "FETCH_CONTAINER_TYPES":
            return { ...state, containerTypes: action.response };
        case "SAVE_TYPE":
            return { ...state, types: [...state.types, action.response] };
        case "SAVE_ATTRIBUTE":
            return {
                ...state,
                type: {
                    ...state.type,
                    attributes: [...state.type.attributes, action.response],
                },
            };
        case "MODIFY_TYPE": {
            return {
                ...state,
                type: {
                    ...state.type,
                    name: action.response.data.name,
                    description: action.response.data.description,
                    groupsWithPermission:
                        action.response.data.groupsWithPermission,
                },
                types: state.types.map((type) =>
                    type.id === action.response.data.id
                        ? {
                            ...type,
                            name: action.response.data.name,
                            description: action.response.data.description,
                        }
                        : type
                ),
            };
        }
        case "SAVE_RELATION": {
            return {
                ...state,
                type: {
                    ...state.type,
                    relationships: [
                        ...state.type.relationships,
                        ...action.response,
                    ],
                },
            };
        }
        case "DELETE_RELATIONSHIPS": {
            return {
                ...state,
                type: {
                    ...state.type,
                    relationships: action.response.data,
                },
            };
        }
        case "DELETE_ATTRIBUTES": {
            return {
                ...state,
                type: {
                    ...state.type,
                    attributes: state.type.attributes.filter(
                        (elem) => !action.response.includes(elem.id)
                    ),
                },
            };
        }
        case "DEAFULT_COLUMNS": {
            return {
                ...state,
                type: {
                    ...state.type,
                    attributes: action.response,
                },
            };
        }
        case "SHOW_CREATE_TYPE_DIALOG":
            return { ...state, showCreateNewTypeDialog: action.response };

        case "SHOW_CREATE_ATTRIBUTE_DIALOG":
            return { ...state, showCreateNewAttributeDialog: action.response };

        case "SHOW_CREATE_RELATIONSHIP_DIALOG":
            return { ...state, showCreateNewRelationshipDialog: action.response };

        case "SHOW_CREATE_CONSTRAINT_DIALOG":
            return { ...state, showCreateNewConstraintDialog: action.response };

        case "SHOW_CREATE_LAYOUT_DIALOG":
            return { ...state, showCreateNewLayoutDialog: action.response };

        case "SHOW_CREATE_NEW_DIALOG":
            return { ...state, showCreateNewDialog: action.response };

        case "CLEAR_TYPES":
            return {
                ...state,
                types: [],
            };

         case "FETCH_ALLOWED_TYPES": 
            return { 
                ...state,
                 allowedTypes: action.response };
        case "FETCH_ALLOWED_TYPES_FOR_TYPES_AND_TEAMS" :
        return { 
            ...state,
            allowedTypesforTypesandTeams: action.response };
        default:
            return state;
    }
};
