export default (state = { currentUser: {} }, action) => {
    switch (action.type) {
        case "USER_PROFILE":
            return { ...state, currentUser: action.response };
        case "LOG_OUT":
            return {};
        default:
            return state;
    }
};
