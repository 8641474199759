import React from "react";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackBar = ({ alert, closeAlert, severity, keyword, action, msg }) => {
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={alert}
        onClose={closeAlert}
        autoHideDuration={6000}
      >
        <Alert severity={severity} onClose={closeAlert}>
          {!msg
            ? severity == "success"
              ? `${keyword} ${action} Succesful!`
              : severity == "error"
              ? `${keyword} ${action} Failed!`
              : severity == "info"
              ? `${keyword} !`
              : ""
            : severity == "success"
            ? `${msg}`
            : severity == "error"
            ? `${msg}`
            : `${msg}`}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnackBar;
