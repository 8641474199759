import React, { useEffect, useState } from "react";
import { Grid, Skeleton, IconButton } from "@mui/material";
import TextLoader from "../../../../Loaders/TextLoader";
import * as Icons from "@mui/icons-material";
import InputLabel from "../../Common/Components/InputLabel";
import "./Widget.css";

export default function PickListWidget(props) {
  const [attr, setAttr] = useState();
  const [loader, setLoader] = useState(true);
  const [objectLoader, setObjectLoader] = useState(true);
  const [dataItem, setDataItem] = useState("");
  const [dataItemFlag, setDataItemFlag] = useState();

  useEffect(() => {
    setLoader(true);
  }, []);

  useEffect(() => {
    if (props.attr) {
      setAttr(props.attr);
      setLoader(false);
    }
  }, [props.attr]);

  useEffect(() => {
    if (props.attr && props.attr.internalName) {
      if (props.facts[props.attr.internalName]) {
        let finalVal = props.facts[props.attr.internalName];
        let finalData = finalVal.map((val, index) => {
          let PreviewPickListIcon = val.icon ? Icons[val.icon] : "";
          return (
            <>
              {PreviewPickListIcon !== "" ? (
                <IconButton
                  key={index}
                  style={{ height: 10, color: val.color }}
                  size="large"
                >
                  <PreviewPickListIcon />
                </IconButton>
              ) : (
                " "
              )}
              <span>{val.name ? val.name : ""}</span>
            </>
          );
        });
        setDataItem(finalData);
        setDataItemFlag(true);
      } else {
        setObjectLoader(false);
      }
    }
  }, [props.facts]);
  useEffect(() => {
    if (dataItemFlag) {
      setObjectLoader(false);
    }
  }, [dataItemFlag]);

  return (
    <Grid item xs>
      {loader ? (
        <Skeleton variant="text" />
      ) : (
        <>
          <InputLabel attr={attr} objectType={props.objectType} />

          <div className="root">
            {objectLoader ? (
              <div className="loaderStyle parahSpace">
                <TextLoader />
              </div>
            ) : (
              <div
                className={
                  attr.enableTooltip ? "parahSpace-tooltip" : "parahSpace"
                }
              >
                {dataItem ? dataItem : ""}
              </div>
            )}
          </div>
        </>
      )}
    </Grid>
  );
}
