export default (
  state = {
    relationValue: { dataObjects: [], attributes: [] },
    relationValuebyTypeId: [],
    relationValuebyOrderDocumentView: [],
    relationValuebyObjectIdAndRelationshipId: [],
  },
  action
) => {
  switch (action.type) {
    case "RELATION_DATA":
      return { ...state, relationValue: action.response };
    case "RELATION_BY_TYPEID":
      return { ...state, relationValuebyTypeId: action.response };
    case "FETCH_RELATION_DOCUMENTVIEW":
      return { ...state, relationValuebyOrderDocumentView: action.response };
    case "FETCH_TYPEOBJECT_RELATION_VALUE_BY_OBJECTID_AND_RELATIONSIPID":
      return {
        ...state,
        relationValuebyObjectIdAndRelationshipId: action.response,
      };
    default:
      return state;
  }
};
