export default (state = { unit: [],selectedUnit: {},categories: [] }, action) => {
    switch (action.type) {
        case "FETCH_ALL_UNITS":
            return { ...state, unit: action.response };
        case "FETCH_ALL_UNIT_CATEGORIES":
            return { ...state, categories: action.response };
        case "FETCH_SELECTED_UNITS": {
            return {
                ...state, selectedUnit: action.response
            };
        }
        case "ADD_UNIT_RECORD":
            return { ...state, unit: [...state.unit, action.response] };
        
         case "UPDATE_UNIT_RECORD":
               return { ...state, unit: [...state.unit, action.response] };
         default:
            return state; 
    }
};