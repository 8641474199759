import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { SnackbarProvider, MaterialDesignContent } from "notistack";
/*------------------------Material imports starts--------------------------------------*/
import { Grid, Skeleton } from "@mui/material";
import { styled, width } from "@mui/system";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
/*------------------------Material imports ends--------------------------------------*/

/*-----------------------------------Other libraries starts--------------------------------------*/
import _ from "lodash";
/*-----------------------------------Other libraries ends--------------------------------------*/

/*---------------------------------common files starts-----------------------------------*/
import FormatExternalGridData from "../../../formatGridNData/FormatExternalGridData";
import { DynamicGrid } from "../../../formatGridNData/DynamicGrid";
import GridLoader from "../../../../Loaders/GridLoader";
import ToolbarRelationshipGeneralIntegration from "../../Common/Components/Toolbars/ToolbarRelationshipGeneralIntegration";
import TypeObjectDialogExternal from "../../Common/Components/Relationship/TypeObjectDialogExternal";
import { exists } from "../../Common/Components/FuncNComponents/CommonFunctions";
import ConfirmationDialogue from "../../../common/ConfirmationDialogue";
import LanguageTranslation from "../../../Language/LanguageTranslation";
import ErrorBoundary from "../../../../ErrorBoundry";
/*---------------------------------common files ends-----------------------------------*/
/*---------------------------------action files starts-----------------------------------*/
import { fetchRelationDataByID } from "../../../actions/relationshipsActions";
import { typewithRelationId } from "../../../actions/typeRefactoredActions";
import { fetchStateAttributeWithoutPickList } from "../../../actions/attributesActions";
import { fetchContainerInstanceMembers } from "../../../actions/usersActions";
/*---------------------------------action files ends-----------------------------------*/

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: "#2F7C31",
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: "#D32F2F",
  },
  "&.notistack-MuiContent-warning": {
    backgroundColor: "#ED6C03",
  },
  "&.notistack-MuiContent-info": {
    backgroundColor: "#0088D1",
  },
}));
export default function RelationshipWidget(props) {
  const dispatch = useDispatch();
  const myGrid = useRef([]);
  const [toolbarLoader, setToolbarLoader] = useState(true);
  const [relationshipId, setRelationshipId] = useState("");
  const [relationshipunique_name, setRelationshipunique_name] = useState("");
  const [relationshipunique_info, setRelationshipunique_info] = useState(false);
  const [relationshipunique_description, setRelationshipunique_description] =
    useState("");
  const [relationAttrFlag, setRelationAttrFlag] = useState(false);
  const [relnRef, setRelnRef] = useState({ relnId: "", type: "" });
  const [relationData, setRelationData] = useState([]);
  const [relationshipMapped, setRelationshipMapped] = useState(true);
  const [stateFieldVal, setStateFieldVal] = useState([]);
  const [typeId, setTypeId] = useState("");
  const [attributes, setAttributes] = useState([]);
  const [filteredattributes, setFilteredAttributes] = useState([]);
  const [body, setBody] = useState([]);
  const [relationshipRowsToShow, setRelationRowsToShow] = useState();
  const [loader, setLoader] = useState(true);
  const [groupByAttribute, setGroupByAttribute] = useState([]); 
  const [showAggregate, setShowAggregate] = useState(false);
  const [aggregateParameters, setAggregateParameters] = useState([
    { selectedAggregate: null, selectedOperation: null },
  ]);

  const [disableDelete, setDisableDelete] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [aggregatePosition, setAggregatePosition] = useState("Footer");
  const [isEdit, setIsEdit] = useState(false);
  const [typeName, setTypeName] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [relationshipunique_multivalued, setRelationshipunique_multivalued] =
    useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [relationDataSet, setRelationDataSet] = useState(false);
  const [diasbledAdding, setDiasbledAdding] = useState(false);
    //For checkbox icon
    const [showCheckBox, setShowCheckBox] = useState(
      props.layoutType === "View" ? false : true
    );
    const [showSelect, setShowSelect] = useState(
      props.layoutType === "View" ? true : false
    );

  /*------------------Getting relationship Id---------------------------*/
  useEffect(() => {
    if (
      props.widget &&
      Object.keys(props.widget).length > 0 &&
      props.widget.relationshipId
    ) {
      setTypeId("");
      setRelationAttrFlag(false);
      setLoader(true);
      setRelationshipId(props.widget.relationshipId);
      setToolbarLoader(true);
    }
  }, [props.widget]);
  /*------------------Getting relationship Id---------------------------*/
  useEffect(() => {
    if (
      selectedRows !== undefined &&
      selectedRows !== null &&
      selectedRows &&
      selectedRows[parseInt(relationshipId)] !== undefined &&
      selectedRows[parseInt(relationshipId)] !== null &&
      selectedRows[parseInt(relationshipId)] &&
      selectedRows[parseInt(relationshipId)].length !== 0
    ) {
      setDisableDelete(false);
    } else {
      setDisableDelete(true);
    }
  }, [selectedRows, relationshipId]);
  useEffect(() => {
    if (
      props.containerId !== undefined &&
      props.containerId !== null &&
      props.containerId &&
      props.containerInstanceId !== undefined &&
      props.containerInstanceId !== null &&
      props.containerInstanceId
    ) {
      dispatch(
        fetchContainerInstanceMembers(
          parseInt(props.containerId),
          parseInt(props.containerInstanceId)
        )
      ).then((res) => {
        if (res.status === 200) {
          setAllUsers(res.data);
        } else {
          setAllUsers([]);
        }
      });
    }
  }, [props.containerId, props.containerInstanceId]);
  /*------------------Getting relationship datasource---------------------------*/

  useEffect(() => {
    if (
      props.relationData &&
      props.relationshipId &&
      props.relnLoaderOff &&
      props.relnLoaderOff[props.relationshipId]
    ) {
      if (props.relationData[props.relationshipId]) {
        setRelationData(props.relationData[props.relationshipId]);
        setRelationDataSet(true);
      } else {
        setRelationData([]);
        setRelationDataSet(true);
      }
    }
  }, [props.relationData, props.relationshipId, props.relnLoaderOff]);

  /*------------------Getting relationship datasource---------------------------*/
  /*------------------Get relationship details like name n info---------------------------*/
  useEffect(() => {
    if (relationshipId !== "" && props.relationships.length > 0) {
      dispatch(fetchRelationDataByID(relationshipId))
        .then((obj) => {
          let relEdit = props.relationships.filter(
            (obj) => obj.id === relationshipId
          );
          if (relEdit.length > 0) {
            setIsEdit(relEdit[0].isEdit);
            setTypeName(relEdit[0].name);
            setRelationshipunique_multivalued(relEdit[0].multivalue);
            setToolbarLoader(false);
          }
          if (obj.status === 200 && obj.data) {
            if (obj.data && obj.data.name) {
              setRelationshipunique_name(obj.data.name);
            }
            if (obj.data && obj.data.relationshipInfo) {
              setRelationshipunique_info(obj.data.relationshipInfo);
            }
            if (obj.data && obj.data.description) {
              setRelationshipunique_description(obj.data.description);
            }
          }
        })
        .catch((err) => {
          setToolbarLoader(false);
        });
    }
  }, [relationshipId, props.relationships]);
  /*------------------Get relationship details like name n info---------------------------*/

  /*------------------Set attribute and typeId by relationship---------------------------*/
  useEffect(() => {
    if (
      relationshipId != "" &&
      props.containerInstanceId &&
      props.containerInstanceId != null &&
      props.containerInstanceId != "" &&
      props.containerId &&
      props.containerId != null &&
      props.containerId != ""
    ) {
      let properties = JSON.parse(props.widget.properties);
      setRelationRowsToShow(properties.rowsToShow);
      let defaultColumn =
        properties && properties.defaultColumn
          ? JSON.parse(properties.defaultColumn)
          : [];
      let formattedData = {
        containerObjectId: parseInt(props.containerInstanceId),
        containerId: parseInt(props.containerId),
        relationId: relationshipId ? parseInt(relationshipId) : "",
        typeAttributes: defaultColumn,
      };
      dispatch(typewithRelationId(formattedData)).then((datType) => {
        if (datType.data && datType.data.id) {
          setTypeId(datType.data.id);
          setRelationAttrFlag(true);
          if (datType.data.attributes) {
            setAttributes(datType.data.attributes);
          }
        }
      });
    }
  }, [relationshipId, props.containerInstanceId, props.containerId]);
  /*------------------Set attribute and typeId by relationship---------------------------*/

  /*------------------Get state value---------------------------*/
  useEffect(() => {
    if (typeId && typeId !== "") {
      dispatch(fetchStateAttributeWithoutPickList(typeId)).then((obj) => {
        if (obj.status === 200 && obj.data) {
          setStateFieldVal([obj.data]);
        }
      });
    }
  }, [typeId, attributes]);
  /*------------------Get state value---------------------------*/

  /*------------------Code to order attribute in grid and format grid data---------------------------*/
  useEffect(() => {
    if (attributes && attributes.length > 0) {
      let attrData = [];
      if (props.widget && props.widget.properties) {
        let widgetProperties = JSON.parse(props.widget.properties);
        if (
          widgetProperties.defaultColumn &&
          widgetProperties.defaultColumn !== ""
        ) {
          let col = JSON.parse(widgetProperties.defaultColumn);
          if (attributes) {
            let filAttr = [];
            col.forEach((relV) => {
              let data = exists(attributes, relV);
              if (data && data.length && data.length > 0) {
                filAttr.push(data[0]);
              }
            });
            attrData = filAttr;
          }
        } else {
          let removedAttr = attributes
            .filter(
              (obj) =>
                obj.internalName === "CREATED_ON" ||
                obj.internalName === "MODIFIED_ON" ||
                obj.internalName === "CREATED_BY" ||
                obj.internalName === "MODIFIED_BY"
            )
            .map((data) => data);

          let filAttr = attributes
            .filter(
              (obj) =>
                obj.internalName !== "CREATED_ON" &&
                obj.internalName !== "MODIFIED_ON" &&
                obj.internalName !== "CREATED_BY" &&
                obj.internalName !== "MODIFIED_BY" &&
                obj.dataType.key !== "WORKLOG" &&
                obj.dataType.key !== "COMMENTS"
            )
            .map((data) => data);

          attrData = filAttr.concat(removedAttr);
        }
        if (
          widgetProperties.defaultColumnGrouping !== undefined &&
          widgetProperties.defaultColumnGrouping !== null &&
          widgetProperties.defaultColumnGrouping &&
          JSON.parse(widgetProperties.defaultColumnGrouping) !== undefined &&
          JSON.parse(widgetProperties.defaultColumnGrouping) !== null &&
          JSON.parse(widgetProperties.defaultColumnGrouping) &&
          JSON.parse(widgetProperties.defaultColumnGrouping)
            .selectedGroupByAttribute !== undefined &&
          JSON.parse(widgetProperties.defaultColumnGrouping)
            .selectedGroupByAttribute !== null &&
          JSON.parse(widgetProperties.defaultColumnGrouping)
            .selectedGroupByAttribute &&
          JSON.parse(widgetProperties.defaultColumnGrouping)
            .selectedGroupByAttribute.length > 0
        ) {
          //setting dynamic columns according to the setting
          let columns = [];
          const columnvalue = JSON.parse(
            widgetProperties.defaultColumnGrouping
          ).selectedGroupByAttribute;
          if (
            columnvalue !== undefined &&
            columnvalue !== null &&
            columnvalue &&
            columnvalue.length > 0
          ) {
            for (let i = 0; i < columnvalue.length; i++) {
              for (let j = 0; j < attributes.length; j++) {
                if (columnvalue[i].id === attributes[j].id) {
                  columns.push(attributes[j]);
                }
              }
            }
            setGroupByAttribute(columns);
          }
        } else {
          setGroupByAttribute([]);
        }

        if (
          widgetProperties.defaultColumnAggregate !== undefined &&
          widgetProperties.defaultColumnAggregate !== null &&
          widgetProperties.defaultColumnAggregate &&
          JSON.parse(widgetProperties.defaultColumnAggregate) !== undefined &&
          JSON.parse(widgetProperties.defaultColumnAggregate) !== null &&
          JSON.parse(widgetProperties.defaultColumnAggregate) &&
          JSON.parse(widgetProperties.defaultColumnAggregate).showAggregate !==
            undefined &&
          JSON.parse(widgetProperties.defaultColumnAggregate).showAggregate !==
            null &&
          JSON.parse(widgetProperties.defaultColumnAggregate).showAggregate ===
            true
        ) {
          setShowAggregate(true);
          if (
            JSON.parse(widgetProperties.defaultColumnAggregate)
              .aggregateParameters !== undefined &&
            JSON.parse(widgetProperties.defaultColumnAggregate)
              .aggregateParameters !== null &&
            JSON.parse(widgetProperties.defaultColumnAggregate)
              .aggregateParameters &&
            JSON.parse(widgetProperties.defaultColumnAggregate)
              .aggregateParameters.length > 0
          ) {
            const available = attributes.filter((val) => {
              return JSON.parse(
                widgetProperties.defaultColumnAggregate
              ).aggregateParameters.some((f) => {
                if (
                  f.selectedAggregate !== undefined &&
                  f.selectedAggregate !== null &&
                  f.selectedAggregate &&
                  f.selectedAggregate.id !== undefined &&
                  f.selectedAggregate.id !== null &&
                  f.selectedAggregate.id &&
                  f.selectedOperation !== undefined &&
                  f.selectedOperation !== null
                ) {
                  return val.id === f.selectedAggregate.id;
                }
              });
            });
            if (available.length === 0) {
              setAggregateParameters([
                { selectedAggregate: null, selectedOperation: null },
              ]);
              setShowAggregate(false);
            } else {
              const availableAggregateParameters = JSON.parse(
                widgetProperties.defaultColumnAggregate
              ).aggregateParameters.filter((f) => {
                return available.some((val) => {
                  if (
                    f.selectedAggregate !== undefined &&
                    f.selectedAggregate !== null &&
                    f.selectedAggregate &&
                    f.selectedAggregate.id !== undefined &&
                    f.selectedAggregate.id !== null &&
                    f.selectedAggregate.id &&
                    f.selectedOperation !== undefined &&
                    f.selectedOperation !== null
                  ) {
                    if (val.id === f.selectedAggregate.id) {
                      f.selectedAggregate = val;
                      return f;
                    }
                  }
                });
              });
              setAggregateParameters(availableAggregateParameters);
            }
          } else {
            setAggregateParameters([
              { selectedAggregate: null, selectedOperation: null },
            ]);
          }
        } else {
          setShowAggregate(false);
        }

        if (
          widgetProperties.defaultColumnAggregate !== undefined &&
          widgetProperties.defaultColumnAggregate !== null &&
          widgetProperties.defaultColumnAggregate &&
          JSON.parse(widgetProperties.defaultColumnAggregate) !== undefined &&
          JSON.parse(widgetProperties.defaultColumnAggregate) !== null &&
          JSON.parse(widgetProperties.defaultColumnAggregate) &&
          JSON.parse(widgetProperties.defaultColumnAggregate).showAggregate !==
            undefined &&
          JSON.parse(widgetProperties.defaultColumnAggregate).showAggregate !==
            null &&
          JSON.parse(widgetProperties.defaultColumnAggregate).showAggregate ===
            true
        ) {
          if (
            widgetProperties.defaultColumnGrouping !== undefined &&
            widgetProperties.defaultColumnGrouping !== null &&
            widgetProperties.defaultColumnGrouping &&
            JSON.parse(widgetProperties.defaultColumnGrouping) !== undefined &&
            JSON.parse(widgetProperties.defaultColumnGrouping) !== null &&
            JSON.parse(widgetProperties.defaultColumnGrouping) &&
            JSON.parse(widgetProperties.defaultColumnGrouping)
              .selectedAggregatePosition !== undefined &&
            JSON.parse(widgetProperties.defaultColumnGrouping)
              .selectedAggregatePosition !== null &&
            JSON.parse(widgetProperties.defaultColumnGrouping)
              .selectedAggregatePosition &&
            JSON.parse(widgetProperties.defaultColumnGrouping)
              .selectedGroupByAttribute !== undefined &&
            JSON.parse(widgetProperties.defaultColumnGrouping)
              .selectedGroupByAttribute !== null &&
            JSON.parse(widgetProperties.defaultColumnGrouping)
              .selectedGroupByAttribute &&
            JSON.parse(widgetProperties.defaultColumnGrouping)
              .selectedGroupByAttribute.length > 0
          ) {
            setAggregatePosition(
              JSON.parse(widgetProperties.defaultColumnGrouping)
                .selectedAggregatePosition
            );
          } else {
            setAggregatePosition("Footer");
          }
        } else {
          setAggregatePosition("Footer");
        }
      } else {
        let removedAttr = attributes
          .filter(
            (obj) =>
              obj.internalName === "CREATED_ON" ||
              obj.internalName === "MODIFIED_ON" ||
              obj.internalName === "CREATED_BY" ||
              obj.internalName === "MODIFIED_BY"
          )
          .map((data) => data);

        let filAttr = attributes
          .filter(
            (obj) =>
              obj.internalName !== "CREATED_ON" &&
              obj.internalName !== "MODIFIED_ON" &&
              obj.internalName !== "CREATED_BY" &&
              obj.internalName !== "MODIFIED_BY" &&
              obj.dataType.key !== "WORKLOG" &&
              obj.dataType.key !== "COMMENTS"
          )
          .map((data) => data);

        attrData = filAttr.concat(removedAttr);
      }
      setFilteredAttributes(attrData);
    }
  }, [attributes]);

  /*------------------Code to order attribute in grid and format grid data---------------------------*/
  /*------------------Code to format grid data---------------------------*/
  useEffect(() => {
    if (
      filteredattributes !== undefined &&
      filteredattributes !== null &&
      filteredattributes &&
      filteredattributes.length > 0 &&
      relationDataSet
    ) {
      let value = FormatExternalGridData(relationData, filteredattributes);
      setBody(value);
      setLoader(false);
      setRelationDataSet(false);
    }
  }, [filteredattributes, relationDataSet]);

  /*------------------Code to format grid data---------------------------*/
  /*------------------Attachment or adding disabled or not---------------------------*/
  useEffect(() => {
    if (relationshipunique_multivalued === false && relationData.length >= 1) {
      setDiasbledAdding(true);
    } else {
      setDiasbledAdding(false);
    }
  }, [relationshipunique_multivalued, relationData]);
  /*------------------Attachment or adding disabled or not---------------------------*/
  /*--------------------For choosing column and excel export in the grid starts here----------------------*/
  const columnChooserclick = () => {
    setRelnRef({ ...relnRef, relnId: relationshipId, type: "columnChooser" });
  };

  const excelExport = () => {
    setRelnRef({ ...relnRef, relnId: relationshipId, type: "excelExport" });
  };
  const searchChange = (val) => {
    setRelnRef({
      ...relnRef,
      relnId: relationshipId,
      type: "search",
      val: val,
    });
  };
  /*--------------------For choosing column and excel export in the grid Ends here----------------------*/

  const attacedLink = () => {
    setShowModal(true);
  };
  const deattacedLink = () => {
    setDeleteConfirmation(true);
  };

  const handleAttachData = (data) => {
    setShowModal(false);
    if (props.relationData[props.relationshipId]) {
      let existingData = props.relationData[relationshipId];
      props.setRelationDataToSave({
        ...props.relationData,
        [props.relationshipId]: existingData.concat(data),
      });
      if (props.setChangedAttribute) {
        props.setChangedAttribute(true);
      }
    } else {
      props.setRelationDataToSave({
        ...props.relationData,
        [props.relationshipId]: data,
      });
      if (props.setChangedAttribute) {
        props.setChangedAttribute(true);
      }
    }
  };

  const toggleSelect = (args) => {
    if (
      args.name &&
      args.name === "rowSelected" &&
      (args.target.className === "e-frame e-icons e-check" ||
        args.target.className === "e-frame e-icons e-uncheck" ||
        args.target.className === "e-frame e-icons e-stop" ||
        args.target.className === "e-checkbox-wrapper e-css" ||
        args.target.className ===
          "e-rowcell e-gridchkbox e-selectionbackground e-active")
    ) {
      if (relationshipId !== undefined) {
        if (Array.isArray(args.data)) {
          setSelectedRows((preValue) => {
            return {
              ...preValue,
              [parseInt(relationshipId)]: args.data,
            };
          });
        } else {
          if (
            selectedRows !== undefined &&
            selectedRows !== null &&
            selectedRows &&
            selectedRows[parseInt(relationshipId)] !== undefined &&
            selectedRows[parseInt(relationshipId)] !== null &&
            selectedRows[parseInt(relationshipId)]
          ) {
            setSelectedRows((preValue) => {
              return {
                ...preValue,
                [parseInt(relationshipId)]: [
                  ...selectedRows[parseInt(relationshipId)],
                  args.data,
                ],
              };
            });
          } else {
            setSelectedRows((preValue) => {
              return {
                ...preValue,
                [parseInt(relationshipId)]: [args.data],
              };
            });
          }

          // if (
          //   args.rowIndexes !== undefined &&
          //   args.rowIndexes !== null &&
          //   args.rowIndexes &&
          //   args.rowIndexes.length === 1
          // ) {
          //   if(props.setOpenDisplayLayoutPop !== undefined && props.setDisplayLayoutObjectId !== undefined){
          //   props.setOpenDisplayLayoutPop(true);
          //   props.setDisplayLayoutObjectId(args?.data?.id);
          //   }

          // } else {

          //   if(props.setOpenDisplayLayoutPop !== undefined){
          //     props.setOpenDisplayLayoutPop(false);
          //   }
          // }
        }
      }
    } else if (args.name === "rowDeselected") {
      if (Array.isArray(args.data)) {
        setSelectedRows((preValue) => {
          return {
            ...preValue,
            [parseInt(relationshipId)]: [],
          };
        });
      } else {
        if (
          selectedRows[parseInt(relationshipId)] !== undefined &&
          selectedRows[parseInt(relationshipId)] !== null &&
          selectedRows[parseInt(relationshipId)] &&
          selectedRows[parseInt(relationshipId)].length > 0
        ) {
          setSelectedRows((preValue) => {
            return {
              ...preValue,
              [parseInt(relationshipId)]: [
                ...selectedRows[parseInt(relationshipId)].filter(
                  (row) => parseInt(args?.data?.id) !== parseInt(row?.id)
                ),
              ],
            };
          });
        } else {
          setSelectedRows((preValue) => {
            return {
              ...preValue,
              [props.uniqueId]: [args.data],
            };
          });
        }
      }
    }

    if (args.name && args.name === "recordClick") {
      if (args.cellIndex != 0) {
        if (
          selectedRows !== undefined &&
          selectedRows !== null &&
          selectedRows &&
          selectedRows[relationshipId] !== undefined &&
          selectedRows[relationshipId] !== null &&
          selectedRows[relationshipId] &&
          selectedRows[relationshipId].length === 1
        ) {
          if (relationshipId !== undefined) {
            if (Array.isArray(args.rowData)) {
              setSelectedRows((preValue) => {
                return {
                  ...preValue,
                  [parseInt(relationshipId)]: args.rowData,
                };
              });
            } else {
              if (
                selectedRows !== undefined &&
                selectedRows !== null &&
                selectedRows &&
                selectedRows[parseInt(relationshipId)] !== undefined &&
                selectedRows[parseInt(relationshipId)] !== null &&
                selectedRows[parseInt(relationshipId)]
              ) {
                setSelectedRows((preValue) => {
                  return {
                    ...preValue,
                    [parseInt(relationshipId)]: [args.rowData],
                  };
                });
              } else {
                setSelectedRows((preValue) => {
                  return {
                    ...preValue,
                    [parseInt(relationshipId)]: [args.rowData],
                  };
                });
              }
            }
          } else {
            if (Array.isArray(args.rowData)) {
              setSelectedRows((preValue) => {
                return {
                  ...preValue,
                  [relationshipId]: args.rowData,
                };
              });
            } else {
              if (
                selectedRows !== undefined &&
                selectedRows !== null &&
                selectedRows &&
                selectedRows[relationshipId] !== undefined &&
                selectedRows[relationshipId] !== null &&
                selectedRows[relationshipId]
              ) {
                setSelectedRows((preValue) => {
                  return {
                    ...preValue,
                    [relationshipId]: [args.rowData],
                  };
                });
              } else {
                setSelectedRows((preValue) => {
                  return {
                    ...preValue,
                    [relationshipId]: [args.rowData],
                  };
                });
              }
            }
          }
        } else {
          if (relationshipId !== undefined) {
            if (Array.isArray(args.rowData)) {
              setSelectedRows((preValue) => {
                return {
                  ...preValue,
                  [parseInt(relationshipId)]: args.rowData,
                };
              });
            } else {
              if (
                selectedRows !== undefined &&
                selectedRows !== null &&
                selectedRows &&
                selectedRows[parseInt(relationshipId)] !== undefined &&
                selectedRows[parseInt(relationshipId)] !== null &&
                selectedRows[parseInt(relationshipId)]
              ) {
                setSelectedRows((preValue) => {
                  return {
                    ...preValue,
                    [parseInt(relationshipId)]: [args.rowData],
                  };
                });
              } else {
                setSelectedRows((preValue) => {
                  return {
                    ...preValue,
                    [parseInt(relationshipId)]: [args.rowData],
                  };
                });
              }
            }
          } else {
            if (Array.isArray(args.rowData)) {
              setSelectedRows((preValue) => {
                return {
                  ...preValue,
                  [relationshipId]: args.rowData,
                };
              });
            } else {
              if (
                selectedRows !== undefined &&
                selectedRows !== null &&
                selectedRows &&
                selectedRows[relationshipId] !== undefined &&
                selectedRows[relationshipId] !== null &&
                selectedRows[relationshipId]
              ) {
                setSelectedRows((preValue) => {
                  return {
                    ...preValue,
                    [relationshipId]: [args.rowData],
                  };
                });
              } else {
                setSelectedRows((preValue) => {
                  return {
                    ...preValue,
                    [relationshipId]: [args.rowData],
                  };
                });
              }
            }
          }
        }
      }
    }
  };

  const deleteSelectedObjects = () => {
    let deletedIds = selectedRows[parseInt(relationshipId)]?.map(
      (obj) => obj.id
    );
    let dataArr = props.relationData[props.relationshipId];
    if (dataArr.length > 0) {
      let toBeKeep = dataArr.filter(
        (resdat) => !deletedIds.some((val) => val === resdat.id)
      );
      props.setRelationDataToSave({
        ...props.relationData,
        [props.relationshipId]: toBeKeep,
      });
      if (props.setChangedAttribute) {
        props.setChangedAttribute(true);
      }
    }
    setDeleteConfirmation(false);
  };

  return (
    <SnackbarProvider
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
      }}
      maxSnack={3}
    >
      <Grid item xs style={{ marginTop: "10px" }}>
        {toolbarLoader ? (
          <ErrorBoundary>
            <Skeleton />
            <br />
          </ErrorBoundary>
        ) : (
          <ToolbarRelationshipGeneralIntegration
            relationshipName={relationshipunique_name}
            relationshipInfo={relationshipunique_info}
            relationshipDescription={relationshipunique_description}
            widget={props.widget}
            addRelation={props.addRelation}
            shouldDelete={!disableDelete}
            shouldEdit={false}
            layoutType={props.layoutType}
            editable={true}
            searchChange={searchChange}
            columnChooserclick={columnChooserclick}
            excelExport={excelExport}
            bodyData={body}
            attacedLink={attacedLink}
            deattacedLink={deattacedLink}
            containerId={props.containerId}
            containerInstanceId={props.containerInstanceId}
            isContainer={props.isContainerCreate}
            relationshipMapped={relationshipMapped}
            isEdit={isEdit}
            diasbledAdding={diasbledAdding}
            showSelect={showSelect}
            setShowCheckBox={setShowCheckBox}
            setShowSelect={setShowSelect}
            myGrid={myGrid}
            relationshipId={relationshipId}
          />
        )}
        {loader ? (
          <GridLoader />
        ) : relationshipMapped === false ? (
          <h1>
            <Alert severity="info" sx={{ width: "400px" }}>
              <AlertTitle>
                "Arofinty Relationship is not Mapped with any external
                relationship"
              </AlertTitle>
            </Alert>
          </h1>
        ) : (
          <DynamicGrid
            toggleData={toggleSelect}
            bodyData={body}
            attributes={filteredattributes}
            appId={props.appId}
            relationshipId={props.relationshipId}
            stateField={stateFieldVal}
            relnRef={relnRef}
            widget={props.widget}
            relationTable={true}
            dateFormat={props.dateFormat}
            dateTimeFormat={props.dateTimeFormat}
            rowsToShow={relationshipRowsToShow}
            groupByAttribute={groupByAttribute}
            showAggregate={showAggregate}
            aggregateParameters={aggregateParameters}
            aggregatePosition={aggregatePosition}
            myGrid={myGrid}
            showCheckBox={showCheckBox}
          />
        )}

        {showModal ? (
          <TypeObjectDialogExternal
            open={showModal}
            setShowModal={setShowModal}
            typeName={typeName}
            attachData={handleAttachData}
            relationShipId={props.relationshipId}
            dateFormat={props.dateFormat}
            dateTimeFormat={props.dateTimeFormat}
            stateField={stateFieldVal}
            widget={props.widget}
            parentObjectId={props.objectId}
            typeId={typeId}
            relationData={relationData}
            initialRelationData={props.relationData}
            allUsers={allUsers}
            containerInstanceId={props.containerInstanceId}
            containerId={props.containerId}
            component="generalIntegration"
            relationshipunique_multivalued={relationshipunique_multivalued}
          />
        ) : null}
      </Grid>

      {deleteConfirmation ? (
        <ConfirmationDialogue
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          header={
            <LanguageTranslation
              key="DELETE_SELECTED_OBJECT_MESSAGE"
              text="Do you really want to delete selected objects?"
            />
          }
          remove={() => deleteSelectedObjects()}
        />
      ) : null}
    </SnackbarProvider>
  );
}
