import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import "@syncfusion/ej2-material-theme/styles/material.css";
import { L10n, loadCldr, setCulture } from "@syncfusion/ej2-base";
import numberingSystemData from "@syncfusion/ej2-cldr-data/supplemental/numberingSystems.json";
import * as enAllData from "@syncfusion/ej2-cldr-data/main/en/all.json";
import * as deDELocalization from "@syncfusion/ej2-locale/src/de.json";
import * as deAllData from "@syncfusion/ej2-cldr-data/main/de/all.json";
import * as hiAllData  from "@syncfusion/ej2-cldr-data/main/hi/all.json"
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { colors, createTheme } from "@mui/material";
import shadows from "./theme/shadows";
import typography from "./theme/typography";
import GlobalStyles from "./components/GlobalStyles";
import SnackBar from "./components/SnackBar";
import routes from "./routes";
import "./index.css";
import "./layouts/UserContainerLayout/NavBar/index.css";
import {
  importAppAlert,
  importWaitingMessage,
} from "./components/actions/appImportExportActions";
import { fetchUserData } from "./components/actions/userAccountActions";
import {
  fetchMigrationStatusById,
  migrationFlag,
  fetchUsersMigration,
} from "./components/actions/migration";
import {
  importDataStatus,
  setImportApi,
} from "./components/actions/importActions";
import { getversion } from "./components/actions/serviceActions";

const App = () => {
  const routing = useRoutes(routes);
  const dispatch = useDispatch();

  const [primaryBackground, setPrimaryBackground] = useState("#303f9f");
  const [secondaryBackground, setSecondaryBackground] = useState(
    "rgb(92 124 238 / 32%)"
  );
  const [cssBlueColor, setCssBluecolor] = useState("rgb(92 124 238 / 32%)");
  const [naviigationBackground, setNavigationBackground] =
    useState("rgb(244, 244, 244)");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [msg, setMsg] = useState("");
  const [fetchedMigration, setFetchedMigration] = useState([]);

  const importedIds = useSelector((state) => {
    return state.import.importData;
  });

  const allSettings = useSelector((state) => {
    return state.appSettings.allColourSettingsOfAllApps;
  });

  const showImportAlert = useSelector((state) => {
    return state.showImportMessage.status;
  });

  const showImportWaitingAlert = useSelector((state) => {
    return state.showImportMessage.showWaitingMessage;
  });

  const fetchedMigra = useSelector((state) => {
    return state.migration.migrationData;
  });

  //For getting Currently logged in user data
  const auth = useSelector((state) => state.auth.currentUser);

  const handleClose = () => {
    dispatch(importAppAlert(false, true));
  };

  const handleCloseWaitingAlert = () => {
    dispatch(importWaitingMessage(false));
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  let urlPath = window.location.pathname.split("/");

  let n = "-1";

  if (
    urlPath &&
    urlPath[0] == "" &&
    urlPath[1] == "wiscode" &&
    urlPath[2] == "admin" &&
    urlPath[3] == "app" &&
    urlPath.length > 4
  ) {
    n = urlPath[4];
  } else if (
    urlPath &&
    urlPath[0] == "" &&
    urlPath[1] == "wiscode" &&
    urlPath[2] == "user" &&
    urlPath[3] == "app" &&
    urlPath.length > 4
  ) {
    n = urlPath[4];
  }
  useEffect(() => {
    if (fetchedMigra && fetchedMigra.length > 0) {
      setFetchedMigration(fetchedMigra);
    }
  }, [fetchedMigra]);

  useEffect(() => {
    if (fetchedMigration && fetchedMigration.length > 0) {
      let interval = setInterval(() => {
        let index = 0;
        fetchedMigration.map((res) => {
          dispatch(fetchMigrationStatusById(res.id)).then((obj) => {
            if (obj.status && obj.data && obj.data.status) {
              if (obj.data.status === "Complete") {
                index = index + 1;
                setMsg("The migration " + res.name + " is " + obj.data.status);
                setSeverity("success");
                setAlert(true);
                let newMigrationData = fetchedMigration.filter(
                  (migraId) => migraId.id !== res.id
                );
                dispatch(migrationFlag(newMigrationData));
                setFetchedMigration([]);
              } else if (obj.data.status === "Failed") {
                index = index + 1;
                setMsg("The migration " + res.name + " is ");
                setSeverity("error");
                setAlert(true);
                let newMigrationData = fetchedMigration.filter(
                  (migraId) => migraId.id !== res.id
                );
                dispatch(migrationFlag(newMigrationData));
                setFetchedMigration([]);
              }
            }
          });
        });
        if (fetchedMigration.length == index) {
          return;
        }
      }, 50000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [fetchedMigration]);

  useEffect(() => {
    if (importedIds) {
      let interval = setInterval(() => {
        dispatch(importDataStatus(importedIds)).then((obj) => {
          if (obj.status && obj.data) {
            if (obj.data === "Completed") {
              setMsg("The import is completed");
              setSeverity("success");
              setAlert(true);
              dispatch(setImportApi(""));
              return;
            } else if (obj.data === "Failed") {
              setMsg("The import is ");
              setSeverity("error");
              setAlert(true);
              dispatch(setImportApi(""));
              return;
            }
          }
        });
      }, 50000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [importedIds]);

  useEffect(() => {
    if (
      allSettings !== undefined &&
      allSettings !== null &&
      allSettings &&
      allSettings.length !== 0 &&
      n !== -1
    ) {
      //Primary color settings
      const filteredPrimarycolorSettings = allSettings.filter(
        (al) =>
          al.appId == n &&
          al.category == "primarycolor" &&
          al.name == "primarycolor"
      );
      if (filteredPrimarycolorSettings.length !== 0) {
        const filPrimarycolorSetting = filteredPrimarycolorSettings.map(
          (fs) => {
            return {
              app_id: fs.appId,
              category: fs.category,
              id: fs.id,
              name: fs.name,
              value: JSON.parse(fs.value),
            };
          }
        );
        if (
          filPrimarycolorSetting[0].value !== undefined &&
          filPrimarycolorSetting[0].value !== null &&
          filPrimarycolorSetting[0].value &&
          filPrimarycolorSetting[0].value.length > 0
        ) {
          setPrimaryBackground(filPrimarycolorSetting[0].value[0].primaryColor);
        } else {
          setPrimaryBackground("#303f9f");
        }
      } else {
        setPrimaryBackground("#303f9f");
      }
      //secondary color settings
      const filteredSecondarycolorSettings = allSettings.filter(
        (al) =>
          al.appId == n &&
          al.category == "secondarycolor" &&
          al.name == "secondarycolor"
      );
      if (filteredSecondarycolorSettings.length !== 0) {
        const filSecondarycolorSetting = filteredSecondarycolorSettings.map(
          (fs) => {
            return {
              app_id: fs.appId,
              category: fs.category,
              id: fs.id,
              name: fs.name,
              value: JSON.parse(fs.value),
            };
          }
        );
        if (
          filSecondarycolorSetting[0].value !== undefined &&
          filSecondarycolorSetting[0].value !== null &&
          filSecondarycolorSetting[0].value &&
          filSecondarycolorSetting[0].value.length > 0
        ) {
          setSecondaryBackground(
            filSecondarycolorSetting[0].value[0].secondaryColor
          );
          setCssBluecolor(filSecondarycolorSetting[0].value[0].secondaryColor);
        } else {
          setCssBluecolor("rgb(92 124 238 / 32%)");
          setSecondaryBackground("rgb(92 124 238 / 32%)");
        }
      } else {
        setCssBluecolor("rgb(92 124 238 / 32%)");
        setSecondaryBackground("rgb(92 124 238 / 32%)");
      }

      //Navigation color settings
      const filteredNavigationcolorSettings = allSettings.filter(
        (al) =>
          al.appId == n &&
          al.category == "navigationcolor" &&
          al.name == "navigationcolor"
      );
      if (filteredNavigationcolorSettings.length !== 0) {
        const filNavigationcolorSetting = filteredNavigationcolorSettings.map(
          (fs) => {
            return {
              app_id: fs.appId,
              category: fs.category,
              id: fs.id,
              name: fs.name,
              value: JSON.parse(fs.value),
            };
          }
        );
        if (
          filNavigationcolorSetting[0].value !== undefined &&
          filNavigationcolorSetting[0].value !== null &&
          filNavigationcolorSetting[0].value &&
          filNavigationcolorSetting[0].value.length > 0
        ) {
          setNavigationBackground(
            filNavigationcolorSetting[0].value[0].navigationColor
          );
        } else {
          setNavigationBackground("rgb(244, 244, 244)");
        }
      } else {
        setNavigationBackground("rgb(244, 244, 244)");
      }
    }
  }, [allSettings, n]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(fetchUserData());
      dispatch(fetchUsersMigration()).then((res) => {
        if (res.status == "200") {
          setFetchedMigration(res.data);
        }
      });
    }
  }, [localStorage.getItem("token")]);

  useEffect(() => {
    dispatch(getversion());
  }, []);

  useEffect(() => {
    if (
      auth &&
      auth.language &&
      auth.language.local !== undefined &&
      auth.language.local !== null &&
      auth.language.local &&
      auth.language.local === "de"
    ) {
      L10n.load(deDELocalization);
      loadCldr(deAllData, numberingSystemData);
      setCulture("de");
    } else if (
      auth &&
      auth.language &&
      auth.language.local !== undefined &&
      auth.language.local !== null &&
      auth.language.local &&
      auth.language.local === "hi"
    ) {
      L10n.load({
        "hi-IN": {
          grid: {
            EmptyRecord: "कोई रिकॉर्ड उपलब्ध नहीं है",
            Add: "जोड़ें",
            Edit: "संपादित करें",
            Delete: "हटाएं",
            Update: "अद्यतन करें",
            Cancel: "रद्द करें",
            Search: "खोजें",
          },
          pager: {
            currentPageInfo: "{0} का {1} पृष्ठ",
            totalItemsInfo: "({0} आइटम्स)",
            firstPageTooltip: "पहले पृष्ठ पर जाएं",
            lastPageTooltip: "अंतिम पृष्ठ पर जाएं",
            nextPageTooltip: "अगले पृष्ठ पर जाएं",
            previousPageTooltip: "पिछले पृष्ठ पर जाएं",
          },
        },
      });
      loadCldr(hiAllData, numberingSystemData);
      setCulture('hi-IN');
    } else {
      loadCldr(enAllData, numberingSystemData);
      setCulture("en");
    }
  }, [auth]);

  let palette = {
    background: {
      dark: "#F4F6F8",
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: colors.indigo[700],
    },
    secondary: {
      main: colors.indigo[500],
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
  };

  if (n != "-1") {
    if (
      primaryBackground &&
      primaryBackground !== null &&
      primaryBackground !== undefined &&
      secondaryBackground &&
      secondaryBackground !== undefined &&
      secondaryBackground !== null
    ) {
      palette = {
        background: {
          dark: "#F4F6F8",
          default: colors.common.white,
          paper: colors.common.white,
        },
        primary: {
          main: primaryBackground,
        },
        secondary: {
          main: secondaryBackground,
        },
      };
    }
    document.documentElement.style.setProperty(
      "--lightblue",
      `${cssBlueColor}`
    );
    document.documentElement.style.setProperty(
      "--darkblue",
      `${primaryBackground}`
    );
    document.documentElement.style.setProperty(
      "--navigationcolor",
      `${naviigationBackground}`
    );
  }

  let theme = createTheme({
    palette,
    shadows,
    typography,
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackBar
          alert={showImportAlert?.show}
          closeAlert={handleClose}
          severity={showImportAlert?.success == true ? "success" : "error"}
          keyword="App"
          action="Import"
        ></SnackBar>

        <SnackBar
          alert={showImportWaitingAlert?.show}
          closeAlert={handleCloseWaitingAlert}
          severity="info"
          keyword="App creation is in progress. You will be notified once done "
          action=""
        ></SnackBar>
        <SnackBar
          alert={alert}
          closeAlert={handleCloseAlert}
          severity={severity}
          keyword={msg}
          action=""
        ></SnackBar>

        <GlobalStyles />
        {routing}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
