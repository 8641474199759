export default (

    state = { 
        allowedTypes: [],
    },   
    action
) => {
    switch (action.type) {
        case "FETCH_ALLOWED_TYPES": 
            return { ...state, allowedTypes: action.response };
        default:
             return state; 
    }
};
