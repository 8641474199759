import React, { useEffect, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import InputLabel from "../../Common/Components/InputLabel";

import "./Widget.css";

export default function DontShowWidget(props) {
  const [loader, setLoader] = useState(true);
  const [attr, setAttr] = useState();

  useEffect(() => {
    setLoader(true);
  }, []);

  useEffect(() => {
    if (props.attr) {
      setAttr(props.attr);
      setLoader(false);
    }
  }, [props.attr]);

  return (
    <Grid item xs>
      {loader ? (
        <Skeleton variant="text" />
      ) : (
        <>
          <InputLabel attr={attr} objectType={"View"} />
          <div className="root">
            <div className="parahSpace">
              You cannot access comment field here
            </div>
          </div>
        </>
      )}
    </Grid>
  );
}
