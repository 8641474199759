import typeRefactoredApi from "../apis/typeRefactoredApi";
import { configHeader } from "./header-configure";
import { configHeaderwithCancel } from "./header-configure";
import { redirect } from "./redirect";

//To get listing of type is enable or disable according to containerID and containerInstanceId
export const enableDisableTypeListingByCointainerAndContainerInstanceId =
  (containerId, containerInstanceId) => async (dispatch) => {
    try {
      const response = await typeRefactoredApi.get(
        `/container/instance/typesdata?containerId=${containerId}&containerInstanceId=${containerInstanceId}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else if (e?.response?.status === 409) {
        return e.response;
      } else {
        dispatch({ type: "DISPATCH_MESSAGE", response: e.response });
      }
      return e.response;
    }
  };

export const typewithSelectedAttributes = (data) => async (dispatch) => {
  try {
    const response = await typeRefactoredApi.post(
      "/typeandtypeattributes/data",
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else if (e?.response?.status === 409) {
      return e.response;
    } else {
      dispatch({ type: "DISPATCH_MESSAGE", response: e.response });
      return e.response;
    }
  }
};

export const typewithSelectedAttributesWithCancel =
  (data, controllerRef) => async (dispatch) => {
    try {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      controllerRef.current = new AbortController();
      const response = await typeRefactoredApi.post(
        `/typeandtypeattributes/data`,
        data,
        configHeaderwithCancel(controllerRef)
      );
      return response;
    } catch (e) {
      if (e?.response && e?.response?.status && e?.response?.status === 401) {
        redirect(e, dispatch);
      } else if (
        e?.response &&
        e?.response?.status &&
        e?.response?.status === 409
      ) {
        return e.response;
      } else {
        dispatch({ type: "DISPATCH_MESSAGE", response: e.response });
      }
      return e.response;
    }
  };

export const typewithRelationId = (data) => async (dispatch) => {
  try {
    const response = await typeRefactoredApi.post(
      "/relation/connected/typeandtypeattributes/data",
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else if (e?.response?.status === 409) {
      return e.response;
    } else {
      dispatch({ type: "DISPATCH_MESSAGE", response: e.response });
    }
    return e.response;
  }
};

//To get listing of Type data with attributes and their possible values according to display string
export const typeDataWithAllPossibleValues = (typeId) => async (dispatch) => {
  try {
    const response = await typeRefactoredApi.get(
      `/modifiedtype?id=${typeId}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else if (e?.response?.status === 409) {
      return e.response;
    } else {
      dispatch({ type: "DISPATCH_MESSAGE", response: e.response });
    }
    return e.response;
  }
};

//To get listing of Type data with attributes and their possible values according to formatted value of reference and type pivk list possible value
export const typeDataWithFormattedPossibleValues =
  (typeId) => async (dispatch) => {
    try {
      const response = await typeRefactoredApi.get(
        `/modified/type/data?id=${typeId}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else if (e?.response?.status === 409) {
        return e.response;
      } else {
        return e.response;
      }
    }
  };

export const typeDataWithFormattedPossibleValuesWithCancel =
  (typeId, controllerRef) => async (dispatch) => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    try {
      const response = await typeRefactoredApi.get(
        `/modified/type/data?id=${typeId}`,
        configHeaderwithCancel(controllerRef)
      );
      return response;
    } catch (e) {
      if (e?.response && e?.response?.status && e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e;
      }
    }
  };

//To get data of Selected type according to selected columns of state (Used at Kanban)
export const typeDataWithSelectedPossibleValues =
  (data, controllerRef) => async (dispatch) => {
    try {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      controllerRef.current = new AbortController();
      const response = await typeRefactoredApi.post(
        `/instance/view`,
        data,
        configHeaderwithCancel(controllerRef)
      );
      return response;
    } catch (e) {
      if (e?.response && e?.response?.status && e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e;
      }
    }
  };

export const containerInstancesByAppIdAndContainerId =
  (data) => async (dispatch) => {
    try {
      const response = await typeRefactoredApi.post(
        `/container/containerinstance`,
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

//To get listing of type attributes with attributes without their possible values and with flag which determine picklist is state or not
export const typeAttributesWithStateFlag = (typeId) => async (dispatch) => {
  try {
    const response = await typeRefactoredApi.get(
      `/attributes/without/possiblevalues?typeId=${typeId}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else if (e?.response?.status === 409) {
      return e.response;
    } else {
      return e.response;
    }
  }
};
//Refactored attribute possibleValues api
export const attributePossibleValues = (data) => async (dispatch) => {
  try {
    const response = await typeRefactoredApi.post(
      "attributes/possiblevalues",
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else if (e?.response?.status === 409) {
      return e.response;
    } else {
      return e.response;
    }
  }
};

//To get data of Version
export const typeDataAccordingToVersion = (data) => async (dispatch) => {
  try {
    const response = await typeRefactoredApi.post(
      `/instance/version`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else if (e?.response?.status === 409) {
      return e.response;
    } else {
      dispatch({ type: "DISPATCH_MESSAGE", response: e.response });
    }
    return e.response;
  }
};
