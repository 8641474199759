export const redirect = (e, dispatch) => {
  if (e?.response?.status === 401) {
    dispatch({ type: "FETCH_TIMEOUT_STATUS", response: true });

    if (window.location.href.search("wiscode/sessiontimeout/login") == -1) {
      localStorage.setItem("previousUrl", window.location.href);
      window.location.href = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/wiscode/sessiontimeout/login`;
    }
  } else {
    dispatch({ type: "DISPATCH_MESSAGE", response: e.response });
    return e.response;
  }
  return e.response;
};
