export default (
  state = {
    users: [],
    userImagesWithByteArray: [],
    allUserAccordinToAppId: [],
    userGroups: [],
    showCreateNewUserDialog: false,
  },
  action
) => {
  switch (action.type) {
    case "FETCH_ALL_USERS":
      return { ...state, users: action.response };
    case "FETCH_ALL_USERS_WITH_IMAGE_BYTEARRAY":
      return { ...state, userImagesWithByteArray: action.response };
    case "FETCH_ALL_USERS_ACCORDING_TO_APPID":
      return { ...state, allUserAccordinToAppId: action.response };
    case "DELETE_USER": {
      return {
        ...state,
        users: [...state.users.filter((v) => v.id !== action.response)],
      };
    }
    case "CREATE_USER":
  
      return { ...state, users: [...state.users, action.response.data] };
    case "FETCH_USER_GROUP":
      return { ...state, userGroups: action.response };
    case "SHOW_CREATE_USER_DIALOG":
      return { ...state, showCreateNewUserDialog: action.response };
    default:
      return state;
  }
};
